import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RegistroMisionView from "./RegistroMision";
import RegistroClinicoView from "./RegistroClinico";
import RegistroProfesionalView from "./RegistroProfesional";
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import instance from "../../utilities/Instance";
import ProfileBase from "../../components/shared/layout/profileBaseSkeleton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ProfileInfo from "../../components/shared/layout/profileInfoSkeleton";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F } from "../../components/alerts";
import { debugHtml, generarForm, generarFormCompleto, isNumeric } from "../../utilities/Utilities";
import { getFormatUp } from "../../structure/toUp";
import PersonasView from "./PersonasView";
import Loader2 from "../../components/shared/layout/Loader2";
import DetallePerfil from "./DetallePerfil";
import CarouselModelPages from "../../components/shared/layout/Carousel";
import ResponseCustomizedSnackbars, { CatchResponseCustomizedSnackbars } from "../../components/shared/notification/snackbar";

export default function SkeletonRegistro(props){
    const [openRM, setOpenRM] = useState(false);
    const [openRC, setOpenRC] = useState(false);
    const [openRP, setOpenRP] = useState(false);
    const [openList, setOpenList] = useState(true);
    const [datatable, setDataTable]= useState([]);
    const [datatableP, setDataTableP]= useState([]);
    const [selectListOptions, setSelectListOptions]= useState({})
    const [actionsButtoms, setActionsButtoms]= useState([]);
    const [SelectOption, setSelectOption] = useState([]);
    const [AditionalFields, setAditionalFields] =useState([]);
    const [formDataSearch, setFormDataSearch]= useState([]);
    const [perfilInformation, setPerfilInformation]= useState({});
    const [persona_id, setPersonaId]=useState(null)
    const [contadorSecuencias, setContador]=useState(0)
    const [listPaises, setListPaises]= useState([]);
    const [listEstados, setListEstados]= useState([]);
    const [listCiudades, setListCiudades]= useState([]);
    const [rules,setRules]= useState([]);
    const [allPrimary,setAllPrimary] = useState({})
    const [registroloader, setRegistroloader]=useState(false);
    const [dataresponse,setDataResponse]=useState(null)
    const [validatorDataresponse,setValidatorDataResponse]=useState(null)
    const [catchResponse,setCatchResponse]=useState(null)
    const [validatorCatchresponse,setValidatorCatchResponse]=useState(null)
    const [generalValitor, setGeneralValidator]= useState(false)
    const {setLoader, menu_id} = props;
    const [photo, setPhoto]=useState('')

    const DataResponseValid=(value)=>{
      setDataResponse(value)
      setValidatorDataResponse(!validatorDataresponse)
    }

    const CatchResponseValid=(value)=>{
      setCatchResponse(value)
      setValidatorCatchResponse(!validatorCatchresponse)
    }

    const consultarRegistros=async(option)=>{
      if(option && persona_id){
        setRegistroloader(true);

        await instance.get(""+option+"/ListAllByPersona/"+persona_id)
        .then(async(response)=>{
          setRegistroloader(false);
          var para=[]
          var registros= response.data.data;
          var newItem= contadorSecuencias
          registros.forEach(async (element) => {
            newItem= newItem+1
            para.push(await createRow(element, newItem))
          });

            
            setContador(newItem)
            setDataTable(para)
        }).catch((error)=>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      }
    }

    const actualizarRegistro = async (row, base, refrow) =>{
      setLoader(true);
      for( var key in row){
        if(typeof row[key] === 'object'){
          if(key!=='fecha')
            row[key]=JSON.stringify(row[key])
        }
        if(row[key]==="null"){
          row[key]=undefined
        }
      }
      await instance.postForm(base,row)
      .then(async(response)=>{
        setLoader(false);
        if(! await AlertCodeInterpreter_F(response.data.data)){
          var newid=response.data.data.data;
          var toEdit= datatable.find(element=>element.idreal===newid)
          if(!toEdit){
            setLoader(false)
            refrow.idreal=newid
            var newdatatable = [...datatable]
            var findData = newdatatable.findIndex(element=>element.id===refrow.id)
            newdatatable.slice(findData, 1, refrow)
            setDataTable(newdatatable)
            return 
          }
          
        }
        return false
      }).catch(error =>{

        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }setLoader(false);
        return false
      })
    }

    const guardarRegistro = async (row) =>{
      var base=""
      var option=""
      if(openRM){
        base="RegistroMision/"
        option="registromision"
      }else if (openRC){
        base="RegistroClinico/"
        option="registroclinico"
      }else if (openRP){
        base="RegistroProfesional/"
        option="registroprofesional"
      }
      var toup=getFormatUp(row, option)
      if(row.idreal){
        //actualizar
        base=base+"update/"+toup.id

      }else{
        //crear
        base=base+"Create"
        toup.persona_id=persona_id
      }
      actualizarRegistro(toup, base, row)
      

    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        persona_id: row?.persona_id || persona_id,
        comprobante_name:row?.comprobante_name || '',
        id: newItem || "",
        idreal: row?.id || null,
        fecha: row?.fecha ||"",
        numeroidentificacion: row?.numeroidentificacion ||"",
        nombrecompleto:row?.nombrecompleto ||"",
        casa_detalle: row?.casa_detalle ||"",
        onlyyear: row?.onlyyear ||"",
        ct_casa_id: row?.ct_casa_id ||"",
        ciudad_detalle: row?.ciudad_detalle ||"",
        ct_establecimiento_id: row?.ct_establecimiento_id ||"",
        doctor_id:row?.doctor_id || "",
        evolucion:row?.doctor_id || "",
        medicamento:row?.medicamento? JSON.parse(row.medicamento) : "",
        numero:row?.numero || "",
        ciudad_id: row?.ciudad_id ||"",
        pais_detalle: row?.pais_detalle ||"",
        mision_detalle: row?.mision_detalle ||"",
        ct_mision_id: row?.ct_mision_id ||"",
        evolucion: row?.evolucion || "",
        prescripcion: row?.prescripcion || "",
        historial_id: row?.historial_id || "",
        historial_detalle: row?.historial_detalle || "",
        ct_comunidad_id: row?.ct_comunidad_id || "",
        ct_comunidad_detalle: row?.ct_comunidad_id || "",
        superiora_id: row?.superiora_id || "",
        superiora: row?.superiora || "",
        doctor: row?.doctor || "",
        ct_responsabilidad_id: row?.ct_responsabilidad_id || "",
        ct_responsabilidad_detalle: row?.ct_responsabilidad_detalle || "",
        observacion: row?.observacion || "",
        opciones: selectListOptions,
        listPaises:listPaises, 
        listEstados:listEstados, 
        listCiudades:listCiudades,
        rules: rules,
        edit:true,
        refreshdata: ()=> setDataTable(prev=>[...prev]),
        actualizarFunc: ()=>getData()
        }
        
        return insertEl
    }

    const addRow = async(row) =>{
      var newItem= contadorSecuencias +1
      
      var aux= [...datatable]
      aux.push(await createRow(row, newItem))
      setContador(newItem)
      setDataTable(aux)
    }
    
    const handleChage = async(e)=>{
    }

    const openEdit = async(e)=>{
      SearchCedula(e.numeroidentificacion)

    }

    const SearchName= async(e)=>{
      setLoader(true);
      await instance.get("Persona/FindByNombrePerfil/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        
        await setterSearch(response)

      }else{
        setPersonaId(0)
        setDataTable([])
        resetAll()
      }
      
      }).catch(error=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
    } 

    const SearchCedula= async(e)=>{
      if(!e?.length){
        return
      }
      setLoader(true);
      
      await instance.get("Persona/FindByIdentidadPerfil/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        await setterSearch(response)
      }else{
        setPersonaId(0)
        setDataTable([])
        resetAll()
      }
      
      }).catch(error=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
    }

    const setterSearch= async(response)=>{
      var datosPersona= response.data.data.data;
      if(Array.isArray(datosPersona)){
        var datosform=[]
        var nombres
        var newItem= contadorSecuencias
        datosPersona.forEach(async element => {
          newItem= newItem+1
          /*datosform.push({
            ...(generarForm({"numeroidentificacion":element.numeroidentificacion}))[0], 
          nombrecompleto: element.nombrecompleto})*/
        datosform.push(await createRow(element, newItem))
        });

        setDataTableP(datosform)
        setContador(newItem)
        response.data.data.form =datosform
        setOpenList(true)
        /*var selected= await AlertCodeInterpreter_preForm(response.data.data)
        if(selected){
          SearchCedula(selected)
        }*/
        setPersonaId(0)
        setPhoto('')

      }else{
        if(!datosPersona){
          datosPersona={}
        }
        var idp =datosPersona?.id
        setPersonaId(idp)
        if(!idp){
        setDataTable([])
        }
        var newItem= contadorSecuencias
        var datosform=[]
        newItem= newItem+1
        datosform.push(await createRow(datosPersona, newItem))
        setContador(newItem)
        //setDataTable([])
        delete datosPersona?.id;
        setDataTableP(datosform)
        
        //var datosform=generarForm(datosPersona);
        var datosform=generarForm(datosPersona);
        setPerfilInformation(datosPersona)
        setFormDataSearch(datosform)
        setOpenList(false)
        setPhoto(response.data.data?.data?.foto ||'')
      }
    }

    const resetAll=()=>{
      setPhoto('')
      setPerfilInformation([])
      setFormDataSearch([]);
    }

  const PrintFicha= async()=>{
    let formato={
      persona: perfilInformation,
      categorias: allPrimary
    }
    let namedoc= encodeURI("FICHA PERSONAL")
    //instance.post("Formatos/printMenuName/"+menu_id+"/"+namedoc, formato)
    instance.post("Formatos/printFichaCompleta/"+menu_id+"/"+perfilInformation.numeroidentificacion, {})
    .then(async response => {
      //response.data
      debugHtml(response.data)
    }).catch((error)=>{
        
    })
    //alert("imprimir")

  }

  const printPerfil= async(title, dataPersona)=>{
    let formato={
      persona: perfilInformation,
      registro: dataPersona,
      categorias: allPrimary
    }
    let namedoc= encodeURI(title)
    instance.post("Formatos/printMenuName/"+menu_id+"/"+namedoc, formato)
    .then(async response => {
      //response.data
      debugHtml(response.data)
    }).catch((error)=>{
        
    })
    //alert("imprimir")

  }

  const PrintReport= async()=>{
    let formato={
      persona: perfilInformation,
      reporte: datatable,
      categorias: allPrimary
    }
    let title='REPORTE MISION'
    if(openRM){
      title='REPORTE MISION'
    }
    if(openRC){
      title='REPORTE CLINICO'
    }
    let namedoc= encodeURI(title)
    instance.post("Formatos/printMenuName/"+menu_id+"/"+namedoc, formato)
    .then(async response => {
      //response.data
      debugHtml(response.data)
    }).catch((error)=>{
        
    })
    //alert("imprimir")

  }

  const SearchReport= async(e)=>{
    if(openRM){
      alert(e + " con reporte misión")
    }else if (openRC){
      alert(e + " con reporte Clínico")
    }else if (openRP){
      alert(e + " con reporte Profesional")
    }  
  }

  useEffect(()=>{
    autosearchRegistro()
  },[persona_id])

  const autosearchRegistro=async()=>{
    if(persona_id){
      if(persona_id){
        if(openRC){
          handleOpenReporte(2)
        }else if(openRM){
          handleOpenReporte(1)
        }else if(openRP){
          handleOpenReporte(3)
        }
      }
    }  
  }

    const ActionsHandle = async(e) =>{
      e.preventDefault();
      const opt = e.nativeEvent.submitter.value;
      const data = new FormData(e.currentTarget);
      var toSend={
        busqueda: data.get('busqueda')
      }
      if(opt==='cedula'){
        if( isNumeric(toSend.busqueda)){
          await SearchCedula(toSend.busqueda)
        }else{{
          await SearchName(toSend.busqueda)
        }}
      }else
      if(opt==='report'){
        SearchReport(toSend.busqueda)
      }else
      if(opt==='print'){
        PrintFicha()
      }
    }

    const getData=async()=>{
      var botones = []
      botones.push({icon:<PersonSearchTwoToneIcon titleAccess="Buscar Persona"></PersonSearchTwoToneIcon>,
                    value: "cedula"}) 
      botones.push({icon: <LocalPrintshopTwoToneIcon titleAccess="Imprimir ficha personal"></LocalPrintshopTwoToneIcon>,
                    value: "print"}) /*
      botones.push({icon: <ContentPasteSearchTwoToneIcon titleAccess="Buscar Reporte" ></ContentPasteSearchTwoToneIcon>,
                    value: "report"}) */
      setActionsButtoms(botones)
      var SelectComponent=[]
      SelectComponent.push({value:1, label:"Registro Mision"})
      SelectComponent.push({value:2, label:"Registro Clínico"})
      //SelectComponent.push({value:3, label:"Registro Profesional"})
      
      setSelectOption(SelectComponent)
        
      var AdicionalFields = (
        <React.Fragment>
          <TextField
                onChange={handleChage}
                margin="normal"
                required
                fullWidth
                id="busqueda"
                label="Buscar"
                name="busqueda"
                autoComplete="busqueda"
                autoFocus
              />
        </React.Fragment>
      )
      
      setAditionalFields(AdicionalFields)

      var listaOpciones={}
      instance.get("DataMaster/getAllSelectors")
      .then(async response=>{
        listaOpciones.detallecatalogo=response?.data?.data?.DetalleCatalogos ||[]
        listaOpciones.listCiudades=response?.data?.data?.Ciudades ||[]
        setSelectListOptions(listaOpciones)
        setListPaises(response?.data?.data?.Paises ||[])
        setListEstados(response?.data?.data?.Estados ||[])
        setListCiudades(response?.data?.data?.Ciudades ||[])
        setRules(response?.data?.data?.Config ||[])
        setAllPrimary(response?.data?.data)
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
    }

    useEffect(()=> {
      getData();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
    }, [])

    

    const handleOpenReporte= (value)=>{
        switch (value) {
            case 1:
                consultarRegistros("RegistroMision")

                setOpenRM(true)
                setOpenRC(false)
                setOpenRP(false)
                break;
            case 2:
                consultarRegistros("RegistroClinico")
                setOpenRC(true)
                setOpenRM(false)
                setOpenRP(false)
                break;
            case 3:
                consultarRegistros("RegistroProfesional")
                setOpenRP(true)
                setOpenRC(false)
                setOpenRM(false)
                break;
            case -1:
                setOpenRP(false)
                break;
            case -2:
                setOpenRP(false)
                break;
            case -3:
                setOpenRP(false)
                break;
            default:
                setOpenRM(false)
                setOpenRC(false)
                setOpenRP(false)
                break;
        }
    }
    
    return (
        <React.Fragment>
            
            <Grid container spacing={3}>
            <ResponseCustomizedSnackbars dataresponse={dataresponse} validator={ validatorDataresponse}/>
            <CatchResponseCustomizedSnackbars dataresponse={catchResponse} validator={validatorCatchresponse}/>
              {/* Perfil Search y options */}
              <Grid item xs={12} md={4} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: "center",
                  }}
                >
                  {<ProfileBase 
                    imageBg={photo}
                    actionsButtoms={actionsButtoms} 
                    titleSelect= {"Tipo de registro"} 
                    SelectOption={SelectOption} 
                    handleOpenReporte={handleOpenReporte}
                    AditionalFields={AditionalFields}
                    ActionsHandle={ActionsHandle}  
                  />}
                </Paper>
              </Grid>
              {/* Perfil datos */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {openList? <PersonasView
                    openEdit={openEdit}
                    data={datatableP} 
                    title={"Lista de personas"}
                  />
                  :
                  <CarouselModelPages 
                  usuario_id={persona_id}
                  arrChildrens={[
                    <ProfileInfo textFields={formDataSearch} persona_id={persona_id}/>,
                    <DetallePerfil 
                      //actionPrint={printPerfil}
                      specialRules={rules} 
                      listPaises={listPaises} 
                      listEstados={listEstados} 
                      listCiudades={listCiudades} 
                      setDataResponse={DataResponseValid} 
                      setCatchResponse={CatchResponseValid} 
                      persona_id={persona_id} 
                      rules={rules} 
                      listCatalogos={selectListOptions} 
                      perfil={"PerfilFamiliar/"} 
                      title={"Registro familiar"}
                    />,
                    <DetallePerfil 
                      //actionPrint={printPerfil}
                      specialRules={rules} 
                      listPaises={listPaises} 
                      listEstados={listEstados} 
                      listCiudades={listCiudades} 
                      setDataResponse={DataResponseValid} 
                      setCatchResponse={CatchResponseValid} 
                      persona_id={persona_id} 
                      rules={rules} 
                      listCatalogos={selectListOptions} 
                      perfil={"PerfilProfesional/"} 
                      title={"Perfil profesional"}
                      fieldsDocsForArraysJson={[{id:63, title: 'TÍTULOS', name: 'titulodoc_id', fromText:'titulonombre'}, {id:70, title: 'OTROS',name: 'otrosdoc_id', fromText:'otrosnombre'}]}
                    />,
                    <DetallePerfil 
                      //actionPrint={printPerfil}
                      specialRules={rules} 
                      listPaises={listPaises} 
                      listEstados={listEstados} 
                      listCiudades={listCiudades} 
                      setDataResponse={DataResponseValid} 
                      setCatchResponse={CatchResponseValid} 
                      persona_id={persona_id} 
                      rules={rules} 
                      listCatalogos={selectListOptions} 
                      perfil={"PerfilReligioso/"} 
                      title={"Vida religiosa"}
                    />,
                    <DetallePerfil 
                      //actionPrint={printPerfil}
                      specialRules={rules} 
                      listPaises={listPaises} 
                      listEstados={listEstados} 
                      listCiudades={listCiudades} 
                      setDataResponse={DataResponseValid} 
                      setCatchResponse={CatchResponseValid} 
                      persona_id={persona_id} 
                      rules={rules} 
                      listCatalogos={selectListOptions} 
                      perfil={"Defuncion/"} 
                      title={"Defunción"}
                      tipoDocumento={[{id:65, title: 'ACTA DEFUNCIÓN', name: 'actadefuncion_id', fromText:'actadefuncionnombre'}]}
                    />
                  ]} title={"Registros"}/>}
                  {}
                  {/*<DetallePerfil persona_id={persona_id} rules={rules} listCatalogos={selectListOptions} perfil={"PerfilFamiliar/"} title={"Registro familiar"}/>*/}
                </Paper>
              </Grid>
              {/* Tablas */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Loader2 onLoader={registroloader}/>
                  {(openRM && persona_id?true:false) && <RegistroMisionView actionPrint={PrintReport} data={datatable} addRow={addRow} guardarRegistro={guardarRegistro}/>}
                  {(openRC && persona_id?true:false) && <RegistroClinicoView actionPrint={PrintReport} data={datatable} addRow={addRow} guardarRegistro={guardarRegistro}/>}
                  {/*(openRP && persona_id?true:false) && <RegistroProfesionalView data={datatable} addRow={addRow} guardarRegistro={guardarRegistro}/>*/}
                </Paper>
              </Grid>
            </Grid>
        </React.Fragment>
    )
}