import logo from './logo.svg';
import './App.css';
import Login from "./components/authentication/login/Login";
import Loader from "./components/shared/layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import allActions from "./redux/actions";
import { useEffect, useState } from 'react';
import Allroutes from './routes/routes';
import { createTheme } from '@mui/material';
import Outroutes from './routes/Outroutes';
function App() {
  const theme = createTheme({
    typography:{
      fontSize:10,
    },
    palette: {
      primary: {
        main: '#faa000',
        //main: '#b39ddb',
      },
      secondary: {
        main: '#2b383e',
        //main: '#ea80fc',
      },
    },
  });
  const dispatch = useDispatch();
  const [wrapperNavHK, setWrapperNavHK] = useState('hk-wrapper');
  const [signedState, setSignedState] = useState(false);

  const user = useSelector(state => state.userReducer.user);
  const [expiredToken, setExpiredToken] = useState(false);

  const signed =async(value) =>{
    setSignedState(value)
  }

  const tokenstate = async (value) =>{
    setExpiredToken(value)
  }

  useEffect(()=> {
    dispatch(allActions.loadingActions.hideLoading());
    const configuration = localStorage.getItem('auth_jwt');
    if(configuration){
      setExpiredToken(true);
      setSignedState(true);
    }
    //setWrapperNavHK('hk-wrapper hk-vertical-nav');


  }, [])
  return (
    <>
      
      {(!expiredToken ) &&
          wrapperNavHK === 'hk-wrapper' && <Outroutes signed={signed} tokenstate= {tokenstate} theme={theme}/>
        }
      {(expiredToken) && signedState &&
          wrapperNavHK === 'hk-wrapper' && <Allroutes theme={theme}/>

      }
    </>
  );
}

export default App;
