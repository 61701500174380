import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import instance from "../../../utilities/Instance";
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import GroupRemoveTwoToneIcon from '@mui/icons-material/GroupRemoveTwoTone';
import { Personas2, PersonasParentezco } from "../../../structure/Tables";
import TablaGestion from "./Table";
import { isNumeric } from "../../../utilities/Utilities";
import { ModalsubPersonaCreator, ModalsubPersonaEditor } from "./ModalsubPersonaSelector";
import Loader2 from "./Loader2";
import ResponseCustomizedSnackbars, { CatchResponseCustomizedSnackbars } from "../notification/snackbar";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { AlertCodeInterpreter_Catch } from "../../alerts";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function ReceotorPersonaSelector (props){
    const {handleClose, setLoader, listaSeleccionadas, setListaSeleccionadas, handleRemovePersona, disabledExit, setDataResponse, setCatchResponse} = props //listaSeleccionadas es el array de objetos.
    const [contadorSecuencias, setContador] =useState([])
    const [searchfield, setSearchField]=useState('')
    const [opensub, setOpensub]=useState(false)
    const [opensub2, setOpensub2]=useState(false)
    const [loader, setLoader2]=useState(false)
    const [persona_id, setPersonaId]=useState(null)
    const [listPersonas, setListPersonas]=useState([])
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
      ]);
    
      const handleClosesub =()=>{
        setOpensub2(false)
        setOpensub(false);
        getData(null)
    }

    const createRow = async (row, newItem)=>{

      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        nombrecompleto: row?.nombrecompleto ||"",
        numeroidentificacion: row?.numeroidentificacion ||"",
        correopersonal: row?.correopersonal ||"",
        nombres: row?.nombres ||"",
        apellidos: row?.apellidos ||"",
        telefono: row?.telefono ||"",
        parentezco: row?.parentezco || ""
        }
        return insertEl
    }

    const searchBy=(e)=>{
        var cuerpo
        
        if(e.key==='Enter'){
            if(!searchfield){
                getData()
                return
            }
            if(isNumeric(searchfield)){
                cuerpo= "FindByIdentidadPerfilComplete/"+searchfield
            }else{
                cuerpo= "FindByNombrePerfilComplete/"+searchfield
            }
            if(cuerpo){
                getDataSearch(cuerpo)
            }
        }
    }

    const handleChange = (e)=>{
        setSearchField(e.target.value)
    }

    const getDataSearch=async (cuerpo)=>{ //Se usará para filtrar
        setLoader(true)
        
        setLoader(false)
    }

    const getData= async(e)=>{
        setLoader(true)
        
        var para=[]
        if( listaSeleccionadas.length>0){
            var registros= listaSeleccionadas;
            var newItem= contadorSecuencias
            registros.forEach(async (element) => {
                newItem= newItem+1
                para.push(await createRow(element, newItem))
            });
            setContador(newItem)
            setListPersonas(para)
        }else{
            setListPersonas([])
        }
        
        setLoader(false)
    }

    const SeleccionarPersona=(row)=>{ //adaptar para que quite de la lista
        //var nombre= row.nombrecompleto
        var id= row.idreal
        handleRemovePersona(id)
        //handleClose(nombre,id)
    }

    const SeleccionarPersonaID=(row)=>{
        var tosend={...row, id:row.idreal}
        setPersonaId(tosend)
        setOpensub2(true)
    }


    useEffect(()=>{
        getData()
    },[listaSeleccionadas])

    const botonesAccion = [
        {
        field: "action",
        headerName: "Agregar",
        width: 70,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? SeleccionarPersona(params.row):e.preventDefault()
                }} ><GroupRemoveTwoToneIcon titleAccess="Remover de la lista"/>
                </div>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? SeleccionarPersonaID(params.row):e.preventDefault()
                }} ><EditTwoToneIcon titleAccess="Editar datos persona"/>
                </div>
            </div>
            );
        },
        },
    ];
    

    return(
        <React.Fragment>
            
            <Modal
                hideBackdrop
                open={opensub}
                onClose={handleClosesub}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 580 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Nuevo</h2>
                    <ModalsubPersonaCreator
                        setDataResponse={setDataResponse}
                        setCatchResponse={setCatchResponse}
                        setLoader={setLoader2}
                        handleClose={handleClosesub}
                    />
                </Box>
                </>
                
            </Modal>
            <Modal
                hideBackdrop
                open={opensub2}
                onClose={handleClosesub}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 580 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Nuevo</h2>
                    <ModalsubPersonaEditor
                        persona_id={persona_id}
                        setDataResponse={setDataResponse}
                        setCatchResponse={setCatchResponse}
                        setLoader={setLoader2}
                        handleClose={handleClosesub}
                    />
                </Box>
                </>
                
            </Modal>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                    fullWidth
                        value={searchfield}
                        label={"Escriba cédula o nombre a buscar y pulse 'ENTER'"}
                        onChange={handleChange}
                        onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
            </Grid>
                
            <TablaGestion 
                botones ={botonesAccion}
                data={listPersonas} 
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {PersonasParentezco} 
                titulo={"Lista de personas."} 
            />
                {!disabledExit && <Grid container spacing={3}>
                    
                    <Grid item xs={6} md={6} lg={6}>
                        <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <Button onClick={()=>handleClose(listaSeleccionadas)}>Aceptar</Button>
                    </Grid>
                </Grid>}
                
        </React.Fragment>
    )
}