import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";


export default function CityButton (params){
    const { nombre, Label}=params
    const {listPaises, listEstados, listCiudades} =params.row
    const [openPais, setOpenPais]=useState(false)
    const [selectedCity, setSelectedCity]=useState(0)
    const [nameCity, setNameCity]= useState('')

    useEffect(()=>{
        setSelectedCity(params.row[nombre])
        if(listCiudades){
            var NameOfCity=listCiudades.find((ciudad)=>ciudad.id===params.row[nombre])
            setNameCity(NameOfCity?.nombre)
            params.row[Label]=NameOfCity?.nombre

            if(NameOfCity){
                var Country = listEstados.find((estado)=>estado.id===NameOfCity.estado_id)
                var Country = listPaises.find((pais)=>pais.id===Country.pais_id)
                params.row.pais_id=Country.id
            }
        }
    },[selectedCity])

    const handleOpenPais=(name)=>{
      setOpenPais(true)
      params.row.openPais=true
    }
  
    const handleClosePais=(ciudad)=>{
      if(ciudad){
        params.row[nombre]=ciudad
        setSelectedCity(ciudad)
        if(typeof params.row?.refreshdata === 'function'){
          params.row.refreshdata()
        }
      }
      setOpenPais(false)
        //params.row.openPais=false
    }
  
    return (
      <React.Fragment>
        <div className="cellAction">
          <SelectCityModal openPais={openPais} ciudadId={selectedCity} handleClosePais={handleClosePais} listPaises={listPaises} listEstados={listEstados} listCiudades={listCiudades}></SelectCityModal>
          {params.row.edit?null: <IconButton aria-label="delete" size="large" onClick={()=>handleOpenPais("field.name")}>
            <TravelExploreTwoToneIcon value={"field.name"} fontSize="inherit" />
          </IconButton>}
          {nameCity}
        </div>
      </React.Fragment>  
    );
  } 