import React, { useEffect } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function ProfileInfo(props){
    const {textFields, persona_id} = props;
    useEffect(()=>{
    },[textFields])
    return(
        <React.Fragment>
            <Title>Detalles de perfil</Title>
            
            <Box component="form" noValidate width={'100%'} sx={{ mt: 1 }} padding={4}>
            <Grid container spacing={3}>
              
              {textFields?.length>0 && textFields.map((field, index)=>{
              if(field.label=== 'foto' || field.name=== 'foto'){
                return null
              }
              return (
                <Grid key={"GridProfileInfo_"+index} item xs={12} md={6} lg={4} container >
                  {/* <p style={{fontSize:20, fontWeight:'bold'}}>
                  {field.label}:&nbsp; <a style={{fontSize:15, fontWeight:'normal'}}>{field.value}</a>
                  </p> */}
                  <TextField
                      key={field.name+"_"+index}
                      disabled
                      multiline
                      minRows={1}
                      maxRows={2}
                      margin="normal"
                      value={field.value===true?'SI':(field.value===false?'NO':field.value)}
                      variant="standard"
                      fullWidth
                      id="email"
                      label={field.label || field.name}
                      name={field.name}
                      autoComplete="email"
                      autoFocus
                  ></TextField>
                  
                </Grid>
              )
                })}
              
              
            </Grid>

            </Box>

        </React.Fragment>
    )

}