import Swal from "sweetalert2";
import axios from "axios"
import { functionsSwal } from "./dictionary";

export const flexibleModal = async (dataform, icon) =>{
    var value= await Swal.fire({
        title: dataform.title,
        iconHtml: icon && "<img src="+icon+" width='220' height='160'>",
        html: dataform.htmlx,
        confirmButtonText: dataform.btnText,
        confirmButtonColor: '#d7a200',
        focusConfirm: false,
        preConfirm: dataform.preConfirm
      })
    return value
}

export const OperationalFlexibleIcon = async (dataform, icon) =>{
    
    var value= await Swal.fire(
        {
          title: dataform.title,
          iconHtml: "<img src="+icon+" width='220' height='160'>",
          type: "success",
          text: dataform.content,
          confirmButtonText: "Ok",
          confirmButtonColor: '#d7a200',
        }
    )
    return value

}


export const ProcessFlexibleModal = async (dataform, icon) =>{
    
    var value =await Swal.fire({
        width: 'auto',
        title: dataform.title,
        html: dataform.html,
        /*html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
        <input type="password" id="password" class="swal2-input" placeholder="Password">`,*/
        confirmButtonText: dataform.buttomTitle,
        focusConfirm: false,
        preConfirm: ()=>functionsSwal["selector"]("select00") || null,
        /*preConfirm: () => {
            const login = Swal.getPopup().querySelector('#login').value
            const password = Swal.getPopup().querySelector('#password').value
            if (!login || !password) {
              Swal.showValidationMessage(`Please enter login and password`)
            }
            return { login: login, password: password }
          }*/
      }).then((result) => { 
        return result.value
      })
    return value
}

export const SampleFlexibleModal = async (dataform, icon) =>{
    
  var value =await Swal.fire({
      width: 'auto',
      title: dataform.title,
      html: dataform.html,
      /*html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
      <input type="password" id="password" class="swal2-input" placeholder="Password">`,*/
      confirmButtonText: dataform.buttomTitle,
      focusConfirm: false,
      preConfirm: dataform.preConfirm,
      /*preConfirm: () => {
          const login = Swal.getPopup().querySelector('#login').value
          const password = Swal.getPopup().querySelector('#password').value
          if (!login || !password) {
            Swal.showValidationMessage(`Please enter login and password`)
          }
          return { login: login, password: password }
        }*/
    }).then((result) => { 
      return result.value
    })
  return value
}