import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";
import SelectPersonaModal from "./PersonaModal";


export default function PersonaButton (params){
  const { nombre, Label}=params
  const [openPersona, setOpenPais]=useState(false)
  const [selectedPerson, setSelectedPerson]=useState(0)
  const [namePerson, setNamePerson]= useState()

  useEffect(()=>{
      setSelectedPerson(params.row[nombre])
      setNamePerson(params.row[Label])
  },[selectedPerson])
    
  const handleOpenPais=(name)=>{
    setOpenPais(true)
    params.row.openPersona=true
  }
  
  const handleClosePersona=(nombrecompleto, id)=>{
    if(id){
      params.row[nombre]=id
      params.row[Label]=nombrecompleto
      setNamePerson(nombrecompleto)
      setSelectedPerson(id)
    }
    setOpenPais(false)
  }
  
  return (
    <React.Fragment>
      <div className="cellAction">
        <SelectPersonaModal openPersona={openPersona} handleClosePais={handleClosePersona}></SelectPersonaModal>
        {params.row.edit?null:<IconButton aria-label="delete" size="large" onClick={()=>handleOpenPais("field.name")}>
          <PersonSearchTwoToneIcon value={"field.name"} fontSize="inherit" />
        </IconButton>}
        {namePerson}
      </div>
    </React.Fragment>  
  );
}


  export function PersonaButtonforForms (props){
    const { value, value_name, Label, label_name, handleChange, title, setDataResponse, setCatchResponse}=props
    const [openPersona, setOpenPais]=useState(false)
    const [selectedPerson, setSelectedPerson]=useState(0)
    const [namePerson, setNamePerson]= useState('')

    useEffect(()=>{
        setSelectedPerson(value)
        setNamePerson(Label)
        
    },[selectedPerson, value])
    
    const handleOpenPais=()=>{
      setOpenPais(true)
    }
  
    const handleClosePersona=(nombrecompleto, id)=>{
      if(id){
        handleChange(id, value_name, nombrecompleto, label_name)
        setNamePerson(nombrecompleto)
        setSelectedPerson(id)
      }
      setOpenPais(false)
    }
  
    return (
      <React.Fragment>
        <div className="cellAction" style={{display:'flex', width:'100%'}} >
          <SelectPersonaModal setDataResponse={setDataResponse} setCatchResponse={setCatchResponse} openPersona={openPersona} handleClosePais={handleClosePersona}></SelectPersonaModal>
          {<IconButton aria-label="delete" size="large" onClick={handleOpenPais}>
            <PersonSearchTwoToneIcon value={"field.name"} fontSize="inherit" />
          </IconButton>}
          <TextField
            label={title}
            multiline
            variant="standard"
            maxRows={1}
            margin="normal"
            fullWidth
            value={namePerson||''}

          />
          
        </div>
      </React.Fragment>  
    );
  } 