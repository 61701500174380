import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function AditionalModalHyperText (props){
    const {handleClose, defaultText, label}= props
    const [text, setText]= useState('')
    const actualizarTexto=(e)=>{
        setText(e.target.value)
    }
    useEffect(()=>{
        setText(defaultText)
    },[])
    return(
        <React.Fragment>
            <TextField
                label={label}
                fullWidth
                value={text || ''}
                onChange={actualizarTexto}
                multiline
                minRows={10}
                maxRows={10}
            ></TextField>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    {text && <Button onClick={()=>handleClose(text)}>Aceptar</Button>}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default function LongDescriptionModal(props){
    const {openDescription, handleCloseDescription, defaultText, label} = props;
    const [loader, setLoader]=useState(false);

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={openDescription}
                onClose={handleCloseDescription}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 600 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Describa {label}</h2>
                    <AditionalModalHyperText 
                        handleClose={handleCloseDescription}
                        defaultText={defaultText}
                        label={label}
                    />
                    
                </Box>
                </>
                
            </Modal>
        </React.Fragment>
    )
}