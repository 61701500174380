//Detalle perfil seleccionado. Muestra información de perfil familiar (datos familiares), perfil religioso (vida religiosa), perfil profesional (Perfil profesional)

import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RegistroMisionView from "./RegistroMision";
import RegistroClinicoView from "./RegistroClinico";
import RegistroProfesionalView from "./RegistroProfesional";
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import instance from "../../utilities/Instance";
import ProfileBase from "../../components/shared/layout/profileBaseSkeleton";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ProfileInfo from "../../components/shared/layout/profileInfoSkeleton";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F , AlertCodeInterpreter_preForm} from "../../components/alerts";
import { generarForm, generarFormCompleto, generarFormPerfilReligioso, isNumeric } from "../../utilities/Utilities";
import { getFormatUp } from "../../structure/toUp";
import PersonasView from "./PersonasView";
import Loader2 from "../../components/shared/layout/Loader2";
import FormsEdit from "../../components/shared/layout/Forms";
import PlaylistAddCheckCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCheckCircleTwoTone';
import Avatar from '@mui/material/Avatar';
import { yellow } from "@mui/material/colors";
import Title from "../../components/shared/layout/Title";
import { Button } from "@mui/material";

export default function DetallePerfil(props){
    const {persona_id, rules, listCatalogos, perfil, title, setDataResponse, setCatchResponse, actionPrint, listPaises, listEstados, listCiudades, specialRules, tipoDocumento, fieldsDocsForArraysJson}=props;
    const [loaderPerfil, setLoaderPerfil]= useState(false)
    const [dataPerfil, setDataPerfil]=useState([])
    const [formDataSearch, setFormDataSearch]= useState([])
    const [formDataSelect, setFormDataSelect]= useState([])
    const [formatPerfilReligioso, setFormatPerfilReligioso]= useState([])
    const [formDataDate, setFormDataDate]= useState([])
    const [formDataToggleSwitch, setFormDataToggleSwitch]= useState([])
    const [formDataModalSearch, setFormDataModalSearch]= useState([])
    const [formDatamModalPersonaSearch, setFormDatamModalPersonaSearch]= useState([])
    const [formDatamModalPersonasListSearch, setFormDatamModalPersonasListSearch]= useState([])
    const [formDataModalText, setFormModalText]= useState([])
    const [formDataModalFeatures, setFormFeaturesModal]= useState([])
    const [formDataModalFeaturesArray, setFormFeaturesArrayModal]= useState([])
    const [datosPersona, setDatosPersona]= useState({});
    const [existPerfil, setExistPerfil]=useState(false);

    const handleChange = (e) =>{
        var datosPers= {...datosPersona}
        datosPers[e.target.name]=e.target.value
        if(e.extra){
            datosPers[e.extra.name]=e.extra.value
        }
        setDatosPersona(datosPers)
      }

    const generalQuery=async(base)=>{
        
    }
    
    const printButton= actionPrint? <Button
    key={"printDetallePerfil"}
    id={"boton1"}
    onClick={()=>actionPrint(title, datosPersona)}
    sx={{
        "&:hover":{
            //background:"#d0d0d0",
            //borderBottom: "1px solid #d0d0d0"
        },
        width:5,
        padding:0,
    }}
>
<LocalPrintshopTwoToneIcon titleAccess="Imprimir Reporte"></LocalPrintshopTwoToneIcon>
</Button> :null

    const setterSearch= (response)=>{
        var datosPersona= response.data.data.data;
        
        if(!datosPersona){
          datosPersona={}
        }
        if(Object.keys(datosPersona).length===0){
            setFormDataSearch([])
            setFormDataSelect([])
            setFormDataDate([]);
            setFormDataToggleSwitch([]);
            setFormDataModalSearch([])
            setFormDatamModalPersonaSearch([])
            setFormDatamModalPersonasListSearch([])
            setDatosPersona({})
            setExistPerfil(false)
            return
        }
        
        var datosform= generarForm(datosPersona);
        var datosprueba= generarFormCompleto(datosPersona);
        var datosordenadorPerfilReligioso= generarFormPerfilReligioso(datosPersona)
        var dictionaryRuleCatalogo=rules.find((rule)=>rule.nombre==="Catalogo parent")?.configuracion
        datosprueba?.selectfield?.forEach(element => {
          element.padre=dictionaryRuleCatalogo[element.paridad.name]
          element.padrefield="catalogo_id"
        });
        var formWithFormat=[]
        if(perfil==="PerfilReligioso/"){
            //generar inputs ordenados. reglas + estructura.
            formWithFormat=generarFormPerfilReligioso(datosPersona)
        }
        setFormatPerfilReligioso(formWithFormat)
        setFormDataSearch(datosprueba.textfield || []);
        setFormDataSelect(datosprueba.selectfield || []);
        setFormDataDate(datosprueba.DateTimeField || []);
        setFormDataToggleSwitch(datosprueba.ToggleSwitchField || []);
        setFormDataModalSearch(datosprueba.modalSearch || [])
        setFormDatamModalPersonaSearch(datosprueba.modalPersonaSearch || [])
        setFormDatamModalPersonasListSearch(datosprueba.modalPersonasSearch ||[])
        setFormModalText(datosprueba.modalText||[])
        setFormFeaturesModal(datosprueba.featuresModal||[])
        setFormFeaturesArrayModal(datosprueba.featuresArrayModal||[])
        
        var generalform={}
        
        datosform.forEach(element => {
            generalform={...generalform, [element.name]:element.value}
          });
        setDatosPersona(generalform)
        setExistPerfil(true)
        
    }
    
    const SubmitHandle = async(e) =>{
      e.preventDefault();
      
      const data = new FormData(e.currentTarget);
      var toSend={}
      toSend={...datosPersona}
      for( var key in toSend){
        if(typeof toSend[key] === 'object'){
            toSend[key]=JSON.stringify(toSend[key])
        }
      }

      guardarRegistro(toSend)
    }

    const guardarRegistro = async (row) =>{
        var base=perfil+"update/"+persona_id
        //var toup=getFormatUp(row, option)
        
        actualizarRegistro(row, base, true)
  
    }

    const handleSelect = (e) =>{
        var datosPers= {...datosPersona}
        /* datosPers[e.target.name]=e.target.value
        setDatosPersona(datosPers) */
    }

    const crearNuevoPerfil=async()=>{
        var base=perfil+"Create"
        var row={"persona_id":persona_id}
        //var toup=getFormatUp(row, option)
        actualizarRegistro(row, base, false)
    }

    const actualizarRegistro=async(data, base, caseAction)=>{
        setLoaderPerfil(true)
        await instance.postForm(base,data)
        .then((response)=>{
            setDataResponse(response.data.data)
            if(!caseAction ){
                setterSearch(response)
            }
        })
        .catch((error)=>{
            if(!caseAction ){
                setExistPerfil(false);
            }
            console.error(error)
            setCatchResponse(error?.response?.data)
         })
        setLoaderPerfil(false)
    }

    const consultarPerfil=async()=>{
        setLoaderPerfil(true)
        await instance.get(perfil+"FindByPersona/"+persona_id)
        .then((response)=>{
            setterSearch(response)
        })
        .catch((error)=>{
            setExistPerfil(false); 
            if(error?.response?.data?.error){
                AlertCodeInterpreter_Catch(error.response.data.error)
              }
            console.error(error)})
        setLoaderPerfil(false)
    }

    useEffect(()=>{
        consultarPerfil()
    },[persona_id,title])

    return(
        <React.Fragment >
            <Loader2 onLoader={loaderPerfil}/>
            {existPerfil?<FormsEdit 
                aditionalButton={printButton}
                fieldsDocsForArraysJson={fieldsDocsForArraysJson}
                tipoDocumento={tipoDocumento}
                idreferencia={persona_id}
                specialFieldsPR={formatPerfilReligioso}
                specialRules={specialRules}
                listPaises={listPaises} 
                listEstados={listEstados} 
                listCiudades={listCiudades}
                setDataResponse={setDataResponse} 
                setCatchResponse={setCatchResponse}
                textFields={formDataSearch} 
                selectFields={formDataSelect} 
                toggleSwitchFields={formDataToggleSwitch} 
                ModalSearchFields={formDataModalSearch}
                DateFields={formDataDate}
                ModalPersonaSearch={formDatamModalPersonaSearch}
                ModalPersonasSearch={formDatamModalPersonasListSearch}
                ModalText={formDataModalText}
                ModalFeatures={formDataModalFeatures}
                ModalFeaturesArray={formDataModalFeaturesArray}
                handleChange={handleChange} 
                formData={datosPersona}
                SubmitHandle={SubmitHandle}
                handleSelect={handleSelect}
                selectListOptions={listCatalogos}
                title={title}
            />:
            <div className="conta" style={{padding:15, display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                {/* <div className="background1">
                    {[...Array(500).keys()].map((element)=>(
                    <div className="circle-container1">
                        <div className="circle"></div>
                    </div>))}
                </div>
                <p className="message"><Title>Crear Registro</Title></p> */}
                <Title>Crear nuevo registro de {title}</Title>
                <Avatar className="palpitantAvatar" sx={{ width: 200, height: 200, bgcolor: yellow[900], cursor:'pointer' }} onClick={crearNuevoPerfil}>
                    <PlaylistAddCheckCircleTwoToneIcon sx={{ width: 'auto', height: 'auto', bgcolor: yellow[700] }}/>
                </Avatar>
            </div>
            
            }
        </React.Fragment>
    )
}