import { OperationalFlexibleIcon, ProcessFlexibleModal, SampleFlexibleModal } from "./modals";
import { cases } from "./dictionary";
import Swal from "sweetalert2";
import { functionsSwal } from "./dictionary";

export const AlertCodeInterpreter_M = async(info,caseIcon,Next) => {
    var icon= cases[""+caseIcon].icon
    info.title=cases[""+caseIcon].title
    
    let valor = await OperationalFlexibleIcon(info,icon)
    if(await valor){
        if(Next){
            if (typeof Next === 'function') {
                return await Next();
            }else{
                return Next;
            }
        }
        return Next;
    }
}

export const AlertCodeInterpreter_T = async(info,caseIcon,Next) => {
  var icon= cases[""+caseIcon].icon
  
  let valor = await OperationalFlexibleIcon(info,icon)
  if(await valor){
      if(Next){
          if (typeof Next === 'function') {
              return await Next();
          }else{
              return Next;
          }
      }
      return Next;
  }
}

/**
 * Método usado como intérprete estandar de las respuestas a las consultas del servidor. Método standar.
 * @param {JSON} info - Estructura de respuesta estandar conformado principalmente por codecase | title | content
 */
export const AlertCodeInterpreter_F = async(info) => {
  var icon= cases[""+info.codecase ].icon
  const configuration = localStorage.getItem('auth_jwt');
  var valor = await OperationalFlexibleIcon(info,icon)
  if(valor){
      
      return info.error;
  }
}

export const AlertCodeInterpreter_Catch = async(info) => {
  var icon= cases["11"].icon
  info.content= info.description
  info.title=info.type
  const configuration = localStorage.getItem('auth_jwt');
  let valor = await OperationalFlexibleIcon(info,icon)
  if(await valor){
      
      return info.error;
  }
}

export const AlertSimpleStandar = async(info, caseIcon) =>{
  var icon= cases[""+caseIcon].icon
  info.title=cases[""+caseIcon].title
  let valor = await OperationalFlexibleIcon(info,icon)
    if(await valor){
        return valor;
    }
}

/**
 * Método usado como intérprete estandar de las respuestas a las consultas del servidor. Genera un formulario. Se usa en caso de que la respuesta sea en array.
 * @param {JSON} info - Estructura de respuesta estandar conformado principalmente por codecase | title | ?preConfirm | ?buttomTitle
 * 
 */
export const AlertCodeInterpreter_preForm = async(info) => {
    var icon= cases[""+info.codecase].icon
    info.title=cases[""+info.codecase].title
    /**
     * info.form debe entrar creado. Aquí se construye el formulario a partir de esos valores.
      {
        label: label,
        name: key,
        value: datos[key] || ''
        type: ??
      }
     */
    info.html=""
    var preConfirm=''
    var prefunction=''
    var returned=''
    var ifvalues=''
    var count = 0
    var options=""
    var first
    info.form.forEach(element => {
        /*var input= `<input type= "${element.type||'text'}" id= "${element.key}" class="swal2-input" placeholder="${element.label}">
        `*/
        if(!first){first=element.value}
        var input= `<option value="${element.value}"><div><br>  ${element.nombrecompleto}</div></option>
        `
        options=options+input
        count++
        //info.html=info.html+input;
        
        returned= returned+` ${element.name}: ${element.name},`

        if(ifvalues.length===0){
            ifvalues = ifvalues+` !${element.name} `
        }else{
            ifvalues = ifvalues+`|| !${element.name} `
        }
    });
    
    
    ifvalues= '!selected'
    returned= 'selected'
    info.html=`<p>Nombre completo:</p>
               <select name="select" id= "select00" class="swal2-input" onchange='var sel = document.getElementById("select00");var respSel = document.getElementsByClassName("respSelector")[0];respSel.innerHTML=sel.value;'>
                ${options}
               </select>`+`<p>Nombre completo: <label class="respSelector">número de identificación: ${first}</label> </p>
               
               `
    
    info.buttomTitle=info.buttomTitle || "confirmar"
    info.preConfirm="selector"
    info.key="select00"
    //info.toTrimm=info.toTrimm||'enviado.'

    let valor = await ProcessFlexibleModal(info,icon)
    if(await valor){
        return valor;
    }else{
        return valor;
    }
  }

  export const ExpiredSession =async(detalle)=>{
    var icon= cases["12"].icon
    var info= {}
    info.title="Sesión expirada."
    info.content= "Su sesión ha expirado. Será redirigido a la ventana principal. "+(detalle?.length? '❗️Razón: '+detalle:'')
    var valor = await OperationalFlexibleIcon(info,icon)
    if(valor){
        
      return true;
    }else{
      return true;
    }
  
  }

  export const Modal_SimplePreForm = async(info) => {
    
    info.html=""
    var first
    
    
    
    info.html=`<input type="text" id="toSearch" class="swal2-input" placeholder="Ingrese dato.">`
    info.preConfirm=() => {
        const toSearch = Swal.getPopup().querySelector('#toSearch').value
        if (!toSearch) {
          Swal.showValidationMessage(`Por favor. Complete el formulario para poder continuar.`)
        }
        return toSearch
      }
    info.buttomTitle=info.buttomTitle || "confirmar"
    //info.toTrimm=info.toTrimm||'enviado.'

    let valor = await SampleFlexibleModal(info,"")

    if(await valor){
        return valor;
    }else{
        return valor;
    }
  }

  export const Modal_PaisPreForm = async(info) => {
    
    info.html=""
    var first
    
    
    
    info.html=`<input type="text" id="nombre" class="swal2-input" placeholder="Nombre del país.">
    <input type="text" id="nomenclatura" class="swal2-input" placeholder="Nomenclarura.">
    <input type="text" id="codigo" class="swal2-input" placeholder="Código del país.">`
    info.preConfirm=() => {
        const nombre = Swal.getPopup().querySelector('#nombre').value
        const nombreclatura = Swal.getPopup().querySelector('#nomenclatura').value
        const codigo = Swal.getPopup().querySelector('#codigo').value
        if (!nombre || !nombreclatura || !codigo) {
          Swal.showValidationMessage(`Por favor. Complete el formulario para poder continuar.`)
        }
        return {nombre, nombreclatura, codigo}
      }
    info.buttomTitle=info.buttomTitle || "confirmar"
    //info.toTrimm=info.toTrimm||'enviado.'

    let valor = await SampleFlexibleModal(info,"")

    if(await valor){
        return valor;
    }else{
        return valor;
    }
  }

export const AlertFilterFlexible = async(info)=>{
  
}