import React, { useState, useEffect } from "react";
import Title from "../../shared/layout/Title";
import BeenhereTwoToneIcon from '@mui/icons-material/BeenhereTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import { Button, Grid, TextField } from "@mui/material";
import TablaGestion from "../../shared/layout/Table";
import { DocumentosFormato } from "../../../structure/Tables";
import instance from "../../../utilities/Instance";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F } from "../../alerts";
import { AddFileModal } from "../Modal/addFileModal";
import { debugBase64, sleep } from "../../../utilities/Utilities";

export function DocumentUpper(props){
    const {tipoDocumento, persona_id, tablaRef, setLoader, handleClose}= props;
    const [searchfield, setSearchField]=useState('')
    const [listDocumentos, setListDocumentos]=useState([])
    const [contadorSecuencias, setContador] =useState(0)
    const [opensub, setOpensub]=useState(false)
    const [validator, setValidator]=useState(false)
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'extension',
          sort: 'asc',
        },
    ]);

    const getDataSearch=async (cuerpo)=>{
        setLoader(true)
        setLoader(false)
    }

    const handleCloseSup=async(file, name, isForEdit)=>{
        setOpensub(false)
        if(!file || !name){
            return
        }
        var toSend={
            persona_id: persona_id,
            ct_tipo_documento: tipoDocumento,
            documento: name,
            file: file,
        }
        setLoader(true)
        await instance.postForm("Documento/Create", toSend)
        .then(response=>{
            if(!AlertCodeInterpreter_F(response.data.data)){
                
            }
            getData()
        })
        .catch(error=>{
            console.error(error)
        })
        setLoader(false)
    }
    const SeleccionarDocumento=(row)=>{
        handleClose(row.idreal, row.documento)
    }

    const EliminarDocumento=async(row)=>{
        
        setLoader(true)
        await instance.get("Documento/RemoveById/"+row.idreal)
        .then(response=>{
            if(!AlertCodeInterpreter_F(response.data.data)){
                
            }
            getData()
        })
        .catch(error=>{
            console.error(error)
        })
        setLoader(false)
        
    }

    const VerDocumento=async(row)=>{
        
        setLoader(true)
        await instance.get("Documento/GetDocById/"+row.idreal)
        .then(async response=>{
            if(!AlertCodeInterpreter_F(response.data.data)){
            }else{
                debugBase64("data:application/pdf;base64,"+response.data.data.data)
                sleep(2000)
            }
            
            //getData()
        })
        .catch(error=>{
            console.error(error)
        })
        setLoader(false)
        
    }

    const createRow = async (row, newItem)=>{
        var insertEl={
          id: newItem || "",
          idreal: row?.id || null,
          ct_tipo_documento: row?.ct_tipo_documento ||"",
          documento: row?.documento ||"",
          extension: row?.extension ||"", //descripción o titulo del documento
          //correopersonal: row?.correopersonal ||"",
          }
        return insertEl
    }

    const handleChange = (e)=>{
        setSearchField(e.target.value)
    }

    const searchBy=(e)=>{
        var cuerpo
        if(e.key==='Enter'){
            if(!searchfield){
                getData()
                return
            }else{
                getDataSearch(searchfield)

            }
        }
    }

    const getData= async(e)=>{
        setLoader(true)
        await instance.get("Documento/ListAllByPersona/"+persona_id+"/"+tipoDocumento)
        .then(async(response)=>{
            var para=[]
            if( response.data.data.length>0){
              var registros= response.data.data;
              var newItem= contadorSecuencias
              registros.forEach(async (element) => {
                newItem= newItem+1
                para.push(await createRow(element, newItem))
              });
              setContador(newItem)
              setListDocumentos(para)
            }else{
                setListDocumentos([])
            }
          })
          .catch((error)=>{
            if(error?.response?.data?.error){
                AlertCodeInterpreter_Catch(error.response.data.error)
              }
            console.error(error)
          })
          setLoader(false)
    }

    useEffect(()=>{
        getData()
    },[validator])

    const botonesAccion = [
        {
        field: "action",
        headerName: "Agregar",
        width: 100,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? SeleccionarDocumento(params.row):e.preventDefault()
                }} ><BeenhereTwoToneIcon titleAccess="Seleccionar como principal."/>
                </div>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? EliminarDocumento(params.row):e.preventDefault()
                }} ><DeleteTwoToneIcon titleAccess="Eliminar."/>
                </div>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? VerDocumento(params.row):e.preventDefault()
                }} ><FindInPageTwoToneIcon titleAccess="Ver/descargar"/>
                </div>
            </div>
            );
        },
        },
    ];

    const validFunc=()=>{
        setValidator(!validator)
    }

    return (
        <React.Fragment>
            <AddFileModal
                validFunc={validFunc}
                open={opensub}
                setOpen={setOpensub}
                handleClose={handleCloseSup}
            />
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                    fullWidth
                        value={searchfield}
                        label={"Escriba algo y pulse 'ENTER' para buscar"}
                        onChange={handleChange}
                        onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                <TablaGestion 
                    botones ={botonesAccion}
                    data={listDocumentos} 
                    sortModel={sortModel} 
                    setSortModel={setSortModel} 
                    formato = {DocumentosFormato} 
                    titulo={"Lista resultante."} 
                />
                
                </Grid>
                <Grid container spacing={3}>
                    
                    <Grid item xs={12} md={6} lg={6}>
                        <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Button onClick={()=>setOpensub(true)}>Subir nuevo</Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
