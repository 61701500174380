import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import instance from "../../../utilities/Instance";
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import { DetalleCatalogo } from "../../../structure/Tables";
import TablaGestion from "./Table";
import { isNumeric } from "../../../utilities/Utilities";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F } from "../../alerts";
import ResponseCustomizedSnackbars, { CatchResponseCustomizedSnackbars } from "../notification/snackbar";


export function ModalsubPersonaCreator (props){
    const { handleClose, setLoader, setDataResponse, setCatchResponse} = props
    const [contadorSecuencias, setContador] =useState([])
    const [datosPersona, setDatosPersona]=useState('')
    const [listPersonas, setListPersonas]=useState([])
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
      ]);


    const createPerson=(e)=>{
        var cuerpo='Create'
        if(!datosPersona.numeroidentificacion && !datosPersona.nombres && !datosPersona.telefono && !datosPersona.apellidos){
            return
        }
        getDataSearch(cuerpo)
       
    }

    const handleChange = (e)=>{
        setDatosPersona({
            ...datosPersona,
            [e.target.name]:e.target.value})
    }

    const getDataSearch=async (cuerpo)=>{
        setLoader(true)
        var data = {
            ...datosPersona
        }
        await instance.postForm("Persona/"+cuerpo, data)
        .then(async(response)=>{
            if(response?.data?.data){
            setDataResponse(response.data.data)}
          /* if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          } */
          handleClose()
        }).catch(error =>{
            console.error(error)
            if(error?.response?.data){
                setCatchResponse(error.response.data)}
        })
        setLoader(false)
    }

    const handleKeyDownNumeroidentificacion = async(event) =>{
        const { key } = event;
        const { name, value } = event.target;
        const keys = ["Escape", "Enter"];
        if (keys.indexOf(key) > -1) {
            if (name === 'numeroidentificacion') {
                
                setLoader(true);
                await instance.get("Persona/FindByIdentidadExternal/"+value)
                .then(async(response)=>{
                    setLoader(false);
                if(! await AlertCodeInterpreter_F(response.data.data)){
                    var information= response.data?.data?.data?.data||{}
                    var nombres=information.name||''
                    nombres=nombres.split(' ');
                    var apellidos=''
                    if(nombres.length===3){
                        apellidos = nombres.slice(0, 2).join(' ');
                        nombres= nombres.slice(2, 3).join(' ');
                    }else if(nombres.length===2){
                        apellidos = nombres[0];
                        nombres= nombres[1];
                    }else if(nombres.length>3){
                        apellidos = nombres.slice(0, 2).join(' ');
                        nombres= nombres.slice(2, nombres.length).join(' ');
                    }else{
                        nombres=nombres[0]
                    }

                    
                    setDatosPersona({
                        ...datosPersona,
                        numeroidentificacion:information.identity,
                        nombres: nombres,
                        apellidos: apellidos
                    })
                    //await setterSearch(response)
                }else{
                    //setPersonaId(0)
                    //resetAll()
                    
                    //setDataTable([])
                }
                
                }).catch((error)=>{
                    if(error?.response?.data?.error){
                    AlertCodeInterpreter_Catch(error.response.data.error)
                    }
                    console.error(error)
                })
                setLoader(false);
            }
        }
    }


    useEffect(()=>{
    },[])


    return(
        <React.Fragment>
            
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                        fullWidth
                        name={"numeroidentificacion"}
                        value={datosPersona["numeroidentificacion"] || ''}
                        label={"Número de identificación"}
                        onChange={handleChange}
                        onKeyDown={handleKeyDownNumeroidentificacion}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"nombres"}
                        value={datosPersona["nombres"] || ''}
                        label={"Nombres"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"apellidos"}
                        value={datosPersona["apellidos"]|| ''}
                        label={"Apellidos"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"telefono"}
                        value={datosPersona['telefono'] || ''}
                        label={"Teléfono"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"correopersonal"}
                        value={datosPersona["correopersonal"] || ''}
                        label={"Correo."}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                
            </Grid>
                
            <Grid container spacing={3}>
                    
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose()}>Cancelar</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>createPerson()}>Agregar</Button>
                </Grid>
            </Grid>
                
        </React.Fragment>
    )
}

export function ModalsubPersonaEditor (props){
    const { handleClose, setLoader, setDataResponse, setCatchResponse, persona_id} = props
    const [contadorSecuencias, setContador] =useState([])
    const [datosPersona, setDatosPersona]=useState({...persona_id})
    const [listPersonas, setListPersonas]=useState([])
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
      ]);


    const updatePerson=(e)=>{
        if(!persona_id){return}
        var cuerpo='update/'+persona_id.id
        if(!datosPersona.numeroidentificacion && !datosPersona.nombres && !datosPersona.telefono && !datosPersona.apellidos){
            return
        }
        getDataSearch(cuerpo)
       
    }

    const handleChange = (e)=>{
        setDatosPersona({
            ...datosPersona,
            [e.target.name]:e.target.value})
    }

    const getDataSearch=async (cuerpo)=>{
        setLoader(true)
        var data = {
            ...datosPersona
        }
        await instance.postForm("Persona/"+cuerpo, data)
        .then(async(response)=>{
            setDataResponse(response.data.data)
          /* if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          } */
          handleClose()
        }).catch(error =>{

            setCatchResponse(error.response.data)
        })
        setLoader(false)
    }



    useEffect(()=>{
        setDatosPersona(persona_id)
    },[persona_id])


    return(
        <React.Fragment>
            
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                        fullWidth
                        name={"numeroidentificacion"}
                        value={datosPersona["numeroidentificacion"] || ''}
                        label={"Número de identificación"}
                        onChange={handleChange}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"nombres"}
                        value={datosPersona["nombres"] || ''}
                        label={"Nombres"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"apellidos"}
                        value={datosPersona["apellidos"]|| ''}
                        label={"Apellidos"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"telefono"}
                        value={datosPersona['telefono'] || ''}
                        label={"Teléfono"}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <TextField
                        fullWidth
                        name={"correopersonal"}
                        value={datosPersona["correopersonal"] || ''}
                        label={"Correo."}
                        onChange={handleChange}
                        //onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
                
            </Grid>
                
            <Grid container spacing={3}>
                    
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose()}>Cancelar</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>updatePerson()}>Actualizar</Button>
                </Grid>
            </Grid>
                
        </React.Fragment>
    )
}