import Swal from "sweetalert2";
import penwritin from "./../../assets/contextAlert/loading-edit.gif"
import ghost from "./../../assets/contextAlert/loading-ghost.gif"
import erroricon from "./../../assets/contextAlert/loading-error.gif"
import deleted from "./../../assets/contextAlert/loading-deleted.gif"
import check from "./../../assets/contextAlert/check.gif"
import lock from "./../../assets/contextAlert/lock.gif"



export const cases={
    "1":{
        title: "Creado",
        icon: check
    },
    "2":{
        title: "Actualizado",
        icon: check
    },
    "3":{
        title: "Editado",
        icon: penwritin
    },
    "4":{
        title: "Eliminado",
        icon: deleted
    },
    "5":{
        title: "Registrado",
        icon: check
    },
    "6":{
        title: "No encontrado",
        icon: ghost
    },
    "7":{
        title: "Encontrado",
        icon: check
    },
    "8":{
        title: "Listado",
        icon: check
    },
    "9":{
        title: "Bienvenido",
        icon: check
    },
    "10":{
        title: "Inconveniente",
        icon: ghost
    },
    "11":{
        title: "Error",
        icon: erroricon
    },
    "12":{
        title: "Bloqueado",
        icon: lock
    },
    "222": {
        title: 'Resultados múltiples. Seleccione una opción.',
        icon: check
    }
}


export const functionsSwal={
    "selector": async (key)=>{
        const selected = await Swal.getPopup().querySelector('#'+key).value

        if( !selected){
            Swal.showValidationMessage('Por favor, complete el formulario.')
        }
        return selected
    },
    "respSelector": ()=>{
        var sel = document.getElementById("select00");
        var respSel = document.getElementsByClassName("respSelector")[0];
        respSel.innerHTML=sel.value;
    }
}

export const filters={
    "superiora_numeroidentificacion":"",
    "superiora_id": "",
    "persona_numeroidetificación":"",
    "persona_id":"",
    "desde": "",
    "hasta": ""
}

