const datosMaestros = (state = {}, action) => {
    switch (action.type) {
        case "@property/add":
            return {
                ...state,
                [action.prop]: action.payload
            }
        case "@property/update":
            const data = state[action.prop];
            let informacion = null;
            if (action.isObject) {
                informacion = {
                    ...data,
                    ...action.payload
                }
            } else {
                informacion = data.map(item => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            ...action.payload
                        }
                    }
                    return item;
                });
            }
            return {
                ...state,
                [action.prop]: informacion
            };
        case '@property/reset':
            return {};
        default:
            return state;
    }
}

export default datosMaestros