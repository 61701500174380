import React from "react";
import Dashboard from "../../components/shared/layout/Dashboard";
import Skeleton from "./Skeleton";

export default function PersonaView(props){
    return(
        <React.Fragment>
            <Dashboard {...props} Skeleton={Skeleton} menu_id={7}/>

        </React.Fragment>
    )
}