import { Avatar, Button, Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import Title from "../../../shared/layout/Title";

export function CedulaUp(props){
    const {setDocument, document, handleChange, fileUp, warningColor}=props

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{justifyContent:'center', display:'flex',alignItems:'center', flexDirection:'column'}}>
                <Title>Agregar documento</Title>
                    <Avatar
                        alt="Avatarimg"
                        sx={{ height:150,width:150 }}
                    >
                        <label htmlFor="file2">
                            <BadgeTwoToneIcon color={document?'secondary':(warningColor?'error':'')} sx={{height:"100%",width:"100%", cursor:'pointer'}}/>
                        </label>
                    </Avatar>
                    <input
                        type="file"
                        id="file2"
                        onChange={(e)=>fileUp(e)}
                        style={{ display: "none" }}
                      />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{justifyContent:'center', display:'flex',alignItems:'center', flexDirection:'column'}} >
                    <Title>Previsualización</Title>
                    <iframe style={{width:'100%'}} src={document}></iframe>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}