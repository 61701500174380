import * as React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Dashboard from '../components/shared/layout/Dashboard';
import { createTheme } from '@mui/material';
import HomeView from '../screens/home';
import ReportView from '../screens/registro';
import GeoView from '../screens/Geo';
import CatalogoView from '../screens/Catalogo';
import PersonaView from '../screens/persona';
import UsuarioView from '../screens/usuario';
import FormatoView from '../screens/Formato';

export default function Allroutes(props){
    const {theme} = props
    const [menus, setMenus]=React.useState([])

    React.useEffect(()=>{
      setMenus(localStorage.getItem("permisos")?.split('_'))
    },[])

    return(
        <>
        <Routes>
          <Route path="/">
            <Route index element={<HomeView theme={theme}/>} />
            {<Route path='Registros' element={<ReportView theme={theme}/>}/>}
            <Route path= 'Perfiles' element={<HomeView theme={theme}/>}/>
            <Route path='Personas' element={<PersonaView theme={theme}/>} />
            
            <Route path='Administracion' >
              <Route index element={<HomeView />} />
              <Route path='Ciudades' element={<GeoView />} />
              <Route path='Catalogo' element={<CatalogoView/>} />
              <Route path='Usuarios' element={<UsuarioView />} />
              {(menus?.find(element=>element==="7")?true:false) && <Route path='Formatos' element={<FormatoView />} />}
            </Route>
            
            {/*
                <Route path='/adminUser' element={<AdminUser/>}></Route>
            <Route path='/adminMembership' element={<AdminMembership/>}></Route>
            <Route path='/changePasswordStrep' element={<ChangepwStepZero/>}></Route>
            <Route path='/changePassword' element={<Changepw/>}></Route>
            <Route path='/Forbidden' element={<ForbbidenPage/>}></Route>
            <Route path='/send' element={<PHPR/>}></Route>*/
            }
            
          </Route>
          <Route
              path="*"
              element={
                <div>
                  <h2>404 Page not found</h2>
                </div>
              }
            />
        </Routes>
        </>
    )
}