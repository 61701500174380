import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";
import LongDescriptionModal from "./LongDescriptionModal";
import DriveFileRenameOutlineTwoToneIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';

export default function LongDescriptionButton (params){
    const { nombre, Label}=params
    const [openDescription, setOpenDescription]=useState(false)
    const [longDescription, setLongDescription]= useState()
    

    useEffect(()=>{
        setLongDescription(params.row[nombre])
        
    },[longDescription])
    
    const handleOpenDescription=(name)=>{
      setOpenDescription(true)
    }
  
    const handleClosePersona=(description)=>{
      if(description){
        params.row[nombre]=description
        setLongDescription(description)
      }
      setOpenDescription(false)
    }
  
    return (
      <React.Fragment>
        <div className="cellAction">
          <LongDescriptionModal openDescription={openDescription} handleCloseDescription={handleClosePersona} defaultText={longDescription} label={Label} />
          {params.row.edit?null:<IconButton aria-label="delete" size="large" onClick={()=>handleOpenDescription("field.name")}>
            <DriveFileRenameOutlineTwoToneIcon value={"field.name"} fontSize="inherit" titleAccess="Editar texto" />
          </IconButton>}
          {longDescription}
        </div>
      </React.Fragment>
    );
  } 

  export function LongDescriptionButtonForm (props){
    const { name, value, Label, handleChange}=props
    const [openDescription, setOpenDescription]=useState(false)
    const [longDescription, setLongDescription]= useState()
    

    useEffect(()=>{
        setLongDescription(value)
        
    },[longDescription])
    
    const handleOpenDescription=(name)=>{
      setOpenDescription(true)
    }
  
    const handleClosePersona=(description)=>{
      if(description){
        var e={target:{name:null, value:null}}
        e.target.name=name
        e.target.value=description
        handleChange(e)
        setLongDescription(description)
      }
      setOpenDescription(false)
    }
  
    return (
      <React.Fragment>
        <div className="cellAction" style={{display:'flex', width:'100%'}}>
          <LongDescriptionModal openDescription={openDescription} handleCloseDescription={handleClosePersona} defaultText={longDescription} label={Label} />
          {<IconButton aria-label="delete" size="large" onClick={()=>handleOpenDescription("field.name")}>
            <DriveFileRenameOutlineTwoToneIcon value={"field.name"} fontSize="inherit" titleAccess="Editar texto" />
          </IconButton>}
          <TextField
          value={longDescription || ""}
          label={Label}
          margin="normal"
          fullWidth
          autoComplete="off"
          >

          </TextField>
          {}
        </div>
      </React.Fragment>
    );
  } 