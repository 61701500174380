import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "./switch";
import Loader2 from "../layout/Loader2";
import DualContainedModal from "../layout/DualContainedModal";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import ReceotorPersonaSelector from "../layout/ReceptorPersonaSelector";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function AditionalModalSelectiorController (props){
    return(
        <React.Fragment>
            <DualContainedModal {...props}/>
        </React.Fragment>
    )
}

export default function PersonaDoubleSectionModal(props){
    const {openPersona, handleClosePais, setDataResponse, setCatchResponse, provitionalList, handleSelectPersona, handleRemovePersona} = props;
    const [loader, setLoader]=useState(false);

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={openPersona}
                onClose={handleClosePais}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: '90%', maxHeight:'90%', overflow: 'auto' }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Buscador</h2>
                    <AditionalModalSelectiorController 
                        ChildLeft={ModalPersonaSelector}
                        leftProps={{handleSelectPersona: handleSelectPersona, disabledExit:true}}
                        ChildRigth={ReceotorPersonaSelector}
                        rigthProps={{listaSeleccionadas:provitionalList, handleRemovePersona:handleRemovePersona, disabledExit:false}}
                        setDataResponse={setDataResponse} 
                        setCatchResponse={setCatchResponse}
                        setLoader={setLoader}
                        handleClose={handleClosePais}
                    />
                </Box>
                </>
                
            </Modal>
        </React.Fragment>
    )
}