import React from "react";
import { useState } from "react";
import TablaGestion from "../../components/shared/layout/Table";
import { Personas2 } from "../../structure/Tables";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';

export default function PersonasView(props){
    const {data, addRow, borrarRegistro, backOption,backFunction,openEdit, title}=props;

    const [sortModel, setSortModel] = React.useState([
        {
          field: 'nombrecompleto',
          sort: 'asc',
        },
      ]);

    


    const botonesAccion = [
        {
        field: "action",
        headerName: "",
        width: 50,
        sortable: false,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} onClick={async(e)=>{
                if(openEdit || typeof openEdit === "function"){openEdit(params.row, "persona")};
                }} >{<EditTwoToneIcon titleAccess="Editar" /> }
                </div>
            </div>
            );
        },
        },
    ];

    return(
        <React.Fragment>
            <TablaGestion 
                botones ={botonesAccion}
                data={data}
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {Personas2} 
                titulo={
                    <>
                        {backOption?<KeyboardBackspaceTwoToneIcon onClick={backFunction} sx={{cursor:"pointer"}}/>:null}
                        {title}
                    </>} 
            />
        </React.Fragment>
    )
}
