import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import { FieldsContainer } from "./FieldsContainer";

export function ArrOfJsonEditor(props){
    const {formData, keyG, setFormData, tipoDocumento, idreferencia, config}=props
    const [initialValues, setInitialValues]=useState([])
    const [arrCols, setArrCols]= useState([])
    const [aditionalInputs, setAditionalInputs]= useState({})

    useEffect(()=>{
        var listData=[]
        for(var key in config){
            listData.push(config[key])
        }
        let aditionalInputsAux={}
        if(listData.find(element=>element.key===keyG)?.label==='Académicos'){
            aditionalInputsAux= {tipoDocumento:tipoDocumento.filter(element=> element.name==="titulodoc_id"), idreferencia:idreferencia}
        }else
        if(listData.find(element=>element.key===keyG)?.label==='Otros'){
            aditionalInputsAux= {tipoDocumento:tipoDocumento.filter(element=> element.name==="otrosdoc_id"), idreferencia:idreferencia}
        }
        let fields= listData?.find(x=>x.key===keyG)?.fields
        if(fields){
            let extrafields= []
            for(var key in fields){
                extrafields.push({'label':fields[key], 'key':key})
            }
            if(extrafields.length>0){
                aditionalInputsAux.extrafields = extrafields
            }
        }
        setAditionalInputs(aditionalInputsAux)
        
        if(keyG){
            setInitialValues(formData[keyG] || [])
        }
    },[keyG])

    const handleChange=(index, value)=>{
        if(!value){

        }else{
            
        }
        var arrValues = initialValues
        arrValues[index]=value
        setInitialValues(arrValues)
        setFormData({...formData, [keyG]:arrValues})
    }

    const addValue = ()=>{
        setInitialValues(values => values.concat(''))
    }

    const remove =(index) =>{
        //nota: los array pasan como valores por referencia. Para actualizarlo es necesario pasarlos como valor y hacer el respectivo proceso en ambas variables.
        var arrValues= [...initialValues]
        initialValues.splice(index,1)
        arrValues.splice(index,1)
        setInitialValues(arrValues)
        //initialValues.splice(index,1)
        
        //setInitialValues(values=>values.splice(index,1));
    }

    return(
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12} sx={{scrollbarColor:"#249736",height:270,overflowY:"auto"}}>
            <FieldsContainer remove={remove} fields={initialValues} handleChange={handleChange} {...aditionalInputs}/>
            </Grid >
            <Grid item xs={12} md={12} lg={12}>
            {!keyG?null:<IconButton className="motionButton" sx={{borderRadius:0}} aria-label="delete" size="large" onClick={addValue}>
                <AddIcon color="primary" value={"field.name"} fontSize="inherit" /><div className="motionText"><h5>Agregar</h5></div>
            </IconButton>}
            </Grid>
            
        </React.Fragment>
    )
}