import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DocumentUpper } from "../Children/viewFIle";
import Loader2 from "../../shared/layout/Loader2";
import { AddFile } from "../Children/addFile";

export function AddFileModal(props){
    const {persona_id, tipoDocumento, open, setOpen, handleChange, handleClose, tituloDocumento}= props;
    const [loader, setLoader]=useState(false)
    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: '70%' }}>
                    <h2 id="parent-modal-title">Seleccionar {tituloDocumento || 'Documento'}</h2>
                    <Loader2 onLoader={loader}/>
                    <AditionalModalSelectiorController 
                        setLoader={setLoader}
                        {...props}
                    />
                </Box>
            </Modal>
        </React.Fragment>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function AditionalModalSelectiorController (props){

    return(
        <React.Fragment>
            <AddFile {...props}/>
        </React.Fragment>
    )
}
