import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import Loader2 from "../layout/Loader2";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function AditionalModalSelectiorController (props){
    return(
        <React.Fragment>
            <ModalPersonaSelector {...props}/>
        </React.Fragment>
    )
}

export default function SelectPersonaModal(props){
    const {openPersona, handleClosePais, setDataResponse, setCatchResponse} = props;
    const [loader, setLoader]=useState(false);

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={openPersona}
                onClose={handleClosePais}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 600 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Buscador</h2>
                    <AditionalModalSelectiorController 
                        setDataResponse={setDataResponse} 
                        setCatchResponse={setCatchResponse}
                        setLoader={setLoader}
                        handleClose={handleClosePais}
                    />
                </Box>
                </>
                
            </Modal>
        </React.Fragment>
    )
}