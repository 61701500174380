import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";
import SelectPersonaModal from "./PersonaModal";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SetterFeatureModal from "./featureModal";

/**
 * Componente para edición y de valores json del tipo llave-array {key: [val1, val2, ...]}
 * Los datos principales es el array, la configuración general compuesta de las siguientes llaves: (json_controller, [names]keys)
 * Un handleChange sencillo de e.target.value y e.target.name.
 * El nombre de la llave en el parámetro 'name'
 * @param {jsonData, config , name, handleChange, title, setDataResponse, setCatchResponse} props 
 * @returns 
 */

export function FeatureButtonforForms (props){
  const {jsonData, config , name, handleChange, tipoDocumento, idreferencia}= props
  const [sampleJson, setSampleJson]=useState({})
  const [reqConfig, setReqConfig] = useState({})
  const [resumeJson, setResumeJson]=useState({})

  const { title, setDataResponse, setCatchResponse}=props
  const [open, setOpen]=useState(false)
  const [selectedPerson, setSelectedPerson]=useState(0)
  const [namePerson, setNamePerson]= useState('')

  useEffect(()=>{
    var dataM= {...jsonData}
    
    for (var key in jsonData){
      var arrnew=[...jsonData[key]]
      dataM[key]=arrnew
    }

    setSampleJson(dataM)
    var expKeys=config.find((element)=>element.nombre==="json_controller")?.configuracion
    if(expKeys){
      var newConf = expKeys[name]
      if(newConf){
        var layouts= config.find((element)=>element.nombre===newConf).configuracion
        setReqConfig(layouts)
      }
    }

  },[jsonData, config, name, open])
    
  const handleOpen=()=>{
    setOpen(true)
  }
  
  const handleClose=(valid)=>{

    if(valid){
      var e={target:{value: sampleJson, name: name}}
      handleChange(e)
    }
    setOpen(false)
  }
  
  return (
    <React.Fragment>
      <div className="cellAction" style={{display:'flex', width:'100%'}} >
        <SetterFeatureModal 
          tipoDocumento={tipoDocumento} 
          idreferencia={idreferencia}
          title={title}
          datajson={sampleJson} 
          controllerDatajson={reqConfig} 
          setDataJson={setSampleJson} 
          setDataResponse={setDataResponse} 
          setCatchResponse={setCatchResponse} 
          open={open} 
          handleClose={handleClose}
        ></SetterFeatureModal>
        {<IconButton aria-label="delete" size="large" onClick={handleOpen}>
          <EditTwoToneIcon value={"field.name"} fontSize="inherit" />
        </IconButton>}
        <TextField
          multiline
          variant="standard"
          maxRows={1}
          margin="normal"
          fullWidth
          value={title}
        />
          
      </div>
    </React.Fragment>  
    );
  } 



export function FeatureButton (props){
  const {jsonData, config , name, params}= props
  const [sampleJson, setSampleJson]=useState({})
  const [reqConfig, setReqConfig] = useState({})
  const [resumeJson, setResumeJson]=useState({})

  const { title, setDataResponse, setCatchResponse}=props
  const [open, setOpen]=useState(false)
  const [selectedPerson, setSelectedPerson]=useState(0)
  const [namePerson, setNamePerson]= useState('')

  useEffect(()=>{
    var dataM= {...jsonData}
    
    for (var key in jsonData){
      var arrnew=[...jsonData[key]]
      dataM[key]=arrnew
    }

    setSampleJson(dataM)
    var expKeys=config.find((element)=>element.nombre==="json_controller")?.configuracion
    if(expKeys){
      var newConf = expKeys[name]
      if(newConf){
        var layouts= config.find((element)=>element.nombre===newConf).configuracion
        setReqConfig(layouts)
      }
    }

  },[jsonData, config, name, open])
    
  const handleOpen=()=>{
    setOpen(true)
  }
  
  const handleClose=(valid)=>{

    if(valid){
      params.row[name]=sampleJson
    }
    setOpen(false)
  }
  
  return (
    <React.Fragment>
      <div className="cellAction" style={{display:'flex', width:'100%'}} >
        <SetterFeatureModal 
          title={title}
          datajson={sampleJson} 
          controllerDatajson={reqConfig} 
          setDataJson={setSampleJson} 
          setDataResponse={setDataResponse} 
          setCatchResponse={setCatchResponse} 
          open={open} 
          handleClose={handleClose}
        ></SetterFeatureModal>
        {params.row.edit?null:<IconButton aria-label="delete" size="large" onClick={handleOpen}>
          <EditTwoToneIcon value={"field.name"} fontSize="inherit" />
        </IconButton>}
        <TextField
          multiline
          variant="standard"
          maxRows={1}
          margin="normal"
          fullWidth
          value={title}
        />
          
      </div>
    </React.Fragment>  
    );
  } 