import { diccionarioInterprete, diccionarioSelect, diccionarioField, diccionarioFieldToDiccionarioLabel, formatoPerfilReligioso } from "./dicionarioInterprete";

const isFunction = functionToCheck => {
  return functionToCheck && ({}.toString.call(functionToCheck) === '[object Function]' || {}.toString.call(functionToCheck) === '[object AsyncFunction]');
}

const currentDate = () => {
  const today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  const year = today.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return year + '-' + month + '-' + day;
}

const tomorroDays = days => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + days);
  return tomorrow;
}

const specialCharactersIN = (replaceCharacter, joinCharacter, params) => {
  return params.map(value => {
    return value.toString().replace(value, replaceCharacter);
  }).join(joinCharacter);
}

const isNumeric = num => {
  return !isNaN(num)
}

const addDays = (fecha, dias) => {
  fecha.setDate(fecha.getDate() + dias + 1 );
  let day = fecha.getDate();
  let month = fecha.getMonth() + 1;
  const year = fecha.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return year + '-' + month + '-' + day;
}

const Remainingdays = (fechaIni, fechaFin) => {
  var diaEnMils = 1000 * 60 * 60 * 24,
    desde = new Date(fechaIni.substr(0, 10)),
    hasta = new Date(fechaFin.substr(0, 10)),
    diff = hasta.getTime() - desde.getTime() + diaEnMils;// +1 incluir el dia de ini
  return diff / diaEnMils;
}

const validateOlderDate = (fechadesde,fechahasta) =>{
  let fecha1 = new Date(fechadesde);
  let fecha2 = new Date(fechahasta);
  if (fecha1.getTime() < fecha2.getTime()){
    return true;
  }else if (fecha1.getTime() > fecha2.getTime()){
    return false;
  }else{
    return true;
  }
}

const decodeJWTFechaexp = (cadena) => {
  var base64Url = cadena.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function formatSecuencia(number, width) {
  if(number === null)
    return "";
  var numberOutput = Math.abs(number);
  var length = number.toString().length;
  var zero = "0";

  if (width <= length) {
    if (number < 0) {
      return ("-" + numberOutput.toString());
    } else {
      return numberOutput.toString();
    }
  } else {
    if (number < 0) {
      return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
    } else {
      return ((zero.repeat(width - length)) + numberOutput.toString());
    }
  }
}

const validationRuc = (ruc) => {
  if (ruc.length === 10) {
    return validationCedula(ruc);
  } else if (ruc.length === 13) {
    if (ruc.substring(10, 13) != '001')
      return 2;
    else {
      const lv_cedula = ruc.substring(0, 10);
      if (parseInt(lv_cedula) == 0 || ruc.length != 13)
        return 2;
      else {
        const lv_vec3 = parseInt(ruc.substring(2, 3));
        if (lv_vec3 >= 0 && lv_vec3 <= 5) {
          const ll_rc = validationCedula(lv_cedula);
          return ll_rc == 0 ? ll_rc : 2;
        } else if (lv_vec3 == 6) {
          let ll_rc = spu_6(lv_cedula);
          if (ll_rc == 2) {
            ll_rc = validationCedula(lv_cedula);
            return ll_rc == 0 ? ll_rc : 2;
          }else{
            return 0;
          }
        } else if (lv_vec3 == 9)
          return spu_9(lv_cedula)
      }
    }
  } else {
    return 3
  }
}

const spu_9 = (lv_ruc_sei) => {
  const ll_uno = parseInt(lv_ruc_sei.substring(0, 1));
  const ll_dos = parseInt(lv_ruc_sei.substring(1, 2));
  const ll_tre = parseInt(lv_ruc_sei.substring(2, 3));
  const ll_cua = parseInt(lv_ruc_sei.substring(3, 4));
  const ll_cin = parseInt(lv_ruc_sei.substring(4, 5));
  const ll_sei = parseInt(lv_ruc_sei.substring(5, 6));
  const ll_sie = parseInt(lv_ruc_sei.substring(6, 7));
  const ll_och = parseInt(lv_ruc_sei.substring(7, 8));
  const ll_nue = parseInt(lv_ruc_sei.substring(8, 9));
  const ll_die = parseInt(lv_ruc_sei.substring(9, 10));

  let ll_sum = ll_uno * 4 + ll_dos * 3 + ll_tre * 2 + ll_cua * 7 + ll_cin * 6 + ll_sei * 5 + ll_sie * 4 + ll_och * 3 + ll_nue * 2;
  while (ll_sum > 11) {
    ll_sum -= 11;
  }
  const lv_dverr2 = 11 - ll_sum;
  if (lv_dverr2 == 10)
    return 2;
  else if (lv_dverr2 != ll_die)
    return 2;
  else
    return 0;
}

const spu_6 = (lv_ruc_sei) => {
  const ll_uno = parseInt(lv_ruc_sei.substring(0, 1));
  const ll_dos = parseInt(lv_ruc_sei.substring(1, 2));
  const ll_tre = parseInt(lv_ruc_sei.substring(2, 3));
  const ll_cua = parseInt(lv_ruc_sei.substring(3, 4));
  const ll_cin = parseInt(lv_ruc_sei.substring(4, 5));
  const ll_sei = parseInt(lv_ruc_sei.substring(5, 6));
  const ll_sie = parseInt(lv_ruc_sei.substring(6, 7));
  const ll_och = parseInt(lv_ruc_sei.substring(7, 8));
  const ll_nue = parseInt(lv_ruc_sei.substring(8, 9));

  let ll_sum = ll_uno * 3 + ll_dos * 2 + ll_tre * 7 + ll_cua * 6 + ll_cin * 5 + ll_sei * 4 + ll_sie * 3 + ll_och * 2;
  while (ll_sum > 11) {
    ll_sum -= 11;
  }
  const lv_dverr2 = 11 - ll_sum;
  if (lv_dverr2 == 10)
    return 2;
  else if (lv_dverr2 != ll_nue)
    return 2;
  else
    return 0;
}

const validationCedula = (cedula) => {
  let lv_prov = parseInt(cedula.substring(0, 2));
  if ((lv_prov >= 1 && lv_prov <= 24) || lv_prov == 30) {
    let ll_tendigit = parseInt(cedula.substring(9, 10));
    let ll_sum = 0, ll_cnt = 0, ll_cntpos = 0;
    while (ll_cntpos < 9) {
      ll_cntpos = 2 * ll_cnt + 1;
      const lv_strnum = cedula.substring(ll_cntpos - 1, ll_cntpos);
      let ll_multi = parseInt(lv_strnum) * 2;
      if (ll_multi >= 10) {
        ll_multi = 1 + (ll_multi % 10);
      }
      ll_sum += ll_multi;
      ll_cnt++;
    }
    ll_cnt = 0;
    ll_cntpos = 1;
    while (ll_cntpos < 8) {
      ll_cnt++;
      ll_cntpos = 2 * ll_cnt;
      const lv_strnum = cedula.substring(ll_cntpos - 1, ll_cntpos);
      ll_sum += parseInt(lv_strnum);
    }

    const ll_decena = (parseInt(ll_sum / 10) + 1) * 10;
    let ll_verific = ll_decena - ll_sum;
    if (ll_verific == 10)
      ll_verific = 0;

    if (ll_verific == ll_tendigit)
      return 0
    else
      return 1
  } else
    return 1

}

const generarForm=(datos)=>{
  var formulario=[];
  for (var key in datos){
    try{
      var label= diccionarioInterprete[key];
      if(label){
        formulario.push({
          label: label,
          name: key,
          value: datos[key] || ''
        })
      }else{
        formulario.push({
          //label: label,
          name: key,
          value: datos[key] || false
        })
      }
    }catch(err){}
    
  }
  return formulario
}

const generarFormCompleto=(datos)=>{
  var formulario={};
  for (var key in datos){
    var tipoField= diccionarioField[key];
    if(tipoField){
      var dicData= diccionarioFieldToDiccionarioLabel[tipoField]
    
      if(formulario[tipoField]== undefined){
        formulario[tipoField]=[]
      }
      var data= dicData[key]
      
      formulario[tipoField].push(
        {
          ...data,
          name:key,
          value: datos[key]
        }
      )
    }
    
    
  }
  if( formulario.paridad){
    formulario.paridad.forEach(paridad => {
      formulario.selectfield?.forEach(selectfield => {
        if(paridad.ref===selectfield.name){
          
          selectfield.paridad=paridad
        }
      });
    });
  }
  delete formulario.paridad;
  return formulario
}

const generarFormPerfilReligioso=(datos)=>{
  var diccionarioPerfilReligioso= formatoPerfilReligioso
  var formulario={};
  var formularioFInal=[];
  for (var key in datos){
    var subformat= diccionarioPerfilReligioso[key]
    if(subformat){
      for(var subkey in subformat){
        if(subkey!=='label')
        subformat[subkey].value=datos[subkey]
      }
      var formularioAux= {
        refActivacion: key,
        fields:{...subformat,[key]: datos[key]}
      }
      formularioFInal.push(formularioAux)
    }
  }

  return formularioFInal
}

const selectForm=(datos)=>{
  var formulario=[];
  for(var key in datos){
    var fields= diccionarioSelect[key];
    fields.forEach(element => {
      var label= diccionarioInterprete[element];
      if(label){
        formulario.push({
          label: label,
          name: element,
          value: ""
          
        })
      }
    });
  }
}

const generarSelector=(datos, labelKey, valueKey )=>{
  var opciones=[];
  datos.forEach(dato => {
    opciones.push({
      label: dato[labelKey],
      value: dato[valueKey]
    })
  });
  return opciones;
}

const debugBase64=(base64URL)=>{
  var win = window.open();
  win.document.write('<iframe src="' + base64URL  + '" frameborder="0px" style="border:0px; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  win.document.body.style='margin:0;'
}

const debugHtml=async (htmlurl)=>{
  var win = window.open();
  win.document.write(htmlurl);
  win.document.close();
  await sleep(1000)
  win.focus();
  win.print();
  win.close();
}

export {
  isFunction,
  currentDate,
  tomorroDays,
  specialCharactersIN,
  isNumeric,
  addDays,
  Remainingdays,
  validateOlderDate,
  decodeJWTFechaexp,
  sleep,
  formatSecuencia,
  validationRuc,
  generarForm,
  generarSelector,
  selectForm,
  generarFormCompleto,
  generarFormPerfilReligioso,
  debugBase64,
  debugHtml
}
