import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
import FeatureBase from "../layout/FeatureBase";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function ModalFeatureJsonGestor (props){
    const {handleClose}=props
    return(
        <React.Fragment>
            <Box>
                <FeatureBase
                    {...props}
                ></FeatureBase>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose(false)}>Cancelar</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose(true)}>Aceptar</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default function SetterFeatureModal(props){
    const {datajson, controllerDatajson, setDataJson, open, title, handleClose, tipoDocumento, idreferencia} = props;
    const [loader, setLoader]=useState(false);

    
    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 600 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title" style={{width:250}}>Describa {title}</h2>
                    <ModalFeatureJsonGestor 
                        tipoDocumento={tipoDocumento} 
                        idreferencia={idreferencia}
                        handleClose={handleClose}
                        controllerDatajson={controllerDatajson}
                        setDataJson={setDataJson}
                        datajson={datajson}
                    />
                </Box>
                </>
                
            </Modal>
        </React.Fragment>
    )
}