import React from 'react';

export default function Loader2(props) {
  const {onLoader}=props;
  return (
    <>
      {onLoader && (
        <div className='backloader2'>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        )
      }
    </>
  );
}