import React from "react";
import { useState } from "react";
import TablaGestion from "../../components/shared/layout/Table";
import { RegistroMision } from "../../structure/Tables";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Button } from "@mui/material";


export default function RegistroMisionView(props){
    const {data, addRow, handleEditar, guardarRegistro, actionPrint}=props;
    const [listaRegistro,setListaRegistros]=useState([])

    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
      ]);

    const printButton=actionPrint? <Button
            key={"printRM"}
            id={"boton1"}
            onClick={actionPrint}
            sx={{
                "&:hover":{
                    //background:"#d0d0d0",
                    //borderBottom: "1px solid #d0d0d0"
                },
                width:5,
                padding:0,
            }}
        >
        <LocalPrintshopTwoToneIcon titleAccess="Imprimir Reporte"></LocalPrintshopTwoToneIcon>
    </Button> :null
    

    const botonesAccion = [
        {
        field: "action",
        headerName: <div  style={{marginBottom:"-10px"}}><AddTwoToneIcon titleAccess="Agregar" onClick={(e)=>addRow()}/></div>,
        width: 50,
        sortable: false,
        
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} onClick={(e)=>{
                if(!params.row.edit){guardarRegistro(params.row)}; params.row.edit= !params.row.edit
                }} >{params.row.edit?<EditTwoToneIcon titleAccess="Editar" />: <SaveTwoToneIcon titleAccess="Guardar"/> }
                </div>
            </div>
            );
        },
        },
    ];

    return(
        <React.Fragment>
            <TablaGestion 
                botones ={botonesAccion}
                data={data} 
                aditionalButton={printButton}
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {RegistroMision} 
                titulo={"Registro de misiones realizadas."} 
            />
        </React.Fragment>
    )
}
