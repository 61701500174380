import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "./ModalCiudadSelector";
import SelectCityModal from "../Litles/cityModal";
import { PersonaButtonforForms } from "../Litles/PersonaButton";
import {InputLabel, FormControl} from "@mui/material";
import { DatePickerCustomed } from "../Litles/dates";
import { LongDescriptionButtonForm } from "../Litles/LongDescriptionButton";
import { FeatureButtonforForms } from "../Litles/featureButton";
import { PersonadOoubleSectionButtonforForms } from "../Litles/PersonaDoubleSectionButton";
import { ButtonFileUpperForms } from "../../FIleUpper/Button/FIleUpperButton";


function AditionalModalSelectiorController (props){

    return(
        <React.Fragment>
            <ModalCiudadSelector {...props}/>
        </React.Fragment>
    )
}

export default function FormsEdit(props){
    const {title,textFields, ModalText, ModalFeatures, specialFieldsPR, idreferencia, tipoDocumento, ModalFeaturesArray, selectFields,setDataResponse, setCatchResponse, ModalPersonaSearch, ModalPersonasSearch, specialRules, toggleSwitchFields, DateFields, ModalSearchFields, handleChange, SubmitHandle, formData,selectListOptions, listPaises, listEstados, listCiudades, fieldsDocsForArraysJson, aditionalButton} = props;
    const [openPais, setOpenPais]=useState(false)
    const [ciudad_name, setCiudad_Name]=useState("")
    const [data,setData]=useState({})

    const getList=(parent, id)=>{
        
    }
    
    const handleOpenPais=(name)=>{
        setCiudad_Name(name)
        setOpenPais(true)
    }
    const handleClosePais=(ciudad)=>{
        if(ciudad){
            var e={}
            e.target={}
            e.target.name=ciudad_name;
            e.target.value=ciudad
            handleChange(e)
        }
        
        setOpenPais(false)
    }

    const handleClosePersona=(id, name, nombrecompleto, label_name)=>{
        if(id){
            var e={}
            e.target={}
            e.target.name=name;
            e.target.value=id
            e.extra={}
            e.extra.name=label_name;
            e.extra.value=nombrecompleto;

            handleChange(e)
        }
    }

    const HandleSwitchChange=(value, name)=>{
        var e={}
        e.target={}
        e.target.name=name;
        e.target.value=value
        handleChange(e)
    }

    useEffect(()=>{
        var newdata= {...data,
        ...formData}
        
        setData(newdata)
    },[formData])
    
    return(
        <React.Fragment>
            {!listPaises?null:
            <SelectCityModal openPais={openPais} handleClosePais={handleClosePais} listPaises={listPaises} listEstados={listEstados} listCiudades={listCiudades}></SelectCityModal>
            }
            <Box component="form" noValidate onSubmit={SubmitHandle} width={'100%'} sx={{ mt: 1 }}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <Title>Detalles de {title||'perfil'}</Title>
                <div style={{display:'flex'}}>
                    {aditionalButton}
                </div>
                </div>
            </Grid>
            </Grid>
            
            
            <Grid container spacing={3}>

              {textFields?.length>0 && textFields.map((field, index)=>(
                <Grid key={"textFields"+index} item xs={12} md={6} lg={6}>
                <TextField
                    disabled={title==='usuario' && (field.name==='numeroidentificacion' || field.name==='nombrecompleto' || field.name==='correopersonal')}
                    key={"TextField_textFields"+index}
                    margin="normal"
                    value={formData[field.name] || ""}
                    fullWidth
                    id={field.label}
                    label={field.label}
                    type={field.type || 'text'}
                    InputProps={ field.type==='number'? { inputProps: { min: 0} }:{}}
                    onChange={handleChange || null}
                    name={field.name}
                    autoComplete="off"
                    autoFocus
                ></TextField>
              </Grid>
              )) 
              }

              {ModalText?.length>0 && ModalText?.map((field, index)=>(
                <Grid key={"ModalText_"+index} item xs={12} md={6} lg={6} display={"flex"}>
                    <LongDescriptionButtonForm name={field.name} value={formData[field.name]} Label={field.label} handleChange={handleChange}/>
                </Grid>
              ))}

              {ModalPersonaSearch?.length>0 && ModalPersonaSearch.map((field, index)=>(
                <Grid key={"ModalPersonaSearch_"+index} item xs={12} md={6} lg={6} display={"flex"}>
                    <PersonaButtonforForms setDataResponse={setDataResponse} setCatchResponse={setCatchResponse} value={formData[field.name]} value_name={field.name} Label={formData[field.fromtext]} title={field.label} label_name={field.fromtext} handleChange={handleClosePersona}/>
                </Grid>
              ))}
                
              { /** validación para número de familiares. */
                ModalPersonasSearch?.length>0 && ModalPersonasSearch.map((field, index)=>(
                    <Grid key={"PersonadOoubleSectionButtonforForms"} item xs={12} md={6} lg={6} display={"flex"}>
                        <PersonadOoubleSectionButtonforForms setDataResponse={setDataResponse} setCatchResponse={setCatchResponse} value={formData[ field.name ||"personas_emergencia"]} value_name={field.name|| "personas_emergencia"} title={field.label} handleChange={handleClosePersona}/>
                    </Grid>
                ))
                
              }

              {ModalSearchFields?.length>0 && ModalSearchFields.map((field, index)=>(
                <Grid key={"ModalSearchFields_"+index} item xs={12} md={6} lg={6} display={"flex"}>
                    
                        <IconButton key={"IconButton_ModalSearchFields_"+index} aria-label="delete" size="large" onClick={()=>handleOpenPais(field.name)}>
                            <TravelExploreTwoToneIcon key={"TravelExploreTwoToneIcon_ModalSearchFields_"+index} value={"field.name"} fontSize="inherit" />
                        </IconButton>
                    
                        <TextField
                            key={"TextField_ModalSearchFields_"+index}
                            margin="normal"
                            value={selectListOptions[field.list]?.find((elemento)=>elemento?.id===formData[field.name])?.nombre || ""}
                            fullWidth
                            id={field.label}
                            label={field.label}
                            onChange={handleChange || null}
                            name={field.name}
                            autoComplete="off"
                        ></TextField>
                </Grid>
              ))}

            {selectFields?.length>0 && selectFields.map((field, index)=>(
                <Grid key={"selectFields_"+index} item xs={12} md={6} lg={6} sx={{display:"flex", alignItems:"center"}}>
                    <FormControl
                    key={"FormControl_selectFields_"+index}
                    variant="outlined"
                    style={{ width: "100%", marginBottom: 32 }}
                    >
                        <InputLabel key={"InputLabel_selectFields_"+index} id={field.label}>{field.label}</InputLabel>
                        <Select
                            key={"Select_selectFields_"+index}
                            value={formData[field.name] || ""}
                            labelId={field.label}
                            fullWidth
                            id={field.label}
                            label={field.label}
                            onChange={handleChange || null}
                            name={field.name}
                        >
                        {selectListOptions[field?.paridad?.list]?.filter((element)=>element[field?.padrefield]===field?.padre)?.map((option, index)=>(
                            <MenuItem key={"selectListOptions_"+index} value={option.id}>{option.descripcion}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    
                </Grid>
            ))
            }

            {ModalFeatures?.length>0 && ModalFeatures.map((field, index)=>(
                <Grid key={"ModalFeatures_"+index} item xs={12} md={6} lg={6} sx={{display:"flex", alignItems:"center"}}>
                    <FeatureButtonforForms 
                        tipoDocumento={fieldsDocsForArraysJson}
                        idreferencia={idreferencia}
                        config={specialRules}
                        name={field.name}
                        handleChange={handleChange || null}
                        title = {field.label}
                        jsonData={formData[field.name] || {}}
                        setDataResponse
                        setCatchResponse
                    />
                </Grid>
            ))}

            {toggleSwitchFields?.length>0 && toggleSwitchFields.map((field, index)=>(
                <Grid key={"toggleSwitchFields_"+index} item xs={12} md={3} lg={2} sx={{display:"flex", alignItems:"center"}}>
                    <IOSSwitch 
                        checked={formData[field.name]} 
                        onChange={(e)=>{HandleSwitchChange(e.target.checked, e.target.name);}}
                        name= {field.name}
                        label={field.name}
                    />
                    <h3>{field.label}</h3>
                </Grid>
            ))
            }
            {(idreferencia?true:false) && 
                tipoDocumento?.map((element, index)=>(
                    <Grid key={'upperDoc_'+index} item xs={12} sm={6} md={6} lg={6} sx={{display:"flex", alignItems:"center"}}>
                        <ButtonFileUpperForms handleChangeReserv={handleChange} idrefDoc={formData[element.name]} nombredocumento={formData[element.fromText]} name={element.name} persona_id={idreferencia} tipoDocumento={element?.id} title={element?.title}/>
                    </Grid>
                ))
            }
            {DateFields?.length>0 && DateFields.map((field,index)=>(
                <Grid key={"DateFields_"+index} item xs={12} sm={6} md={6} lg={6} sx={{display:"flex", alignItems:"center"}}>
                    <DatePickerCustomed 
                        value={formData[field.name] || ''} 
                        name={field.name}
                        label={field.label}
                        onChange={(e)=>{
                            var newdata={target:{value:null, name:null}}
                            newdata.target.value=e;
                            newdata.target.name=field.name;
                            handleChange(newdata);}}
                    />
                </Grid>
            ))}
            {specialFieldsPR?.length>0 &&
                <Grid key={'sfpr_index'} item xs={12} md={12} lg={12}>
                    <hr/>
                    <Title>Etapas de formación</Title>
                </Grid>

            }
            {specialFieldsPR?.length>0 && specialFieldsPR.map(((fieldsGroup, index)=>{
                var keys=[]
                for(var key in fieldsGroup.fields){
                    keys.push(key)
                }
                return (
                    <Grid item xs={12} md={12} lg={12}>
                    <Grid container sx={{border: '1px solid #d0d0d0', borderRadius: 2, padding: 2}}>
                        <Grid key={"GroupFieldPR"+index} item xs={12} md={12} lg={12} sx={{display:"flex", alignItems:"center"}}>
                            <IOSSwitch 
                            checked={formData[fieldsGroup.refActivacion]} 
                            onChange={(e)=>{HandleSwitchChange(e.target.checked, e.target.name);}}
                            name= {fieldsGroup.refActivacion}
                            label={fieldsGroup.refActivacion}
                            />
                            <h3>{fieldsGroup.fields.label}</h3>
                        </Grid>
                        &nbsp;
                        {formData[fieldsGroup.refActivacion] && <Grid container padding={2} xs={12} md={12} lg={12}>
                            {/**ciudad */}
                            {keys.map((key, index_k)=>{
                                if(fieldsGroup.fields[key]?.type=== "modalSearch")
                                return(
                                    <Grid item xs={12} sm={6} md={6} lg={4} sx={{display:"flex", alignItems:"center"}}>
                                    <IconButton key={"PR_IconButton_ModalSearchFields_"+index_k} aria-label="delete" size="large" onClick={()=>handleOpenPais(key)}>
                                        <TravelExploreTwoToneIcon key={"TravelExploreTwoToneIcon_ModalSearchFields_"+index_k} value={key} fontSize="inherit" />
                                    </IconButton>
                                
                                    <TextField
                                        key={"TextField_ModalSearchFields_pr_"+index_k}
                                        margin="normal"
                                        //value={fieldsGroup.fields[key].value}
                                        value={selectListOptions["listCiudades"]?.find((elemento)=>elemento?.id===formData[key])?.nombre || ""}
                                        //value={formData[key]}   
                                        fullWidth
                                        id={key}
                                        label={"Ciudad"}
                                        onChange={handleChange || null}
                                        name={key}
                                        autoComplete="off"
                                    ></TextField>
                                    </Grid>
                                )
                                if(fieldsGroup.fields[key]?.type=== "DateTimeField")
                                return (
                                    <Grid item xs={12} sm={6} md={12} lg={4} sx={{display:"flex", alignItems:"center"}}>
                                        <DatePickerCustomed 
                                            value={formData[key] || ''} 
                                            name={key}
                                            label={"Fecha"}
                                            onChange={(e)=>{
                                                var newdata={target:{value:null, name:null}}
                                                newdata.target.value=e;
                                                newdata.target.name=key;
                                                handleChange(newdata);}}
                                        />
                                    </Grid>
                                )
                                if(fieldsGroup.fields[key]?.type=== "modalPersonaSearch")
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={4} sx={{display:"flex", alignItems:"center"}}>
                                        <PersonaButtonforForms 
                                            setDataResponse={setDataResponse} 
                                            setCatchResponse={setCatchResponse} 
                                            value={formData[key]} 
                                            value_name={key} 
                                            Label={formData[fieldsGroup.fields[key].fromText]} 
                                            label_name={fieldsGroup.fields[key].fromText} 
                                            title={"Maestra"} 
                                            handleChange={handleClosePersona}
                                        />
                                        
                                    </Grid>
                                )
                            })}
                            
                        </Grid>}
                    </Grid>
                    </Grid>
                )
            }))}

            </Grid>
            
            {(textFields?.length>0 || ModalFeatures?.length>0) && <Button 
              key={"botonFrom283"}
              variant="outlined"
              type="submit"
              id={"boton1"}
              name={"submitPersona"}
              value={null}
              //onClick={boton.action || null}
              >Actualizar
            </Button>}
            </Box>

        </React.Fragment>
    )

}