import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import instance from "../../../utilities/Instance";
import BookmarkAddedTwoToneIcon from '@mui/icons-material/BookmarkAddedTwoTone';
import { DetalleCatalogo } from "../../../structure/Tables";
import TablaGestion from "./Table";
import { isNumeric } from "../../../utilities/Utilities";
import ModalsubDetalleCatalogoSelector from "./ModalsubDesatalleCatalogoSelector";
import Loader2 from "./Loader2";
import { AlertCodeInterpreter_Catch } from "../../alerts";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function ModalDetalleCatalogoSelector (props){
    const { handleClose, setLoader, parent, actualizar} = props
    const [contadorSecuencias, setContador] =useState(0)
    const [searchfield, setSearchField]=useState('')
    const [listPersonas, setListPersonas]=useState([])
    const [opensub, setOpensub]=useState(false)
    const [loader, setLoader2]=useState(false)
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
    ]);


    const handleClosesub =()=>{
        setOpensub(false);
        getData(null)
    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        nombrecompleto: row?.nombrecompleto ||"",
        descripcion: row?.descripcion ||"",
        numeroidentificacion: row?.numeroidentificacion ||"",
        correopersonal: row?.correopersonal ||"",
        }
        return insertEl
    }

    const searchBy=(e)=>{
        var cuerpo
        if(e.key==='Enter'){
            if(!searchfield){
                getData()
                return
            }else{
                getDataSearch(searchfield)

            }
        }
    }

    const handleChange = (e)=>{
        setSearchField(e.target.value)
    }

    const getDataSearch=async (cuerpo)=>{
        setLoader(true)

        await instance.get("detallecatalogo/child/"+parent)
        .then(async(response)=>{
            var para=[]
            if( response.data.data.length>0){
              var registros= response.data.data;
              var newItem= contadorSecuencias
              registros.forEach(async (element) => {
                if((element.descripcion||'').toUpperCase().includes((cuerpo.toUpperCase() || '').toUpperCase())){
                    newItem= newItem+1
                    para.push(await createRow(element, newItem))
                }
                
              });
            setContador(newItem)
              setListPersonas(para)
            }
          })
          .catch((error)=>{
            if(error?.response?.data?.error){
                AlertCodeInterpreter_Catch(error.response.data.error)
              }
            console.error(error)
          })

        


        
        setLoader(false)
    }

    const getData= async(e)=>{
        setLoader(true)
        await instance.get("detallecatalogo/child/"+parent)
        .then(async(response)=>{
            var para=[]
            if( response.data.data.length>0){
              var registros= response.data.data;
              var newItem= contadorSecuencias
              registros.forEach(async (element) => {
                newItem= newItem+1
                para.push(await createRow(element, newItem))
              });
              setContador(newItem)
              setListPersonas(para)
            }
          })
          .catch((error)=>{
            if(error?.response?.data?.error){
                AlertCodeInterpreter_Catch(error.response.data.error)
              }
            console.error(error)
          })
          setLoader(false)
    }

    const SeleccionarPersona=(row)=>{
        var nombre= row.descripcion
        var id= row.idreal
        handleClose(nombre,id)
    }


    useEffect(()=>{
        getData()
    },[])

    const botonesAccion = [
        {
        field: "action",
        headerName: "Agregar",
        width: 100,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    !params.row.isDisable? SeleccionarPersona(params.row):e.preventDefault()
                }} ><BookmarkAddedTwoToneIcon titleAccess="Seleccionar"/>
                </div>
            </div>
            );
        },
        },
    ];

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={opensub}
                onClose={handleClosesub}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 600 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Nuevo</h2>
                    <ModalsubDetalleCatalogoSelector 
                        parent={parent}
                        actualizar={actualizar}
                        setLoader={setLoader2}
                        handleClose={handleClosesub}
                    />
                </Box>
                </>
                
            </Modal>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                    fullWidth
                        value={searchfield}
                        label={"Escriba algo y pulse 'ENTER' para buscar"}
                        onChange={handleChange}
                        onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
            </Grid>
                
            <TablaGestion 
                botones ={botonesAccion}
                data={listPersonas} 
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {DetalleCatalogo} 
                titulo={"Lista resultante."} 
            />
                <Grid container spacing={3}>
                    
                    <Grid item xs={12} md={6} lg={6}>
                        <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Button onClick={()=>setOpensub(true)}>Agregar</Button>
                    </Grid>
                </Grid>
                
        </React.Fragment>
    )
}