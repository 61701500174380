import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { isNumeric } from '../../../utilities/Utilities';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  export default function ResponseCustomizedSnackbars(props) {
    const {dataresponse, validator}=props
    const [typeToast,setTypeToast]=React.useState("success")
    const [preventMessage, setPreventMessage]=React.useState(null)
    const [open, setOpen]= React.useState(false)
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
      setPreventMessage("")
    };

    React.useEffect(()=>{
      if(dataresponse){
        if(isNumeric(dataresponse)){
            if(Number(dataresponse)>0){
                setTypeToast("success")
                setPreventMessage("Proceso realizado con éxito!")
            }else{
                setTypeToast("error")
                setPreventMessage("Ha ocurrido un error")
            }
            setOpen(true)
            return
        }else{
          setPreventMessage(dataresponse?.content ||"Proceso realizado con éxito")
          if(dataresponse?.codecase===7){
            setTypeToast("success")
          }else{
            setTypeToast("error")
          }
          setOpen(true)
        }
      }
      
    },[validator])
  
    return (
      <Stack spacing={2} >
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={typeToast} sx={{ width: '100%' }}>
            {preventMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }

  export function CatchResponseCustomizedSnackbars(props) {
    const {dataresponse, validator}=props
    const [typeToast,setTypeToast]=React.useState("success")
    const [open, setOpen]= React.useState(false)
    const [preventMessage, setPreventMessage]=React.useState(null)
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
      setPreventMessage("")

    };

    React.useEffect(()=>{
        if(dataresponse?.error){
            setTypeToast("error")
            setPreventMessage(dataresponse?.error?.description ||"Ha ocurrido un error. Vuelva a intentar más tarde.")
            setOpen(true)
        }else{
            setPreventMessage("")

        }
    },[validator])
  
    return (
      <Stack spacing={2} sx={{ width: '100%',   }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={typeToast} sx={{ width: '100%' }}>
            {preventMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }