import { IOSSwitch } from "../components/shared/Litles/switch";
import { DatePickerCustomed } from "../components/shared/Litles/dates";
import { entityIdActivar } from "./essentialsComsumeAPI";
import { SelectCustomedOptionsPrebuild } from "../components/shared/Litles/selects";
import SelectCityModal from "../components/shared/Litles/cityModal";
import  React, { useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import CityButton from "../components/shared/Litles/cityButton";
import { CountryAfterCity } from "../components/shared/Litles/CountryTableDependency";
import PersonaButton from "../components/shared/Litles/PersonaButton";
import LongDescriptionButton from "../components/shared/Litles/LongDescriptionButton";
import { FeatureButton } from "../components/shared/Litles/featureButton";
import { ButtonFileUpperForms } from "../components/FIleUpper/Button/FIleUpperButton";
import EventAvailableTwoToneIcon from '@mui/icons-material/EventAvailableTwoTone';
Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
};


export const RegistroMision = [
  {
    field: "fecha",
    headerName: "Fecha",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {!params.row.edit?
          <React.Fragment>
            <div onDoubleClick={(e)=>{params.row.onlyyear=!params.row.onlyyear;params.row.refreshdata()}} >
            <DatePickerCustomed value={params.row.fecha} 
              key={'fecha_'+params.row.id}
              inputFormat={params.row.onlyyear?"YYYY":"MM/DD/YYYY"}
              onChange={(e)=>{params.row.fecha=e; params.row.refreshdata()}}/>

            </div>
          </React.Fragment>
          :typeof params.row.fecha === "object"? new Date(params.row.fecha.toString()).yyyymmdd()?.substring(0,params.row.onlyyear?4:10): params.row.fecha.substring(0,params.row.onlyyear?4:10) } 
        </div>
      );
    },
  },
  {
    field: "casa_detalle",
    headerName: "Casa",
    width: 150,
    renderCell: (params) => {
      return (
        <React.Fragment>
          {<SelectCustomedOptionsPrebuild
            refresh={params.row.refreshdata}
            params={params}
            disabled ={params.row.edit?true:null}
            name={"ct_casa_id"}
            Label={"casa_detalle"}
            opciones={params.row.opciones.detallecatalogo}
          />}
        </React.Fragment>
      );
    },
  },
  { 
    field: "ciudad_detalle", 
    headerName: "Ciudad", 
    width: 150,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <CityButton {...params} nombre={"ciudad_id"} Label={"ciudad_detalle"}/>          
        </React.Fragment>
      );
    },
  },
  {
    field: "pais_detalle",
    headerName: "Pais",
    width: 150,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <CountryAfterCity {...params} nombre={"pais_id"} Label={"pais_detalle"}/>
        </React.Fragment>
      );
    },
  },
  {
    field: "superiora",
    headerName: "Superiora",
    width: 250,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <PersonaButton {...params} nombre={"superiora_id"} Label={"superiora"}/>
        </React.Fragment>
      );
    },
  },
  {
    field: "mision_detalle",
    headerName: "Mision que desarrolla",
    width: 250,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <SelectCustomedOptionsPrebuild
            disabled ={params.row.edit?true:null}
            params={params}
            name={"ct_mision_id"}
            Label={"mision_detalle"}
            opciones={params.row.opciones.detallecatalogo}
            />
        </React.Fragment>
      );
    },
  }
];

export const RegistroClinico = [
  {
    field: "fecha",
    headerName: "Fecha",
    width: 170,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {!params.row.edit?
          <div onDoubleClick={(e)=>{params.row.onlyyear=!params.row.onlyyear;params.row.refreshdata()}} >
            <DatePickerCustomed value={params.row.fecha} 
            key={'fecha_'+params.row.id}
            inputFormat={params.row.onlyyear?"YYYY":"MM/DD/YYYY"}
            onChange={(e)=>{params.row.fecha=e}}/>
          </div>
          :typeof params.row.fecha === "object"? new Date(params.row.fecha.toString()).yyyymmdd()?.substring(0,params.row.onlyyear?4:10): params.row.fecha.substring(0,params.row.onlyyear?4:10) } 
        </div>
      );
    },
  },
  {
    field: "establecimiento_detalle",
    headerName: "Establecimiento",
    width: 150,
    renderCell: (params) => {
      return (
        <React.Fragment>
          {<SelectCustomedOptionsPrebuild
            params={params}
            disabled ={params.row.edit?true:null}
            name={"ct_establecimiento_id"}
            Label={"establecimiento_detalle"}
            opciones={params.row.opciones.detallecatalogo}
          />}
        </React.Fragment>
      );
    },
  },
  {
    field: "doctor",
    headerName: "Doctor",
    width: 250,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <PersonaButton {...params} nombre={"doctor_id"} Label={"doctor"} PersonClass={"doctor"}/>
        </React.Fragment>
        
        );
    },
  },
  {
    field: "numero",
    headerName: "Numero",
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {params.row.edit? params.row.numero:
          <TextField fullWidth defaultValue={params.row.numero} onKeyDown={(e) => e.stopPropagation()} onChange={(e)=>{params.row.numero=e.target.value; }} readOnly={params.row.edit} style={{width:"100%", border:"none"}}/>}
        </div>
      );
    },
  },
  { field: "evolucion", 
    headerName: "Evolución", 
    width: 130,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <LongDescriptionButton {...params} nombre={"evolucion"} Label={"Evolucion"}/>
        </React.Fragment>
      );
    },
  },
  { field: "prescripcion", 
    headerName: "Prescripción", 
    width: 130,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <LongDescriptionButton {...params} nombre={"prescripcion"} Label={"Prescripción"}/>
        </React.Fragment>
      );
    },
  },
  { field: "medicamento", //crear modal para  agregar detalles de medicamento {medicamento1:cantidad, medicamento2:cantidad, ...}
    headerName: "Medicamento", 
    width: 130,
    renderCell: (params) => {
      return (
        <React.Fragment>
          {/* <LongDescriptionButton {...params} nombre={"medicamento"} Label={"Medicamento"}/> */}
          <FeatureButton 
            config={params.row.rules}
            name={"medicamento"}
            params={params}
            title = {"Medicamento"}
            jsonData={params.row.medicamento || {}}
            setDataResponse
            setCatchResponse
          />
        </React.Fragment>
      );
    },
  },
  {
    field: "comprobante_id",
    headerName: "Comprobante médico",
    width: 250,
    renderCell: (params) => {
      const handleChange= (e)=>{
        params.row[e.target.name]=e.target.value;
      }
      return (
        <React.Fragment>
          <ButtonFileUpperForms handleChangeReserv={handleChange} idrefDoc={params.row.comprobante_id} nombredocumento={params.row.comprobante_name} name={'comprobante_id'} persona_id={params.row.persona_id} tipoDocumento={64} title={'Comprobante o Ficha Médica.'}/>
          
        </React.Fragment>
      );
    },
  }
];

export const RegistroProfesional = [
  {
    field: "fecha",
    headerName: "Fecha",
    width: 170,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {!params.row.edit?
          <div onDoubleClick={(e)=>{params.row.onlyyear=!params.row.onlyyear;params.row.refreshdata()}} >
            <DatePickerCustomed value={params.row.fecha} 
            inputFormat={params.row.onlyyear?"YYYY":"MM/DD/YYYY"}
            key={'fecha_'+params.row.id}
            onChange={(e)=>{params.row.fecha=e}}/>
          </div>
          :typeof params.row.fecha === "object"? new Date(params.row.fecha.toString()).yyyymmdd()?.substring(0,params.row.onlyyear?4:10): params.row.fecha.substring(0,params.row.onlyyear?4:10) } 
        </div>
      );
    },
  },
  {
    field: "ct_comunidad_detalle",
    headerName: "Comunidad",
    width: 150,
    renderCell: (params) => {
      return (
        <React.Fragment>
          {<SelectCustomedOptionsPrebuild
            params={params}
            disabled ={params.row.edit?true:null}
            name={"ct_comunidad_id"}
            Label={"ct_comunidad_detalle"}
            opciones={params.row.opciones.detallecatalogo}
          />}
        </React.Fragment>
      );
    },
  },
  {
    field: "superiora",
    headerName: "Supervisora",
    width: 250,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <PersonaButton {...params} nombre={"superiora_id"} Label={"superiora"}/>
        </React.Fragment>
        
        );
    },
  },
  {
    field: "ct_responsabilidad_detalle",
    headerName: "Responsabilidad",
    width: 250,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <SelectCustomedOptionsPrebuild
            disabled ={params.row.edit?true:null}
            params={params}
            name={"ct_responsabilidad_id"}
            Label={"ct_responsabilidad_detalle"}
            opciones={params.row.opciones.detallecatalogo}
          />
        </React.Fragment>
      );
    },
  },
  { field: "observacion", 
    headerName: "Observacion", 
    width: 130,
    renderCell: (params) => {
      return (
        <React.Fragment>
          <LongDescriptionButton {...params} nombre={"observacion"} Label={"Observación"}/>
          
        </React.Fragment>
      );
    },
  },
];

export const Personas = [
  {
    field: "numeroidentificacion",
    headerName: "Número de identificación",
    width: 150,
    
  },
  {
    field: "nombrecompleto",
    headerName: "Nombres completos",
    width: 250,
  },
  { field: "estado", 
    headerName: "Miembro", 
    width: "auto",
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <IOSSwitch checked={params.row.estado} onChange={(e)=>{params.row.estado=e.target.checked; entityIdActivar("persona",params.row.idreal)}}/>
        </div>
      );
    },
  }
];

export const Usuarios = [
  {
    field: "numeroidentificacion",
    headerName: "Número de identificación",
    width: 150,
    
  },
  {
    field: "nombrecompleto",
    headerName: "Nombres completos",
    width: 250,
  },
  { field: "estado", 
    headerName: "Estado", 
    width: "auto",
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <IOSSwitch checked={params.row.estado} onChange={(e)=>{params.row.estado=e.target.checked; entityIdActivar("usuario",params.row.idreal)}}/>
        </div>
      );
    },
  }
];

export const Personas2 = [
  {
    field: "numeroidentificacion",
    headerName: "Número de identificación",
    width: 150
  },
  {
    field: "nombrecompleto",
    headerName: "Nombres completos",
    width: 250
  }
];
export const PersonasParentezco = [
  {
    field: "numeroidentificacion",
    headerName: "Número de identificación",
    width: 150
  },
  {
    field: "nombrecompleto",
    headerName: "Nombres completos",
    width: 250
  },
  {
    field: "parentezco",
    headerName: "Parentezco",
    width: 250
  },

];
export const DetalleCatalogo = [
  {
    field: "descripcion",
    headerName: "Detalle",
    width: 150
  }
];

export const DocumentosFormato = [
  {
    field: "documento",
    headerName: "Detalle",
    width: 150,
    renderCell:(params)=>{
      return(
        params.row.documento + params.row.extension
      )
    }
  }
];

export const Estados = [
  { field: "nombre", 
    headerName: "Nombre", 
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {params.row.edit? params.row.nombre:
          <TextField fullWidth defaultValue={params.row.nombre} onKeyDown={(e) => e.stopPropagation()} onChange={(e)=>{params.row.nombre=e.target.value; }} readOnly={params.row.edit} style={{width:"100%", border:"none"}}/>}
        </div>
      );
    },
  },
  { field: "nombreclatura", 
    headerName: "Nombreclatura", 
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {params.row.edit? params.row.nombreclatura:
          <TextField fullWidth defaultValue={params.row.nombreclatura} maxLength={5} onChange={(e)=>{params.row.nombreclatura=e.target.value; }} readOnly={params.row.edit} style={{width:"100%", border:"none"}}/>}
        </div>
      );
    },
    
  },
  {
    field: "fechacreacion",
    headerName: "Fecha creación",
    width: 180,
  }
];

export const Catalogos = [
  { 
    field: "descripcion", 
    headerName: "Descripción", 
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {params.row.edit? params.row.descripcion:
          <TextField fullWidth defaultValue={params.row.descripcion} onKeyDown={(e) => e.stopPropagation()} onChange={(e)=>{params.row.descripcion=e.target.value; }} readOnly={params.row.edit} style={{width:"100%", border:"none"}}/>}
        </div>
      );
    },
  },
  { 
    field: "ordenvisualizacion", 
    headerName: "ordenvisualizacion", 
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          {params.row.edit? params.row.ordenvisualizacion:
          <TextField fullWidth defaultValue={params.row.ordenvisualizacion} onChange={(e)=>{params.row.ordenvisualizacion=e.target.value; }} readOnly={params.row.edit} style={{width:"100%", border:"none"}}/>}
        </div>
      );
    },
  },/* 
  { field: "estado", 
    headerName: "estado", 
    width: 130,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <IOSSwitch checked={params.row.estado} onChange={(e)=>{params.row.estado=e.target.checked}}/>
        </div>
      );
    },
  }, */
];



