import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";

export default function ModalCiudadSelector (props){
    const {listPaises, listEstados, listCiudades, handleClose, ciudadId} = props
    const [selectPais, setSelectPais]=useState(null)
    const [selectEstado, setSelectEstado]=useState(null)
    const [selectCiudad, setSelectCiudad]=useState(null)
    const [listPais, setlistPais]=useState([])
    const [listEstado, setlistEstado]=useState([])
    const [listCiudad, setlistCiudad]=useState([])

    const SelectorHandle=(e)=>{
        /**Set id */
        switch (e.target.name) {
            case "pais":
                setSelectPais(e.target.value)
                setlistEstado(listEstados.filter((element)=>element.pais_id===e.target.value))
                setSelectCiudad(null)
                setlistCiudad([])
                break;
            case "estado":
                setSelectEstado(e.target.value)
                setlistCiudad(listCiudades.filter((element)=>element.estado_id===e.target.value))
                break;
            case "ciudad":
                setSelectCiudad(e.target.value)
                break;
            default:
                break;
        }
        /**Set List base id */
    }

    useEffect(()=>{
        setlistPais(listPaises)
        if(!ciudadId){
        }else{
            var ciudad= listCiudades.find((element)=>element.id===ciudadId)
            if(ciudad){
                setlistCiudad(listCiudades.filter((element)=>element.estado_id===ciudad.estado_id))
                setSelectCiudad(ciudadId)
                var estado= listEstados.find((element)=>element.id===ciudad.estado_id)
                setSelectEstado(estado?.id)
                if(estado){
                    setlistEstado(listEstados.filter((element)=>element.pais_id===estado.pais_id))
                    var pais= listPaises.find((element)=>element.id===estado.pais_id)
                    setSelectPais(pais?.id)
                }
            }
        }
    },[])

    return(
        <React.Fragment>
            {listPais && 
                <Select
                    margin="normal"
                    value={selectPais}
                    fullWidth
                    id={"pais"}
                    label={"Pais"}
                    onChange={SelectorHandle || null}
                    name={"pais"}
                    autoComplete="off"
                    autoFocus
                >
                    {listPais.map((pais)=>(
                        <MenuItem value={pais.id}>{pais.nombre}</MenuItem>
                    ))}
                </Select>
                    
                }
            {selectPais && 
                <Select
                    margin="normal"
                    value={selectEstado}
                    fullWidth
                    id={"estado"}
                    label={"Estado/Provincia"}
                    onChange={SelectorHandle || null}
                    name={"estado"}
                    autoComplete="off"
                    autoFocus
                >
                    {listEstado.map((estado)=>(
                        <MenuItem value={estado.id}>{estado.nombre}</MenuItem>
                    ))}
                </Select>}
            {selectEstado && 
                <Select
                    margin="normal"
                    value={selectCiudad}
                    fullWidth
                    id={"ciudad"}
                    label={"Ciudad"}
                    onChange={SelectorHandle || null}
                    name={"ciudad"}
                    autoComplete="off"
                    autoFocus
                >
                {listCiudad.map((ciudad)=>(
                    <MenuItem value={ciudad.id}>{ciudad.nombre}</MenuItem>
                ))}
                </Select>}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        {selectCiudad && <Button onClick={()=>handleClose(selectCiudad)}>Aceptar</Button>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                    </Grid>
                </Grid>
                
        </React.Fragment>
    )
}