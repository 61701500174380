import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import logoMain from './../../assets/logo.png'
import instance from "../../utilities/Instance";

export default function Skeleton(){
    const [actionsButtoms, setActionsButtoms]= useState([]);

    const consultarMisiones=async()=>{

    }

    const consultarProfesiones=async()=>{

    }

    const consultarHistorialClinico=async()=>{

    }

    const getData=async()=>{
      var botones = []
      botones.push({icon:<PersonSearchTwoToneIcon titleAccess="Buscar Persona"></PersonSearchTwoToneIcon>,
                    action: null})
      botones.push({icon: <LocalPrintshopTwoToneIcon titleAccess="Imprimir reporte"></LocalPrintshopTwoToneIcon>,
                    action: null})
      botones.push({icon: <ContentPasteSearchTwoToneIcon titleAccess="Buscar Reporte"></ContentPasteSearchTwoToneIcon>,
                    action: null})
      setActionsButtoms(botones)
    }

    useEffect(()=> {
      getData();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
  
  
    }, [])

    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
              {/* logo */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <img
                        src={logoMain}
                    >
                    </img>
                </Paper>
              </Grid>
            </Grid>
        </React.Fragment>
    )
}