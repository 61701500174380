import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useId } from "react";
import {  ThemeProvider } from '@mui/material/styles';
import instance from '../../../utilities/Instance';
import Loader from '../../shared/layout/Loader';
import { AlertCodeInterpreter_M, AlertSimpleStandar, AlertCodeInterpreter_F, AlertCodeInterpreter_Catch } from '../../alerts';
import axios from 'axios';
import imgpg1 from '../../../assets/banner/1.jpg'
import imgpg2 from '../../../assets/banner/2.jpg'
import imgpg3 from '../../../assets/banner/3.jpg'
import imgpg4 from '../../../assets/banner/4.jpg'
import imgpg5 from '../../../assets/banner/5.jpg'
import imgpg6 from '../../../assets/banner/6.jpg'
import imgpg7 from '../../../assets/banner/7.jpg'
import imgpg8 from '../../../assets/banner/8.jpg'


function Copyright(props) {

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.sistemascontrol.ec/">
        Control Sistemas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function ChangePasswordModal(props) {
  const {signed, tokenstate, theme, handleClose, usuario_id, handleOpen} = props;
  const [onLoader,setOnLoader] = useState(false);

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var toSend={
      codigo: data.get('codigo'),
      contrasena: data.get('contrasena'),
      contrasena2: data.get('contrasena_confirm'),
    }
    if(!toSend.codigo || !toSend.contrasena || !toSend.contrasena2){
      var info={
        content:"Formulario incompleto. Por favor, coloque el Código solicitado enviado al correo junto a su nueva contraseña."
      }
      AlertSimpleStandar(info, 11);
      return;
    }
    setOnLoader(true);
    if(!usuario_id){
        handleClose()
    }
    toSend.usuarioId=Number(usuario_id)
    //await axios.postForm("http://localhost:8000/api-siscontrol-santamariana/public/Usuario/login", toSend)
    //await instance.postForm("factura/crear", toSend)
    await instance.postForm("Usuario/ChangePassword", toSend)
    .then(async (response)=>{
      setOnLoader(false);
      handleClose()
      if(! await AlertCodeInterpreter_F(response.data.data)){
        //handleOpen()
      }
    })
    .catch((error)=>{
      if(error?.response?.data?.error){
        AlertCodeInterpreter_Catch(error.response.data.error)
      }
      console.error(error)
    })


    setOnLoader(false);

  };

  return (
    <ThemeProvider theme={theme}>
      {<Loader onLoader={onLoader}/>}
      <Grid container >
        <CssBaseline />
        
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Cambio de contraseña
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="codigo"
                label="Código"
                name="codigo"
                autoComplete="code"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Nueva contraseña"
                name="contrasena"
                type="password"
                id="password"
                autoComplete="new-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Confirme contraseña"
                name="contrasena_confirm"
                type="password"
                id="password2"
                autoComplete="new-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Cambiar de contraseña
              </Button>
              <Grid container> 
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link href="/" variant="body2">
                    {"¿Ya tienes cuenta? Ingresa."}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
            <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleClose}>Cancelar</Button>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}