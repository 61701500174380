import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function SimpleColumnSelector(props){
    const {config, setResult}=props
    const [arrCols, setArrCols]= useState([])

    useEffect(()=>{
        var colums=[]
        for(var key in config){
            colums.push({...config[key], edit:false})
        }
        setArrCols(colums)
    },[config])

    const HandleClick=(key)=>{
        var colums= arrCols
        colums.forEach(element => {
            if(element.key===key){
                element.edit=true
            }else{
                element.edit=false
            }
        });
        setArrCols(colums)
        setResult(key)
    }

    return(
        <React.Fragment>
            {arrCols?.map((element, index)=>(
                <Grid key={"arrCols_"+index} item xs={12} md={12} lg={12} sx={{display:'flex'}}>
                    <TextField
                        key={"TextField_arrCols_"+index}
                        multiline
                        maxRows={3}
                        margin="normal"
                        variant="standard"
                        value= {element.label}
                        name= {element.key}
                        focused={element.edit}
                        fullWidth
                    ></TextField>
                    {element.edit?
                        <IconButton key={"IconButton_arrCols_1"+index} aria-label="delete" size="large" onClick={()=>{HandleClick(element.key)}}>
                            <ArrowForwardIosIcon color="primary" value={"field.name"} fontSize="inherit" />
                        </IconButton>:
                        <IconButton key={"IconButton_arrCols_2"+index} aria-label="delete" size="large" onClick={()=>{HandleClick(element.key)}}>
                            <EditTwoToneIcon value={"field.name"} fontSize="inherit" />
                        </IconButton>}
                    
                </Grid>
            ))}
        </React.Fragment>
    )
}