import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ButtonGroup, Button, Select, MenuItem } from "@mui/material";
import  React, { useEffect, useState } from "react";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SelectDetalleCatalogoModal from './SelectDetalleCatalogoModal';
import IconButton from '@mui/material/IconButton';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

export function SelectCustomedOptionsPrebuild(props){
    const {opciones, params, name, Label, value_name, label_name, refresh}= props;
    const [open, setOpen]=useState(false)
    const [selectedId, setSelectedId]=useState(params.row[name])
    const {actualizarFunc}= params.row
    const [listaOpciones, setListaOpciones]= useState([])
    const [namePerson, setName]= useState('')
    const [selectedDetalleCatalogo, setSelectedDetalleCatalogo]=useState(0)
    const [parent, setParent]=useState(0);

    const handleOpen=()=>{
        setOpen(true)
      }
      const handleClosePersona=(nombrecompleto, id)=>{
        if(id){
          setSelectedId(id)
          params.row[name]=id
          setSelectedDetalleCatalogo(id)
          var existInList= listaOpciones.find((element)=> element.id===id)
          if(!existInList){
            var newList= listaOpciones
            newList.push({id:id, descripcion:nombrecompleto, catalogo_id: parent})
            setListaOpciones(newList)
          }
        }
        setOpen(false)
      }
    useEffect(()=>{
        if(params.row.rules){
            var ruleCP = params.row.rules.find((element)=> element.nombre==="Catalogo parent")?.configuracion
            var ruleCG = params.row.rules.find((element)=> element.nombre==="Groups Catalogo")?.configuracion
            var keyid=ruleCP[ruleCG[name]]
            setParent(keyid)
            var listaFiltradas= opciones.filter((element)=>element.catalogo_id===keyid)
            setListaOpciones(listaFiltradas)
        }
        
    },[])
    const handleChange=(e)=>{
        setSelectedId(e.target.value)
        params.row[name]=e.target.value
        if(typeof refresh==='function'){
            refresh()
        }
    }
    return (
        <React.Fragment>
            <SelectDetalleCatalogoModal actualizar={actualizarFunc} parent={parent} open={open} handleClose={handleClosePersona}></SelectDetalleCatalogoModal>
            {!params.row.edit?<IconButton aria-label="delete" size="large" onClick={handleOpen}>
                <ContentPasteSearchIcon value={"field.name"} fontSize="inherit" />
            </IconButton>:null}
            <Select
                {...props}
                margin="normal"
                value={selectedId}
                fullWidth
                key={'casa_detalle_'+params.row.id}
                //label={""}
                onChange={handleChange}
                autoComplete="off"
            >
            {listaOpciones.map((opcion)=>(
                <MenuItem value={opcion.id} >{opcion.descripcion || opcion.nombre || ''}</MenuItem>
            ))}
            </Select>
        </React.Fragment>
      )
} 


export function SelectCustomedOptionsPrebuildFORM(props){
    const {opciones, params, name, Label, value_name, label_name, refresh}= props;
    const [open, setOpen]=useState(false)
    const [selectedId, setSelectedId]=useState(params.row[name])
    const [listaOpciones, setListaOpciones]= useState([])
    const [namePerson, setName]= useState('')
    const [selectedDetalleCatalogo, setSelectedDetalleCatalogo]=useState(0)
    const [parent, setParent]=useState(0);

    const handleOpen=()=>{
        setOpen(true)
      }
      const handleClosePersona=(nombrecompleto, id)=>{
        if(id){
          handleChange(id, value_name, nombrecompleto, label_name)
          setName(nombrecompleto)
          setSelectedDetalleCatalogo(id)
        }
        setOpen(false)
      }
    useEffect(()=>{
        if(params.row.rules){
            var ruleCP = params.row.rules.find((element)=> element.nombre==="Catalogo parent")?.configuracion
            var ruleCG = params.row.rules.find((element)=> element.nombre==="Groups Catalogo")?.configuracion
            var keyid=ruleCP[ruleCG[name]]
            setParent(keyid)
            var listaFiltradas= opciones.filter((element)=>element.catalogo_id===keyid)
            setListaOpciones(listaFiltradas)
        }
        
    },[])
    const handleChange=(e)=>{
        setSelectedId(e.target.value)
        params.row[name]=e.target.value
        if(typeof refresh==='function'){
            refresh()
        }
    }
    return (
        <React.Fragment>
            <SelectDetalleCatalogoModal parent={parent} open={open} handleClose={handleClosePersona}></SelectDetalleCatalogoModal>
            {<IconButton aria-label="delete" size="large" onClick={handleOpen}>
                <ContentPasteSearchIcon value={"field.name"} fontSize="inherit" />
            </IconButton>}
            <Select
                {...props}
                margin="normal"
                value={selectedId}
                fullWidth
                key={'casa_detalle_'+params.row.id}
                //label={""}
                onChange={handleChange}
                autoComplete="off"
            >
            {listaOpciones.map((opcion)=>(
                <MenuItem value={opcion.id} >{opcion.descripcion || opcion.nombre || ''}</MenuItem>
            ))}
            </Select>
        </React.Fragment>
      )
} 