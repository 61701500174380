import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
import SimpleColumnSelector from "../gadgets/SimpleColumnSelector";
import { ArrOfJsonEditor } from "../gadgets/ChildrenSimpleColumnSelector";

/**
 * Función por defecto para gestionar datos json del tipo llave-array => {key:[...values]}
 * @param {datajson, controllerDatajson, setDataJson} props 
 * @returns 
 */
export default function FeatureBase(props){
    const {datajson, controllerDatajson, setDataJson, tipoDocumento, idreferencia}=props
    const [keySelected, setKeySelecter]=useState(null)
    useEffect(()=>{
    },[])

    const changeKey=(key)=>{
        setKeySelecter(key)
    }

    return(
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6} sx={{height:350, overflowY:"auto"}}>
                    <SimpleColumnSelector config={controllerDatajson} setResult={changeKey}/>
                </Grid>
                <Grid item xs={6} md={6} lg={6} sx={{height:350, overflowY:"hidden", background:'#e0d0d0', borderTopLeftRadius:25}}>
                    <ArrOfJsonEditor config={controllerDatajson} formData={datajson} keyG={keySelected} setFormData={setDataJson} tipoDocumento={tipoDocumento} 
                        idreferencia={idreferencia}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}