import axios from 'axios';
import { ExpiredSession } from '../components/alerts';


const instance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  withCredentials: false,
  baseURL: 'https://api-conventomariana.controlsistemasjl.com/',

});

 instance.interceptors.request.use(
  config => {
    const configuration = localStorage.getItem('auth_jwt');
    if (configuration) {
      config.headers['Authorization'] = `${configuration}`;
      ///config.headers['Authorization'] = `${configuration}`;
      config.headers['Content-Type'] = "application/json; charset=utf-8";
    }
    return config;
  },
  error => {
    const { response: { status } } = error;
    if (status === 401) {
      localStorage.clear('auth_jwt');
    } else {
      return Promise.reject(error);
    }
  },
);

instance.interceptors.response.use(
  
  async response =>{
    if(response?.data?.expired){
      localStorage.clear()
      if(await ExpiredSession(response.data.detalle)){
        window.location.assign('/')
      }
      return
    }
    if(response?.data?.data?.logob64)
      {
        localStorage.setItem("logob64", response.data.data.logob64)
      }
    if(response?.data?.refreshToken){  
      localStorage.setItem("auth_jwt", response.data?.refreshToken)
    }
    return response
  },
  async function (error) {
    const { response: { status } } = error;
    if (status === 401) {
      localStorage.clear('auth_jwt');
      if(await ExpiredSession('Sesión expirada')){
        window.location.assign('/')
      }
    } else {
      const { config, message } = error;
      if (!config?.retry) {
        return Promise.reject(error);
      }
      // retry while Network timeout or Network Error
      if (!(message.includes("timeout") || message.includes("Network Error"))) {
        return Promise.reject(error);
      }
      config.retry -= 1;
      const delayRetryRequest = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, config.retryDelay || 1000);
      });
      return delayRetryRequest.then(() => instance(config));

      //return Promise.reject(error);
    }
  },
);


/*export const Instance_UDC = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  withCredentials: false,
  baseURL: 'http://localhost:7195/',
});

Instance_UDC.interceptors.request.use(
  config => {
    const configuration = localStorage.getItem('auth_jwt');
    if (configuration) {
      config.headers['Authorization'] = `Bearer ${configuration}`;
    }

    return config;
  },
  error => {
    const { response: { status } } = error;
    if (status === 401) {
      //localStorage.clear('auth_jwt');
    } else {
      return Promise.reject(error);
    }
  },
);*/

export default instance;
