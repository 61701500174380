import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalPersonaSelector from "../layout/ModalPersonaSelector";
import Loader2 from "../layout/Loader2";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { ButtonFileUpperForms } from "../../FIleUpper/Button/FIleUpperButton";

export function FieldsContainer(props){
    const {fields, handleChange, remove, tipoDocumento, idreferencia, extrafields}=props
    
    useEffect(()=>{
    },[fields])

    return(
        <React.Fragment>
            {fields?.map? fields.map((element, index)=>(
                <Grid key={"fields_"+index} item xs={12} md={12} lg={12} sx={{display:'flex'}}>
                    
                    {(idreferencia?true:false) && 
                        tipoDocumento?.map((element2, index2)=>(
                            <Grid key={'upperDoc_'+index2} item xs={12} sm={12} md={12} lg={12} sx={{display:"flex", alignItems:"center"}}>
                                <ButtonFileUpperForms handleChangeReserv={(e)=>handleChange(index, {[e.target.name]:e.target.value, [element2.fromText]: e.target.label})} idrefDoc={element[element2.name]} nombredocumento={element[element2.fromText]} name={element2.name} persona_id={idreferencia} tipoDocumento={element2?.id} title={element2?.title}/>
                            </Grid>
                        ))
                    }
                    {!idreferencia && <TextField
                        key={"TextField_fields_"+index}
                        multiline
                        variant="standard"
                        margin="normal"
                        value= {element}
                        onChange={(e)=>handleChange(index,e.target.value)}
                        fullWidth
                    ></TextField>}
                    {extrafields?.length>0 && false && <>
                        <IconButton key={"IconButton_fields_"+index} aria-label="delete" size="large" onClick={remove?(e)=>remove(index):null}>
                            <EditTwoToneIcon color="primary" value={"field.name"} fontSize="inherit" />
                        </IconButton>
                    </>}
                    <IconButton key={"IconButton_fields_"+index} aria-label="delete" size="large" onClick={remove?(e)=>remove(index):null}>
                        <RemoveCircleTwoToneIcon color="primary" value={"field.name"} fontSize="inherit" />
                    </IconButton>
                </Grid>
            )):null}
        </React.Fragment>
    )
}