export const diccionarioInterprete = {
    "id": "ID",
    "persona_id":"persona_id",
    "fecha_ingreso":"fecha_ingreso",
    "ciudad_ingreso_id": "ciudad_ingreso_id",
    "noviciado":"noviciado",
    "maestra_noviciado_id":"maestra_noviciado_id",
    "ciudad_noviciado_id":"ciudad_noviciado_id",
    "fecha_primera_profesion":"fecha_primera_profesion",
    "fecha_profesion_perpetua": "fecha_profesion_perpetua",
    "ciudad_profesion_id": "ciudad_profesion_id",
    "maestra_profesion_id":"maestra_profesion_id",
    "prescripcion":"prescripcion",
    "fecha":"fecha",
    ///
    "valores":"valores",
    "precedetes":"precedetes",
    "antecendente_vida_consagrada":"antecendente_vida_consagrada",
    //"trabajo_apostolico":"trabajo_apostolico",

    "estudios":"estudios",
    "titulos":"titulos",

    "padre_id":"padre_id",
    "madre_id":"madre_id",
    "madre":"madre",
    "emergencia":"emergencia",
    "n_hermanos":"n_hermanos",
    "n_nicho":"n_nicho",
    "n_varones":"n_varones",
    "n_mujeres":"n_mujeres",
    "ct_pos_familia_id":"ct_pos_familia_id",
    "persona_emergencia_id":"persona_emergencia_id",
    "personas_emergencia":"personas_emergencia",
    "numeroidentificacion":"Número de identificación",
    "apellidos": "Apellidos",
    "nombres": "Nombres",
    "nombrecompleto": "Nombres completos",
    "telefono":"Teléfono",
    "telefonocelular": "Teléfono celular",
    "lugarnacimiento": "Lugar de nacimiento",
    "cementerio": "Cementerio",
    "seccion": "Seccion",
    "ciudadnatalidad": "Ciudad de natalidad",
    "fechanacimiento": "Fecha de nacimiento",
    //"fecha": "Fecha",
    "fechadefuncion": "Fecha Defunción",
    "ciudaddefuncion": "Ciudad defuncion",
    "ciudadresidencia": "Ciudad de residencia",
    "correopersonal": "Correo Personal",
    "correointitucional": "Correo Institucional",
    "ct_tipoidentificacion_id": "ID Tipo Identidad",
    "tipoidentificacion": "Tipo Identificación",
    "ct_estadocivil": "ID Estado Civil",
    "estadocivil": "Estado Civil",
    "ct_sexo": "ID Sexo",
    "sexo": "Sexo",
    "observacion": "Observación",
    "fechacreacion": "Fecha Creación",
    "fechamodificacion": "Fecha Modificación",
    "direccion": "Dirección",
    
    "paisnombre":"Pais",
    "estadonombre":"Estado/provicia",
    "ciudadnombre":"Ciudad"
}

export const diccionarioParidad={
    "ciudadnatalidad": {ref:"ciudadnatalidad_id", list: "ciudad"},
    "ciudad": {ref:"ciudad_id", list: "ciudad"},
    "ciudadresidencia": {ref:"ciudadresidencia_id", list: "ciudad"},
    "ciudaddefuncion": {ref:"ciudaddefuncion_id", list: "ciudad"},
    "ciudad_ingreso": {ref:"ciudad_ingreso_id", list: "ciudad"},
    "ciudad_noviciado": {ref:"ciudad_noviciado_id", list: "ciudad"},
    "ciudad_profesion": {ref:"ciudad_profesion_id", list: "ciudad"},
    "tipoidentificacion": {ref:"ct_tipoidentificacion_id", list: "detallecatalogo"},
    "tiporol": {ref:"ct_tiporol", list: "detallecatalogo"},
    "tiporolreligioso": {ref:"ct_tiporolreligioso", list: "detallecatalogo"},
    "estadocivil": {ref:"ct_estadocivil", list: "detallecatalogo"},
    "sexo": {ref:"ct_sexo", list: "detallecatalogo"},
    "tiposangre": {ref:"ct_tiposangre", list: "detallecatalogo"},
    "tiposeguro": {ref:"ct_tiposeguro", list: "detallecatalogo"},
    "formacion": {ref:"ct_formacion", list: "detallecatalogo"},
    "estadonombre":{ref:"estado_id", list:"detallecatalogo"},
    "paisnombre":{ref:"pais_id", list: "detallecatalogo"},
    "pos_familia":{ref:"ct_pos_familia_id", list: "detallecatalogo"},
    
}
/**Conjunto de diccionarios para identificar el nombre título de los campos */
const diccionarioLabelSelectField={
    "ct_tipoidentificacion_id":{label:"Tipo de identidad", paridad:true},
    "ct_tiporol":{label:"Rol usuario", paridad:true},
    "ct_tiporolreligioso":{label:"Posición religiosa", paridad:true},
    "ct_estadocivil":{label:"Estado civil", paridad:true},
    "ct_sexo":{label:"Sexo", paridad:true},
    "ct_tiposangre":{label:"Tipo de sangre", paridad:true},
    "ct_tiposeguro":{label:"Tipo de seguro", paridad:true},
    "ct_formacion":{label:"Tipo de formación", paridad:true},
    "ct_pos_familia_id":{label:"Posición familiar", paridad:true},
    
}

const diccionarioLabelTextField={
    "id":{label: "ID"},
    "numeroidentificacion":{label: "Número de identidad"},
    "apellidos":{label: "Apellidos"},
    "nombres":{label: "Nombres"},
    "nombrecompleto":{label: "Nombres completos"},
    "telefono":{label: "Teléfono"},
    "telefonocelular":{label: "Teléfono celular"},
    "lugarnacimiento":{label: "Lugar de nacimiento"},
    "cementerio":{label: "Cementerio"},
    "seccion":{label: "Seccion"},
    "correopersonal":{label: "Correo personal"},
    "correointitucional":{label: "Correo institucional"},
    "observacion":{label: "Observación"},
    "direccion":{label: "Dirección"},
    "n_hermanos":{label: "Número de hermanos", type: 'number'},
    "n_nicho":{label: "N° Nicho", type: 'number'},
    "n_mujeres":{label: "Varones", type: 'number'},
    "n_varones":{label: "Mujeres", type: 'number'},
    "noviciado": {label: "Noviciado"},
}

const diccionarioLabelToggleSwitchField={
    "estado":{label: "Estado"},
    "discapacidad":{label: "Discapacidad"},
    "extranjero":{label: "Extranjero"},
    "activarcuenta":{label: "Activar Cuenta"},
}

const diccionarioLabelDateTimeField={
    "fechanacimiento":{label: "Fecha de nacimiento"},
    "fecha":{label: "Fecha"},
    "fechadefuncion":{label: "Fecha defunción"},
    "fechacreacion":{label: "Fecha de creación"},
    "fechamodificacion":{label: "Fecha de modificación"},
    
    "fecha_ingreso":{label: "Fecha de Ingreso"},
    "fecha_primera_profesion":{label: "Fecha primera profesión"},
    "fecha_profesion_perpetua": {label: "Fecha profesión perpetua"},
}

const diccionarioModalSearch={
    "ciudadnatalidad_id":{label:"Ciudad de nacimiento", list: "listCiudades" },
    "ciudad_id":{label:"Ciudad", list: "listCiudades" },
    "ciudadresidencia_id":{label:"Ciudad de residencia", list: "listCiudades"},
    "ciudaddefuncion_id":{label:"Ciudad de defunción", list: "listCiudades"},

    "ciudad_ingreso_id": {label:"Ciudad de ingreso", list: "listCiudades"},
    "ciudad_noviciado_id": {label:"Ciudad Noviciado", list: "listCiudades"},
    "ciudad_profesion_id": {label:"Ciudad profesión.", list: "listCiudades"},
}

const diccionarioModalPersonaSearch={
    "madre_id":{label:"Nombre de la madre", list: "listPersonas", fromtext:"madre" },
    "padre_id":{label:"Nombre del padre", list: "listPersonas", fromtext:"padre"},
    "persona_emergencia_id":{label:"Contacto principal de emergencia", list: "listPersonas", fromtext:"emergencia"},
    "maestra_profesion_id":{label:"Maestra de profesión", list: "listPersonas", fromtext:"maestra_profesion"},
    "maestra_noviciado_id":{label:"Maestra de noviciado", list: "listPersonas", fromtext:"maestra_noviciado"},
}

const diccionarioModalPersonasSearch={
    "personas_emergencia":{label: "Contactos adicionales de emergencia.", list: "listPersonas", fromtext:"none"}
}

const diccionarioModalText={
    "precedetes":{label:"Precedentes",  fromtext:"precedetes"},
    "antecendente_vida_consagrada":{label:"Antecedentes vida consagrada.", fromtext:"antecendente_vida_consagrada"},
}

const diccionarioFeaturesModal={
    "valores":{label:"Valores que le caracterizan",  fromtext:"valores", KeysController:["Valores y virtudes"]},
    //"trabajo_apostolico":{label:"Trabajo apostólico",  fromtext:"trabajo_apostolico", KeysController:["humano", "espiritual"]},
    "estudios":{label:"Formaciòn académica",  fromtext:"estudios", KeysController:["Primaria", "Secundaria","Tercer Nivel","Cuarto nivel","Otros"]},
    "titulos":{label:"Tìtulos",  fromtext:"titulos", KeysController:["Académicos", "Otros"]},
    
}

const diccionarioFeaturesArrayModal={
    /* "trabajo_apostolico":{label:"Trabajo apostólico",  fromtext:"trabajo_apostolico", 
        PrimaryKeyController: {ref:"cargo", label: "Ocupación/Cargo"},
        KeysController:[{ref:"tiempo", label: "Tiempo enseñando"}]}, */
}

export const diccionarioFieldToDiccionarioLabel={
    "selectfield":diccionarioLabelSelectField,
    "textfield":diccionarioLabelTextField,
    "ToggleSwitchField":diccionarioLabelToggleSwitchField,
    "DateTimeField":diccionarioLabelDateTimeField,
    "paridad":diccionarioParidad,
    "modalSearch":diccionarioModalSearch,
    "modalPersonaSearch":diccionarioModalPersonaSearch,
    "modalPersonasSearch":diccionarioModalPersonasSearch,
    "modalText": diccionarioModalText,
    "featuresModal": diccionarioFeaturesModal,
    "featuresArrayModal": diccionarioFeaturesArrayModal,
}

export const diccionarioField ={

    "ciudadnatalidad_id":"modalSearch",
    "ciudad_id":"modalSearch",
    "ciudadresidencia_id":"modalSearch",
    "ciudaddefuncion_id":"modalSearch",
    ///
    "ciudad_ingreso_id": "modalSearch",
    "ciudad_noviciado_id":"modalSearch",
    "ciudad_profesion_id": "modalSearch",
    

    "madre_id":"modalPersonaSearch",
    "padre_id":"modalPersonaSearch",
    "persona_emergencia_id":"modalPersonaSearch",
    /////
    "maestra_profesion_id":"modalPersonaSearch",
    "maestra_noviciado_id":"modalPersonaSearch",


    "personas_emergencia": "modalPersonasSearch",


    "ct_tipoidentificacion_id":"selectfield",
    "ct_estadocivil":"selectfield",
    "ct_sexo":"selectfield",
    "ct_tiposangre":"selectfield",
    "ct_tiposeguro":"selectfield",
    "ct_formacion":"selectfield",
    "ct_tiporol":"selectfield",
    "ct_tiporolreligioso":"selectfield",
    "ct_pos_familia_id":"selectfield",


    "id":"textfield",
    "numeroidentificacion":"textfield",
    "apellidos":"textfield",
    "nombres":"textfield",
    "nombrecompleto":"textfield",
    "telefono":"textfield",
    "telefonocelular":"textfield",
    "lugarnacimiento":"textfield",
    "cementerio":"textfield",
    "seccion":"textfield",
    "correopersonal":"textfield",
    "correointitucional":"textfield",
    "observacion":"textfield",
    "direccion":"textfield",
    "n_hermanos":"textfield",
    "n_nicho":"textfield",
    "n_mujeres":"textfield",
    "n_varones":"textfield",
    ////
    "noviciado":"textfield",
    
    "activarcuenta":"ToggleSwitchField",
    "discapacidad":"ToggleSwitchField",
    "extranjero":"ToggleSwitchField",

    "fechanacimiento":"DateTimeField",
    "fecha":"DateTimeField",
    "fechadefuncion":"DateTimeField",
    "fechacreacion":"DateTimeField",
    "fechamodificacion":"DateTimeField",
    /////
    "fecha_ingreso":"DateTimeField",
    "fecha_primera_profesion":"DateTimeField",
    "fecha_profesion_perpetua": "DateTimeField",

    "ciudadnatalidad": "paridad",
    "tiporol": "paridad",
    "tiporolreligioso": "paridad",
    "ciudadresidencia": "paridad",
    "ciudaddefuncion": "paridad",
    "tipoidentificacion": "paridad",
    "estadocivil": "paridad",
    "sexo": "paridad",
    "formacion": "paridad",
    "tiposangre": "paridad",
    "tiposeguro": "paridad",
    "estadonombre":"paridad",
    "paisnombre":"paridad",
    "pos_familia":"paridad",


    "precedetes": "modalText",
    "antecendente_vida_consagrada": "modalText",


    "valores": "featuresModal",
    //"trabajo_apostolico": "featuresModal",
    "titulos": "featuresModal",
    "estudios":"featuresModal"
    
    //"trabajo_apostolico": "featuresArrayModal",

}

export const diccionarioSelect ={
    "ciudadnatalidad_id": ["ciudadnatalidad_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudad_id": ["ciudad_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudadresidencia_id": ["ciudadresidencia_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudaddefuncion_id": ["ciudaddefuncion_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudad_ingreso_id": ["ciudad_ingreso_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudad_noviciado_id": ["ciudad_noviciado_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ciudad_profesion_id": ["ciudad_profesion_id", "estadonatalidad_id", "paisnatalidad_id"],
    "ct_tipoidentificacion_id": ["ct_tipoidentificacion_id"],
    "ct_estadocivil": ["ct_estadocivil"],
    "ct_pos_familia_id": ["ct_pos_familia_id"],
    "ct_sexo": ["ct_sexo"],
    "ct_tiposangre": ["ct_tiposangre"],
    "ct_tiposeguro": ["ct_tiposeguro"],
    "ct_formacion": ["ct_formacion"],
}

export const diccionarioSelectEntity ={
    "ciudadnatalidad_id": ["ciudad"],
    "ciudad_id": ["ciudad"],
    "ciudadresidencia_id": ["ciudad"],
    "ciudaddefuncion_id": ["ciudad"],
    "ciudad_ingreso_id": ["ciudad"],
    "ciudad_noviciado_id": ["ciudad"],
    "ciudad_profesion_id": ["ciudad"],
    "ct_tipoidentificacion_id": ["detallecatalogo"],
    "ct_estadocivil": ["detallecatalogo"],
    "ct_pos_familia_id": ["detallecatalogo"],
    "ct_sexo": ["detallecatalogo"],
    "ct_tiposangre": ["detallecatalogo"],
    "ct_tiposeguro": ["detallecatalogo"],
    "ct_formacion": ["detallecatalogo"],
}

export const diccionarioSelectCatalog ={
    "ct_tipoidentificacion_id": ["tipoidentidad"],
    "ct_estadocivil": ["estadocivil"],
    "ct_pos_familia_id": ["pos_familia"],
    "ct_sexo": ["sexo"],
    "ct_tiposangre": ["sexo"],
    "ct_tiposeguro": ["sexo"],
    "ct_formacion": ["sexo"],
}

export const diccionarioBoolean ={
    "estado": "Estado",
    "discapacidad": "Discapacidad",
    "extranjero": "Extranjero",

}


//AgrupacionesFormato.
export const formatoPerfilReligioso={
    "esaspirando": {
        "label":"Aspirantado",
        "fechaaspirando": {
            "type":"DateTimeField",
            "name": "fechaaspirando",
            "value":""
        },
        "maestraaspirando":{
            "fromText":"maestraaspirandonombre",
            "type":"modalPersonaSearch",
            "name": "maestraaspirando",
            "value": ""
        },
        "ciudadaspirando":{
            "name": "ciudadaspirando",
            "type":"modalSearch",
            "value": ""
        }
    },
    "espostulando": {
        "label":"Postulando",
        "fechapostulando": {
            "name": "fechapostulando",
            "type":"DateTimeField", 
            "value":""
        },
        "maestrapostulando":{
            "fromText":"maestrapostulandonombre",
            "type":"modalPersonaSearch",
            "name": "maestrapostulando",
            "value": ""
        },
        "ciudadpostulando":{
            "name": "ciudadpostulando",
            "type":"modalSearch",
            "value": ""
        }
    },
    "esnoviciado": {
        "label":"Noviciado",
        "fechanoviciado": {
            "name": "fechanoviciado",
            "type":"DateTimeField", 
            "value":""
        },
        "maestranoviciado":{
            "fromText":"maestranoviciadonombre",
            "type":"modalPersonaSearch",
            "name": "maestranoviciado",
            "value": ""
        },
        "ciudadnoviciado":{
            "name": "ciudadnoviciado",
            "type":"modalSearch",
            "value": ""
        }
    },
    "esjuniorado": {
        "label":"Juniorado",
        "fechajuniorado": {
            "name": "fechajuniorado",
            "type":"DateTimeField", 
            "value":""
        },
        "maestrajuniorado":{
            "fromText":"maestrajunioradonombre",
            "type":"modalPersonaSearch",
            "name": "maestrajuniorado",
            "value": ""
        },
        "ciudadjuniorado":{
            "name": "ciudadjuniorado",
            "type":"modalSearch",
            "value": ""
        }
    },
    "esperpetua": {
        "label":"Profesión Perpetua",
        "fechaperpetua": {
            "name": "fechaperpetua",
            "type":"DateTimeField", 
            "value":""
        },
        "maestraperpetua":{
            "fromText":"maestraperpetuanombre",
            "type":"modalPersonaSearch",
            "name": "maestraperpetua",
            "value": ""
        },
        "ciudadperpetua":{
            "name": "ciudadperpetua",
            "type":"modalSearch",
            "value": ""
        }
    }
}