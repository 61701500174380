import React from 'react';

export default function Loader(props) {
  const {onLoader}=props;
  return (
    <>
      {onLoader && (
        <div className='backloader'>
          <div className="preloader-it">
            <div className="loader-pendulums"></div>
            <span className="loader"></span>
          </div>
        </div>
        )
      }
    </>
  );
}
