import React from "react";
import { useState } from "react";
import TablaGestion from "../../components/shared/layout/Table";
import { Personas } from "../../structure/Tables";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { Box, Modal } from "@mui/material";
import Loader2 from "../../components/shared/layout/Loader2";
import { ModalsubPersonaCreator } from "../../components/shared/layout/ModalsubPersonaSelector";
import { IOSSwitch } from "../../components/shared/Litles/switch";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function PersonasView(props){
    const {data=[], addRow, getData, borrarRegistro, backOption,backFunction,openEdit, title, setDataResponse, setCatchResponse, filters, setFilters}=props;
    const [opensub, setOpensub]=useState(false)
    const [loader, setLoader2]=useState(false)

    const handleClosesub =()=>{
        setOpensub(false);
        getData(null)
    }

    

    const [sortModel, setSortModel] = React.useState([
        {
          field: 'nombrecompleto',
          sort: 'asc',
        },
      ]);


    const botonesAccion = [
        {
        field: "action",
        headerName: <div  style={{marginBottom:"-10px"}}><AddTwoToneIcon titleAccess="Agregar" onClick={()=>setOpensub(true)}/></div>,
        width: 60,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} onClick={async(e)=>{
                if(openEdit || typeof openEdit === "function"){openEdit(params.row, "persona")};
                }} >{<EditTwoToneIcon titleAccess="Editar" /> }
                </div>
                {/* 
                {!backOption?
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    verCiudades? verCiudades(params.row.idreal):e.preventDefault()
                }} ><TravelExploreTwoToneIcon titleAccess="Consultar ciudades"/>
                </div>:null} */}
            </div>
            );
        },
        },
    ];

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={opensub}
                onClose={handleClosesub}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <>
                <Box sx={{ ...style, width: 580 }}>
                <Loader2 onLoader={loader}/>
                    <h2 id="parent-modal-title">Nuevo</h2>
                    <ModalsubPersonaCreator
                        setDataResponse={setDataResponse}
                        setCatchResponse={setCatchResponse}
                        setLoader={setLoader2}
                        handleClose={handleClosesub}
                    />
                </Box>
                </>
                
            </Modal>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <h3>Solo hermanas</h3>
                    <IOSSwitch 
                        checked={filters.f_hermanas} 
                        onChange={(e)=>{setFilters(prev=>({...prev, f_hermanas:e.target.checked}))}}
                    /> 
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <h3>Sin N° identificación</h3>
                    <IOSSwitch 
                        checked={filters.f_SNID} 
                        onChange={(e)=>{setFilters(prev=>({...prev, f_SNID:e.target.checked}))}}
                    /> 
                </div>
            </div>
            <TablaGestion 
                botones ={botonesAccion}
                data={data
                    .filter(x=> ((filters.f_hermanas && x.estado) || !filters.f_hermanas) && ((filters.f_SNID && !x.numeroidentificacion) || !filters.f_SNID ) )
                }
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {Personas} 
                titulo={
                    <>
                        {backOption?<KeyboardBackspaceTwoToneIcon onClick={backFunction} sx={{cursor:"pointer"}}/>:null}
                        {title}
                    </>} 
            />
        </React.Fragment>
    )
}
