import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import instance from "../../../utilities/Instance";
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import { DetalleCatalogo } from "../../../structure/Tables";
import TablaGestion from "./Table";
import { isNumeric } from "../../../utilities/Utilities";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F } from "../../alerts";


export default function ModalsubDetalleCatalogoSelector (props){
    const { handleClose, setLoader, parent, actualizar} = props
    const [contadorSecuencias, setContador] =useState([])
    const [searchfield, setSearchField]=useState('')

    const [listPersonas, setListPersonas]=useState([])
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'fecha',
          sort: 'asc',
        },
      ]);


    const searchBy=(e)=>{
        var cuerpo='add'
        
        if(e.key==='Enter'){
            if(!searchfield){
                return
            }else{
                getDataSearch(cuerpo)
            }
        }
    }

    const handleChange = (e)=>{
        setSearchField(e.target.value)
    }

    const getDataSearch=async (cuerpo)=>{
        setLoader(true)

        var data = {
            catalogoId:parent,
            descripcion: searchfield
        }
        await instance.postForm("detallecatalogo/"+cuerpo, data)
        .then(async(response)=>{
          /* if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          } */
        }).catch(error =>{
            if(error?.response?.data?.error){
                AlertCodeInterpreter_Catch(error.response.data.error)
              }
          console.error(error)
        })
        setLoader(false)
        handleClose()
    }



    useEffect(()=>{
    },[])


    return(
        <React.Fragment>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                    fullWidth
                        value={searchfield}
                        label={"Ingrese el nuevo dato y pulse 'ENTER' para agregar."}
                        onChange={handleChange}
                        onKeyDown={searchBy}
                    >
                        
                    </TextField>
                </Grid>
            </Grid>
                
            <Grid container spacing={3}>
                    
                <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={()=>handleClose()}>Cancelar</Button>
                </Grid>
            </Grid>
                
        </React.Fragment>
    )
}