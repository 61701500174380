import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';
import { FileUpperModal } from "../Modal/FileUpperModal";

export function ButtonFileUpperForms(props){
    const {persona_id, name, handleChangeReserv, tipoDocumento, title, idrefDoc, nombredocumento}=props
    const [open, setOpen]= useState(false)
    const [docname, setDocName]= useState('')

    useEffect(()=>{
        setDocName(nombredocumento)
    },[nombredocumento])
    const handleClose=(idres, newname)=>{
        if(idres){
            var e={target:{value: idres, name: name, label: newname}}
            setDocName(newname)
            handleChangeReserv(e)
        }
        setOpen(false)

    }

    return(
        <React.Fragment>
            <FileUpperModal
                open={open}
                setOpen={setOpen}
                handleClose={handleClose}
                {...props}
            />
            <Button onClick={()=>setOpen(true)}>
                <UploadFileTwoToneIcon titleAccess={"SUBIR "+title}/>
            </Button>
            <TextField 
                disabled
                fullWidth
                variant="standard"
                value={docname ||('SUBIR ' +(title||'DOCUMENTO'))}
            />
        </React.Fragment>
    )
}