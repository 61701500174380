import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useId } from "react";
import {  ThemeProvider } from '@mui/material/styles';
import instance from '../../../utilities/Instance';
import Loader from '../../shared/layout/Loader';
import { AlertCodeInterpreter_M, AlertSimpleStandar, AlertCodeInterpreter_F, AlertCodeInterpreter_Catch } from '../../alerts';
import axios from 'axios';
import imgpg1 from '../../../assets/banner/1.jpg'
import imgpg2 from '../../../assets/banner/2.jpg'
import imgpg3 from '../../../assets/banner/3.jpg'
import imgpg4 from '../../../assets/banner/4.jpg'
import imgpg5 from '../../../assets/banner/5.jpg'
import imgpg6 from '../../../assets/banner/6.jpg'
import imgpg7 from '../../../assets/banner/7.jpg'
import imgpg8 from '../../../assets/banner/8.jpg'
import ChangePasswordModal from './ChangePassword';
import { Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function Copyright(props) {

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.sistemascontrol.ec/">
        Control Sistemas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function ChangePassword(props) {
  const {signed, tokenstate, theme} = props;
  const [onLoader,setOnLoader] = useState(false);
  const [open, setOpen]=useState(false);
  const [usuario_id, setUsuarioId]=useState(0);

  const handleClose=()=>{
    setOpen(false)
  }

  const handleOpen=()=>{
    setOpen(true)
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var toSend={
      correopersonal: data.get('correopersonal'),
    }
    if(!toSend.correopersonal){
      var info={
        content:"Formulario incompleto. Por favor, coloque el correo electrónico de su cuenta."
      }
      AlertSimpleStandar(info, 11);
      return;
    }
    setOnLoader(true);
    
    //await axios.postForm("http://localhost:8000/api-siscontrol-santamariana/public/Usuario/login", toSend)
    //await instance.postForm("factura/crear", toSend)
    await instance.postForm("Usuario/RequestChangePassword", toSend)
    .then(async (response)=>{
      setOnLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        setOpen(true)
        setUsuarioId(response.data.data.data)
      }else{
        setUsuarioId(0)
      }
    })
    .catch((error)=>{
      if(error?.response?.data?.error){
        AlertCodeInterpreter_Catch(error.response.data.error)
      }
      console.error(error)
    })


    setOnLoader(false);

  };

  /*const handleChange = (e)=>{
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }*/

  const colectionImg=[
    imgpg1,
    imgpg2,
    imgpg3,
    imgpg4,
    imgpg5,
    imgpg6,
    imgpg7,
    imgpg8
  ]

  function randomNumber(min, max) {
    return  Math.round(Math.random() * (max - min) + min);
  }

  const randomSelection=()=>{
    return colectionImg[randomNumber(0, colectionImg.length-1)]
  }

  return (
    <ThemeProvider theme={theme}>
      {<Loader onLoader={onLoader}/>}
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        >
        <Box sx={{ ...style, width: 400 }}>
          <ChangePasswordModal usuario_id={usuario_id} handleClose={handleClose} handleOpen={handleOpen} signed={signed} tokenstate={tokenstate} theme={theme}
            
          />
        </Box>
      </Modal>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${randomSelection()})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Solicitud de cambio de contraseña.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="correopersonal"
                autoComplete="email"
                autoFocus
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Solicitar cambio de contraseña
              </Button>
              <Grid container> 
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    ¿Olvidaste tu contraseña?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Link href="/" variant="body2">
                    {"¿Ya tienes cuenta? Ingresa."}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}