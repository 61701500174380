import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import instance from "../../../utilities/Instance";
import HowToRegTwoToneIcon from '@mui/icons-material/HowToRegTwoTone';
import { Personas2 } from "../../../structure/Tables";
import TablaGestion from "./Table";
import { isNumeric } from "../../../utilities/Utilities";
import { ModalsubPersonaCreator, ModalsubPersonaEditor } from "./ModalsubPersonaSelector";
import Loader2 from "./Loader2";
import ResponseCustomizedSnackbars, { CatchResponseCustomizedSnackbars } from "../notification/snackbar";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { AlertCodeInterpreter_Catch } from "../../alerts";

export default function DualContainedModal (props){
    const{ChildLeft, ChildRigth, setDataResponse, setCatchResponse, leftProps, rigthProps, handleClose, setLoader}=props

    
    return(
        <React.Fragment>
            
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    {ChildLeft && <ChildLeft
                    handleClose={handleClose}
                    setLoader={setLoader}
                    setDataResponse={setDataResponse} 
                    setCatchResponse={setCatchResponse}
                    {...leftProps}
                    />}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                    {ChildRigth && <ChildRigth
                        handleClose={handleClose}
                        setLoader={setLoader}
                        setDataResponse={setDataResponse} 
                        setCatchResponse={setCatchResponse}
                        {...rigthProps}
                    />}
                </Grid>
            </Grid>
        </React.Fragment>
    )
}