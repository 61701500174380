import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import userReducer from './userReducer';
import datosMaestrosReducer from './datosMaestrosReducer'

const rootReducer = combineReducers({
    loadingReducer,
    userReducer,
    datosMaestrosReducer
})

export default rootReducer