import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import logoMain from './../../assets/logo.png'
import instance from "../../utilities/Instance";
import PaisView from "./PaisView";
import { generarSelector } from "../../utilities/Utilities";
import EstadosView from "./EstadosView";
import { getFormatUp } from "../../structure/toUp";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F, Modal_PaisPreForm, Modal_SimplePreForm } from "../../components/alerts";
import { getEntityRoute } from "../../structure/toUp";


export default function Skeleton(props){
    const [actionsButtoms, setActionsButtoms]= useState([]);
    const [listPaises, setListPaises]= useState([]);
    const [listEstados, setListEstados]= useState([]);
    const [listCiudades, setListCiudades]= useState([]);
    const [SelectOption, setSelectOption] = useState([]);
    const [AditionalFields, setAditionalFields] =useState([]);
    const [datatable, setDataTable]= useState([]);
    const [contadorSecuencias, setContador]=useState(0);
    const [pais_id, setPaisId]= useState(0);
    const [estado_id, setEstadoId]= useState(0);
    const [title, setTitle]= useState("");
    const [backOption, setBackOption]= useState(null);
    const {setLoader} = props;


    const backToEstados= async ()=>{
      setDataTable(listEstados)
      setBackOption(null)
    }

    const selectPais =async(e)=>{
      setTitle("Lista de estados/provincias.")
      setBackOption(false)
        if(e>0){
          setLoader(true)
          await instance.get("Estado/ListAllByPaisId/"+e)
          .then(async (response)=>{
              var data=response.data.data ||[]
              var newData= []
              
              var newItem= contadorSecuencias
              data.forEach(async element => {
                newItem= newItem+1
                newData.push(await createRow(element, newItem))
              });
              setContador(newItem)
              setListEstados(newData)
              setDataTable(newData)
          }).catch(error=>{
            if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
            console.error(error)
          })
          setLoader(false)
        }
        setPaisId(e)
        
    }

    const selectEstado =(e)=>{
      setTitle("Lista de ciudades.")
        if(e>0){
          setLoader(true)
          instance.get("Ciudad/ListAllByEstadoId/"+e)
          .then(async (response)=>{
              var data=response.data.data ||[]
              var newData= []
              setListCiudades(response.data.data)
              var newItem= contadorSecuencias
              data.forEach(async element => {
                newItem= newItem+1
                newData.push(await createRow(element, newItem))
              });
              setContador(newItem)
              
              setDataTable(newData)
              setBackOption(true)
          }).catch((error)=>{
            if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
            console.error(error)
          })
        }
        setEstadoId(e)
        setLoader(false)
        
    }

    const ActionsHandle = async(e) =>{
      e.preventDefault();
      var opt = e.nativeEvent.submitter.value;
      var base ="Pais/";
      var requestType =true;
      var newdata={}
      switch (opt) {
        case "Create":
          opt="Create"
          requestType=true
          newdata= await Modal_PaisPreForm({title:"Ingrese nombre del pais."})

          break;
        case "update":
          opt="update/"+pais_id
          requestType=true
          newdata= await Modal_PaisPreForm({title:"Ingrese nuevo nombre del pais."})
          break;
        case "RemoveById":
          opt="RemoveById/"+pais_id
          requestType=false
          break;
        
        default:
          break;
      }
      setLoader(true);
      if(requestType){
        await instance.postForm(base+opt,newdata)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      }else{
        await instance.get(base+opt)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      }
      getData()
      setLoader(false);
    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        nombre: row?.nombre ||"",
        codigo: row?.codigo || "",
        nombreclatura: row?.nombreclatura ||"",
        pais_id:row?.pais_id ||"",
        estado_id:row?.estado_id ||"",
        estado: row?.estado ||"",
        fechacreacion:row?.fechacreacion ||"",

        }
        return insertEl
    }

    const actualizarRegistro = async (row, base) =>{
      setLoader(true);
      var res = 0;
      await instance.postForm(base,row)
      .then(async(response)=>{
        if(! await AlertCodeInterpreter_F(response.data.data)){
          setLoader(false);
          res= await response.data.data.data
          
        }
      }).catch(error =>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
      return res;
    }

    const borrarRegistro = async (row, backOption)=>{
      
      var opt="RemoveById/"+row.idreal;
      
      var base ="Ciudad/";
      if(!backOption){
        base="Estado/"
      }
      setLoader(true);
        await instance.get(base+opt)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            var indexItem = datatable.findIndex(element=>element.id===row.id)
            if(indexItem!==-1){
              var actualizarrow=[...datatable]

              actualizarrow.splice(indexItem, 1)
              setDataTable(actualizarrow)
            }
            setLoader(false);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      
      getData()
      setLoader(false);
    }

    const guardarRegistro = async (row, formatUp) =>{
      var base=getEntityRoute(formatUp) ||""
      
      var option=formatUp||""
      
      var toup=getFormatUp(row, option)
      if(row.idreal){
        //actualizar
        base=base+"update/"+row.idreal
        if(formatUp==="ciudad"){
          toup.estado_id=estado_id;
        }
        if(formatUp==="estado"){
          toup.pais_id=pais_id;
        }

      }else{
        //crear
        base=base+"Create"
        toup.pais_id=pais_id;
        toup.estado_id=estado_id;
      }
      var res=await actualizarRegistro(toup, base) 
      return res

    }

    const addRow = async(row) =>{
      var newItem= contadorSecuencias +1
      
      var aux= [...datatable]
      aux.push(await createRow(row, newItem))
      setContador(newItem)
      setDataTable(aux)
      //alert('accion')
    }
    const getData=async()=>{
      instance.get("Pais/ListAll")
      .then((response)=>{
        setListPaises(response.data.data);
        var list= response.data.data
        setSelectOption(generarSelector(list, "nombre", "id"))
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })

      

      var botones = []
      botones.push({icon:<AddLocationAltIcon titleAccess="Agregar Nuevo País" ></AddLocationAltIcon>,
                    value: "Create"})
      botones.push({icon: <EditLocationAltIcon titleAccess="Editar país"></EditLocationAltIcon>,
                    value: "update"})
      botones.push({icon: <WrongLocationIcon titleAccess="Eliminar país"></WrongLocationIcon>,
                    value: "RemoveById"})
      setActionsButtoms(botones)
    }

    useEffect(()=> {
      getData();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
  
  
    }, [])

    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
              {/* Selector de paises */}
              <Grid item xs={12} md={4} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: "center",
                  }}
                >
                  {<PaisView 
                    actionsButtoms={actionsButtoms} 
                    titleSelect= {"Tipo de registro"} 
                    SelectOption={SelectOption} 
                    handleOpenReporte={selectPais}
                    AditionalFields={AditionalFields}
                    ActionsHandle={ActionsHandle}
                    
                  />}
                </Paper>
              </Grid>
              {/* Lista provincias */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  { <EstadosView 
                      data={datatable} 
                      title={title}
                      addRow={addRow}
                      borrarRegistro={borrarRegistro}
                      guardarRegistro={guardarRegistro} 
                      verCiudades={selectEstado}
                      backOption={backOption}
                      backFunction={backToEstados}/> }
                  {/*<Chart />*/}
                </Paper>
              </Grid>
              {/* lista ciudades */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <img
                        src={logoMain}
                    >
                    </img>
                </Paper>
              </Grid>
            </Grid>
        </React.Fragment>
    )
}