import loadingActions from './loadingActions';
import userActions from './userActions';
import datosMaestrosAction from './datosMaestrosAction'

const allActions = {
    loadingActions,
    userActions,
    datosMaestrosAction
}

export default allActions;