import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import { IOSSwitch } from "./switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";
import PersonaDoubleSectionModal from "./PersonaDoubleSectionModal";

export default function PersonadOoubleSectionButton (params){
  const { nombre, Label}=params
  const [openPersona, setOpenPais]=useState(false)
  const [selectedPerson, setSelectedPerson]=useState(0)
  const [namePerson, setNamePerson]= useState()

  useEffect(()=>{
      setSelectedPerson(params.row[nombre])
      setNamePerson(params.row[Label])
  },[selectedPerson])
    
  const handleOpenPais=(name)=>{
    setOpenPais(true)
    params.row.openPersona=true
  }
  
  const handleClosePersona=(nombrecompleto, id)=>{
    if(id){
      params.row[nombre]=id
      params.row[Label]=nombrecompleto
      setNamePerson(nombrecompleto)
      setSelectedPerson(id)
    }
    setOpenPais(false)
  }
  
  return (
    <React.Fragment>
      <div className="cellAction">
        <PersonaDoubleSectionModal openPersona={openPersona} handleClosePais={handleClosePersona} />
        {params.row.edit?null:<IconButton aria-label="delete" size="large" onClick={()=>handleOpenPais("field.name")}>
          <PersonSearchTwoToneIcon value={"field.name"} fontSize="inherit" />
        </IconButton>}
        {namePerson}
      </div>
    </React.Fragment>  
  );
}
 

  /** el valor de ingreso es un array de json con valores {id, parentezco} */

  export function PersonadOoubleSectionButtonforForms (props){
    const { value, value_name, handleChange, title, setDataResponse, setCatchResponse}=props
    const [openPersona, setOpenPais]=useState(false)
    const [selectedPersons, setSelectedPersons]=useState(0)
    const [provitionalList, setProvitionalList]=useState([])

    useEffect(()=>{
        setSelectedPersons(value?.length||0)
        setProvitionalList(value||[])
    },[selectedPersons, value])
    
    const handleOpenPais=()=>{
      setOpenPais(true)
    }
  
    const handleClosePersona=(lista)=>{
      if(lista){
        handleChange(lista, value_name)
        setSelectedPersons(lista?.length||0)
      }
      setOpenPais(false)
    }

    const handleSelectPersona=(parentezco, id, aditional)=>{
      if(id){
        //return
        if(!aditional){
          aditional={}
        }
        if(provitionalList.find(persona=> persona.id===id)){
          //persona ya agregada a la lista
          return
        }
        var toAdd={id: id, parentezco: parentezco, ...aditional}
        //setProvitionalList.push(toAdd)
        setProvitionalList(ProvList=>ProvList.concat(toAdd))
        //handleChange(toAdd, value_name)
      }
      //setOpenPais(false)
    }

    const handleRemovePersona=(id)=>{
      if(id){
        var list=[...provitionalList]
        //var element= list.find(persona=>persona.id===id)
        var indextoSplice= list.findIndex(persona=>persona.id===id)
        list.splice(indextoSplice, 1)
        setProvitionalList(list)
      }
      //setOpenPais(false)
    }
  
    return (
      <React.Fragment>
        <div className="cellAction" style={{display:'flex', width:'100%'}} >
          <PersonaDoubleSectionModal provitionalList={provitionalList} handleSelectPersona={handleSelectPersona} handleRemovePersona={handleRemovePersona} setDataResponse={setDataResponse} setCatchResponse={setCatchResponse} openPersona={openPersona} handleClosePais={handleClosePersona} />
          {<IconButton aria-label="delete" size="large" onClick={handleOpenPais}>
            <PersonSearchTwoToneIcon value={"field.name"} fontSize="inherit" />
          </IconButton>}
          <TextField
            label={title}
            multiline
            variant="standard"
            maxRows={1}
            margin="normal"
            fullWidth
            value={'Contactos adicionales de emergencia: '+selectedPersons}

          />
          
        </div>
      </React.Fragment>  
    );
  } 