import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
import SelectCityModal from "./cityModal";

export function CountryAfterCity (params){
    const { nombre, Label}=params
    const {listPaises} =params.row
    const [selectedCountry, setSelectedCountry]=useState(0)
    const [nameCountry, setnameCountry]= useState('')

    useEffect(()=>{
        setSelectedCountry(params.row[nombre])
        if(listPaises){
            var NameOfCountry=listPaises.find((ciudad)=>ciudad.id===params.row[nombre])?.nombre
            setnameCountry(NameOfCountry)
            params.row[Label]=NameOfCountry
        }
    },[params.row[nombre]])
    
  
    return (
      <React.Fragment>
        <div className="cellAction">
          {nameCountry}
        </div>
      </React.Fragment>  
    );
  } 