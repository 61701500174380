import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Title from "./Title";
import DefaultProfile from "./../../../assets/ProfileDefault.png";
import Box from '@mui/material/Box';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { Select, MenuItem } from "@mui/material";


export default function ProfileBase(props){
    const {actionsButtoms, titleSelect, SelectOption, AditionalFields, ActionsHandle,idreferencia, handleOpenReporte, title, imageUp, imageBg} = props;


    return(
        <React.Fragment>
            <Title>{title||'Perfil'}</Title>
            <Avatar
                alt="Avatarimg"
                src={imageBg ||DefaultProfile}
                sx={{ width: 'auto', height: 'auto',  maxHeight:150,maxWidth:150 }}
            >
            </Avatar>
            {typeof imageUp==="function" && idreferencia?
            <label htmlFor="file">
                <DriveFolderUploadOutlinedIcon />
            </label>
            :null}
            <input
                        type="file"
                        id="file"
                        onChange={imageUp}
                        style={{ display: "none" }}
                      />
            <Box component="form" noValidate onSubmit={ActionsHandle} width={'100%'} sx={{ mt: 1, alignItems: "center", display:"flex", flexDirection:"column" }}>

            {AditionalFields}
            
            <Title>{titleSelect || ''}</Title>
            {SelectOption?.length>0 && <Select
                key={"SelectProfileBase"}
                defaultValue={''}
                fullWidth
                onChange={(e)=>handleOpenReporte? handleOpenReporte(e.target.value):null}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" key={"MenuItemProfileBaseA"}>
                        <em>--Seleccione una opción--</em>
                    </MenuItem>
                    {
                        SelectOption.map((option, index)=>(
                            <MenuItem key={"MenuItemProfileBase"+index} value={option.value}>{option.label}</MenuItem>
                        ))
                    }
            </Select>}
            

            <ButtonGroup variant="text" aria-label="text button group">
                {actionsButtoms?.length>0 && actionsButtoms?.map((boton, index)=>(
                    <Button 
                        key={"ButtonProfileBase"+index}
                        type="submit"
                        id={"boton1"}
                        name={boton.value}
                        value={boton.value}
                        //onClick={boton.action || null}
                        >
                    {boton.icon || ''}
                    </Button>))}

            </ButtonGroup>
            </Box>

        </React.Fragment>
    )
}