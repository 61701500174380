
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Title from "./Title";
import { Grid } from "@mui/material";
export default function TablaGestion (props) {
  const {botones, data, formato, titulo,sortModel,setSortModel, aditionalButton} = props;  

  return (
    <React.Fragment>
      <Grid container spacing={2} padding={2}>
        <Grid>
          <Title>{titulo}</Title>
        </Grid>
        {aditionalButton?<Grid>
          {aditionalButton}
        </Grid>:null}
      </Grid>
      
      <DataGrid
            disableColumnMenu
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            className="datagrid"
            sx={
              {
                '& div': {
                fontSize: 11,
                //height:"10px",
                },
                "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#49545C",
                color: "white",
                fontSize: 16,
                }
              }
            }
            headerHeight={30}
            rowHeight={45}
            rows={data}
            autoHeight
            columns={botones.concat(formato)}
            pageSize={9}
            rowsPerPageOptions={[9]}
        />
    </React.Fragment>
  );
};