const addProperty = (datos, property) => {
    return {
        type: "@property/add",
        payload: datos,
        prop: property
    }
}

const updateProperty = (datos, property, id, isObject = false) => {
    return {
        type: "@property/update",
        payload: datos,
        prop: property,
        id: id,
        isObject: isObject
    }
}

const resetProperties = () => {
    return {
        type: "@property/reset"
    }
}

export default { addProperty, updateProperty, resetProperties }