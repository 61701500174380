import * as React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Login from '../components/authentication/login/Login';
import Registro from '../components/authentication/register/Register';
import ChangePassword from '../components/authentication/requestChangePassword/RequestChangePassword';
export default function Outroutes(props){
    const {signed, tokenstate, theme} = props
    return(
        <>
        <Routes>
          <Route path="/" >
            <Route index element={<Login signed={signed} tokenstate= {tokenstate} theme={theme}/>} />
            <Route path='Registrate' element={<Registro signed={signed} tokenstate= {tokenstate} theme={theme}/>}/>
            <Route path='CambioDeContraseña' element={<ChangePassword signed={signed} tokenstate= {tokenstate} theme={theme}/>}/>
            
            
          </Route>
          <Route
              element={
                <Login signed={signed} tokenstate= {tokenstate} theme={theme}/>
              }
            />
        </Routes>
        </>
    )
}