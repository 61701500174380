import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonRemoveTwoToneIcon from '@mui/icons-material/PersonRemoveTwoTone';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import instance from "../../utilities/Instance";
import ProfileBase from "../../components/shared/layout/profileBaseSkeleton";
import TextField from '@mui/material/TextField';
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F , AlertCodeInterpreter_preForm} from "../../components/alerts";
import { generarForm, generarFormCompleto, isNumeric, selectForm } from "../../utilities/Utilities";
import { getFormatUp } from "../../structure/toUp";
import logoMain from './../../assets/logo.png'
import PersonasView from "./PersonasView";
import FormsEdit from "../../components/shared/layout/Forms";
import ResponseCustomizedSnackbars, { CatchResponseCustomizedSnackbars } from "../../components/shared/notification/snackbar";


export default function SkeletonPersona(props){
    const [datatable, setDataTable]= useState([]);
    const [actionsButtoms, setActionsButtoms]= useState([]);
    const [AditionalFields, setAditionalFields] =useState([]);
    const [formDataSearch, setFormDataSearch]= useState([]);
    const [formDataSelect, setFormDataSelect]= useState([]);
    const [formDataDate, setFormDataDate]= useState([]);
    const [formDataToggleSwitch, setFormDataToggleSwitch]= useState([]);
    const [formDataModalSearch, setFormDataModalSearch]= useState([]);
    const [theTitle, setTheTitle]=useState('Perfil');
    const [persona_id, setPersonaId]=useState(null)
    const [contadorSecuencias, setContador]=useState(0)
    const [title, setTitle]= useState("");
    const [rules,setRules]= useState([]);
    const [listCatalogos,setListCatalogos]= useState([]);
    const [selectListOptions, setSelectListOptions]= useState({})
    const [listPaises, setListPaises]= useState([]);
    const [listEstados, setListEstados]= useState([]);
    const [listCiudades, setListCiudades]= useState([]);
    const [datosPersona, setDatosPersona]= useState({});
    const [filter, setFilters] = useState({
      f_hermanas:false,
      f_SNID: false
    })

    
    const [dataresponse,setDataResponse]=useState(null)
    const [validatorDataresponse,setValidatorDataResponse]=useState(null)
    const [catchResponse,setCatchResponse]=useState(null)
    const [validatorCatchresponse,setValidatorCatchResponse]=useState(null)
    const [file, setFile] = useState("");
    
    const {setLoader} = props;
    
    const DataResponseValid=(value)=>{
      setDataResponse(value)
      setValidatorDataResponse(!validatorDataresponse)
    }

    const CatchResponseValid=(value)=>{
      setCatchResponse(value)
      setValidatorCatchResponse(!validatorCatchresponse)
    }

    const actualizarRegistro = async (row, base) =>{
      setLoader(true);
      await instance.postForm(base,row)
      .then(async(response)=>{
        setLoader(false);
        if(! await AlertCodeInterpreter_F(response.data.data)){

        }
      }).catch(error =>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
        setLoader(false);
      })
    }

    const guardarRegistro = async (row) =>{
      var base="Persona/update/"+persona_id
      //var toup=getFormatUp(row, option)
      if(persona_id){
        if(file){
          row.foto=file;
        }
      }
      
      actualizarRegistro(row, base)

    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        numeroidentificacion: row?.numeroidentificacion ||"",
        apellidos: row?.apellidos ||"",
        nombres: row?.nombres ||"",
        nombrecompleto:row?.nombrecompleto ||"",
        telefono:row?.telefono ||"",
        telefonocelular:row?.telefonocelular || "",
        lugarnacimiento:row?.lugarnacimiento ||"",
        ciudadnatalidad_id:row?.ciudadnatalidad_id||"",
        ciudadnatalidad_detalle:row?.ciudadnatalidad_detalle||"",
        fechanacimiento:row?.fechanacimiento||"",
        ciudadresidencia_id:row?.ciudadresidencia_id||"",
        ciudadresidencia_detalle:row?.ciudadresidencia_detalle||"",
        correopersonal:row?.correopersonal||"",
        correointitucional:row?.correointitucional||"",
        ct_tipoidentificacion_id:row?.ct_tipoidentificacion_id||"",
        ct_tipoidentificacion_detalle:row?.ct_tipoidentificacion_detalle||"",
        ct_estadocivil:row?.ct_estadocivil||"",
        ct_estadocivil_detalle:row?.ct_estadocivil_detalle||"",
        ct_sexo:row?.ct_sexo||"",
        ct_sexo_detalle:row?.ct_sexo_detalle||"",
        discapacidad:row?.discapacidad||false,
        extranjero:row?.extranjero||false,
        observacion:row?.observacion||"",
        estado:row?.estado||false,
        fechacreacion:row?.fechacreacion||"",
        fechamodificacion:row?.fechamodificacion||"",
        direccion:row?.direccion||""
      }
        return insertEl
    }

    const addRow = async(row) =>{
      var newItem= contadorSecuencias +1
      
      var aux= [...datatable]
      aux.push(await createRow(row, newItem))
      setContador(newItem)
      setDataTable(aux)
    }


    const SearchId= async(e)=>{
      setLoader(true);
      await instance.get("Persona/FindByIdPerfilComplete/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        await setterSearch(response)
      }else{
        setPersonaId(0)
        resetAll()
        
        //setDataTable([])
      }
      
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
    }

    const SearchCedula= async(e)=>{
      setLoader(true);
      await instance.get("Persona/FindByIdentidadPerfilComplete/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        await setterSearch(response)
      }else{
        setPersonaId(0)
        resetAll()
        
        //setDataTable([])
      }
      
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
    }

    const setterSearch= async(response)=>{
      var datosPersona= response.data.data.data;
      if(Array.isArray(datosPersona)){
        var datosform=[]
        var nombres
        var newItem= contadorSecuencias
        datosPersona.forEach(async element => {
          newItem= newItem+1
          /*datosform.push({
            ...(generarForm({"numeroidentificacion":element.numeroidentificacion}))[0], 
          nombrecompleto: element.nombrecompleto})*/
          datosform.push(await createRow(element, newItem))
        });

        setDataTable(datosform)
        setContador(newItem)
        response.data.data.form =datosform
        /*var selected= await AlertCodeInterpreter_preForm(response.data.data)
        if(selected){
          SearchCedula(selected)
        }*/
        setPersonaId(0)
      }else{
        if(!datosPersona){
          datosPersona={}
        }
        var idp =datosPersona?.id
        setPersonaId(idp)
        var newItem= contadorSecuencias
        var datosform=[]
        newItem= newItem+1
        datosform.push(await createRow(datosPersona, newItem))
        setContador(newItem)
        //setDataTable([])
        delete datosPersona?.id;
        setDataTable(datosform)
        
        var datosform=generarForm(datosPersona);
        var datosprueba= generarFormCompleto(datosPersona);
        var dictionaryRuleCatalogo=rules.find((rule)=>rule.nombre==="Catalogo parent")?.configuracion
        datosprueba.selectfield.forEach(element => {
          element.padre=dictionaryRuleCatalogo[element.paridad.name]
          element.padrefield="catalogo_id"
        });
        
        var generalform={}
        await setFormDataSearch(datosprueba.textfield || []);
        
        await setFormDataSelect(datosprueba.selectfield || []);
        await setFormDataDate(datosprueba.DateTimeField || []);
        await setFormDataToggleSwitch(datosprueba.ToggleSwitchField || []);
        await setFormDataModalSearch(datosprueba.modalSearch || [])

        datosform.forEach(element => {
          generalform={...generalform, [element.name]:element.value}
        });
        await setDatosPersona(generalform)
        setFile(response.data.data?.data?.foto ||'')
        
      }
    }

    const resetAll=()=>{
      setFile('')
      setFormDataSearch([]);
        
      setFormDataSelect( []);
      setFormDataDate( []);
      setFormDataToggleSwitch( []);
      setFormDataModalSearch( [])
    }

    const SearchName= async(e)=>{
      setLoader(true);
      await instance.get("Persona/FindByNombrePerfilComplete/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        
        await setterSearch(response)

      }else{
        setPersonaId(0)
        resetAll()
        //setDataTable([])
      }
      
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
    } 

    const openEdit = async(e)=>{
      if(e.numeroidentificacion){
        SearchCedula(e.numeroidentificacion)
      }else{
        if(e.idreal){
          SearchId(e.idreal)
        }
      }

    }

    const handleChange = (e) =>{
      var datosPers= {...datosPersona}
      datosPers[e.target.name]=e.target.value
      setDatosPersona(datosPers)
    }

    const handleSelect = (e) =>{
      var datosPers= {...datosPersona}
      /* datosPers[e.target.name]=e.target.value
      setDatosPersona(datosPers) */
    }


    const ActionsHandle = async(e) =>{
      e.preventDefault();
      var opt = e.nativeEvent.submitter.value;
      
      const data = new FormData(e.currentTarget);
      var toSend={
        busqueda: data.get('busqueda')
      }
      if(!toSend.busqueda?.length){
        getDataAll()
        return
      }
      if( isNumeric(toSend.busqueda)){
        opt = "cedula"
      }else{{
        opt = "nombre"
      }}
      if(opt==='cedula'){
        await SearchCedula(toSend.busqueda)
      }else
      if(opt==='nombre'){
        SearchName(toSend.busqueda)
      }
    }

    const SubmitHandle = async(e) =>{
      e.preventDefault();
      
      const data = new FormData(e.currentTarget);
      var toSend={}
      formDataSearch.forEach(element => {
        toSend[element.name]=data.get(element.name)
      });

      toSend={...datosPersona}
      
      guardarRegistro(toSend)
    }

    const getDataAll= async(e)=>{
      setLoader(true)
      await instance.get("Persona/ListAll")
      .then(async(response)=>{
          var para=[]
          if( response.data.data.length>0){
            var registros= response.data.data;
            var newItem= contadorSecuencias
            registros.forEach(async (element) => {
              newItem= newItem+1
              para.push(await createRow(element, newItem))
            });
            setContador(newItem)
            setDataTable(para)
          }
        })
        .catch((error)=>{
          if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
            console.error(error)
        })
        setLoader(false)
  }

    const getData=async()=>{
      var botones = []
      botones.push({icon:<BadgeTwoToneIcon titleAccess="Nùmero de identidad" />,
                    value: "cedula"})
      botones.push({icon: <PersonSearchTwoToneIcon titleAccess="Nombre" />,
                    value: "nombre"})
      setActionsButtoms(botones)
      
      
      /* var botonesForm = []
      botonesForm.push({icon:<BadgeTwoToneIcon titleAccess="Guardar" />,
                    value: "update"})
      botonesForm.push({icon: <PersonSearchTwoToneIcon titleAccess="Crear" />,
                    value: "Create"})
       */
      var AdicionalFields = (
        <React.Fragment>
          <TextField
                margin="normal"
                required
                fullWidth
                id="busqueda"
                label="Buscar"
                name="busqueda"
                autoComplete="busqueda"
                autoFocus
              />
        </React.Fragment>
          
      )
      
      setAditionalFields(AdicionalFields)

      var listaOpciones={}
      await instance.get("DataMaster/getAllSelectors")
      .then(async response=>{
        listaOpciones.detallecatalogo=response?.data?.data?.DetalleCatalogos ||[]
        listaOpciones.listCiudades=response?.data?.data?.Ciudades ||[]
        setSelectListOptions(listaOpciones)
        setListPaises(response?.data?.data?.Paises ||[])
        setListEstados(response?.data?.data?.Estados ||[])
        setListCiudades(response?.data?.data?.Ciudades ||[])
        setListCatalogos(response?.data?.data?.Catalogos ||[])
        setRules(response?.data?.data?.Config ||[])
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
    }

    useEffect(()=> {
      getData();
      getDataAll();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
    }, [])

    const imageUp= (e) => {
      if(!persona_id){
        setFile({})
        return
      }
      //setTheTitle(getBase64(e.target.files[0]))
      var reader = new FileReader();
      var fname=e.target.files[0];
      
      //setFile(e.target.files[0])
      if(e.target.files[0].type==="image/png" || e.target.files[0].type==="image/jpg" || e.target.files[0].type==="image/jpeg"){
        if(e.target.files[0].size<=1000000){
          reader.onloadend = function() {
            setFile( reader.result)
          }
          reader.readAsDataURL(fname);
           AlertCodeInterpreter_F({error:true, codecase: 11, title: 'Archivo muy pesado', content: 'Asegúrate de subir un archivo con peso menor a 1Mb'})

          
          
        }else{
          reader.onloadend = function() {
            setFile( reader.result)
          }
          reader.readAsDataURL(fname);
        }
        
      }else{
        //warningm('Estimado usuario. El tipo de archivo que intenta subir no es el adecuado. Por favor, ingrese archivos de extensión ".png", ".jpg" y ".jpeg".')
      }
          /* setFile(getBase64(e.target.files[0]))
          console.log(e.target.files[0]) */
      
    }

    
    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
            <ResponseCustomizedSnackbars dataresponse={dataresponse} validator={ validatorDataresponse}/>
            <CatchResponseCustomizedSnackbars dataresponse={catchResponse} validator={validatorCatchresponse}/>
            
              {/* Perfil Search y options */}
              <Grid item xs={12} md={4} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: "center",
                  }}
                >
                  {<ProfileBase 
                    idreferencia={persona_id}
                    title={theTitle}
                    actionsButtoms={actionsButtoms} 
                    titleSelect= {"Buscar por:"} 
                    imageUp={imageUp}
                    imageBg={file}
                    //SelectOption={SelectOption} 
                    //handleOpenReporte={handleOpenReporte}
                    AditionalFields={AditionalFields}
                    ActionsHandle={ActionsHandle}  
                  />}
                </Paper>
              </Grid>
              {/* Perfil datos */}
              <Grid item xs={12} md={8} lg={9}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <PersonasView
                    filters={filter}
                    setFilters={setFilters}
                    openEdit={openEdit}
                    getData={getDataAll}
                    setDataResponse={DataResponseValid} setCatchResponse={CatchResponseValid}
                    data={datatable} 
                    title={title}
                    addRow={addRow} 
                    //backOption={backOption}
                    //backFunction={backToEstados}
                    /> 
                    
                </Paper>
              </Grid>
              {/* Tablas */}
              <Grid item xs={12}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {(persona_id?true:false) &&<FormsEdit textFields={formDataSearch}
                    tipoDocumento={[{id:61, title: 'CÉDULA', name: 'ceduladoc_id', fromText:'cedulanombre'}, {id:62, title: 'PASAPORTE',name: 'pasaportedoc_id', fromText:'pasaportenombre'}]}
                    idreferencia={persona_id}
                    specialRules={rules}
                    DateFields={formDataDate}
                    selectFields={formDataSelect} 
                    toggleSwitchFields={formDataToggleSwitch} 
                    ModalSearchFields={formDataModalSearch}
                    handleChange={handleChange} 
                    formData={datosPersona}
                    SubmitHandle={SubmitHandle}
                    handleSelect={handleSelect}
                    selectListOptions={selectListOptions}
                    listPaises={listPaises}
                    listEstados={listEstados}
                    listCiudades={listCiudades}
                    />}
                  {/*<Chart />*/}
                </Paper>
                
              </Grid>
            </Grid>
        </React.Fragment>
    )
}