import React, { useEffect, useState } from "react";
import Title from "./Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "./ModalCiudadSelector";
import SelectCityModal from "../Litles/cityModal";
import { PersonaButtonforForms } from "../Litles/PersonaButton";
import {InputLabel, FormControl} from "@mui/material";
import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import Pagination from '@mui/material/Pagination';

function ButtonAction(props){
    const {Icon, action}=props
    return (
        <React.Fragment>
            <div onClick={action} className="BotonAGris" style={{display:'flex', alignContent:'center', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                <Icon  />
            </div>
        </React.Fragment>
    )
}

export default function CarouselModelPages(props){
    const {arrChildrens, title, usuario_id}=props;
    const [page, setPage]=useState(0)
    const [rangePage, setRangePage]= useState(0)
    const [ChildrenPage, setChildrenPage]= useState(null)

    const goback = () =>{
        var newpage=0
        if(page-1<0){
            var newpage=rangePage-1
            setPage(rangePage-1)
        }else{
            var newpage=page-1
            setPage(page-1)
        }
        setChildrenPage(arrChildrens[newpage])
    }

    const paginatorCapture=(event, value) => {
        setPage(value-1);
        setChildrenPage(arrChildrens[value-1])
      };

    const gonext = () =>{
        var newpage=0
        if(page+1>=rangePage){
            var newpage=0
            setPage(0)
        }else{
            var newpage=page+1
            setPage(page+1)
        }
        setChildrenPage(arrChildrens[newpage])
    }

    useEffect(()=>{
        setRangePage(arrChildrens?.length || 0)
        if(arrChildrens?.length){
            setChildrenPage(arrChildrens[page])
        }
    },[usuario_id, arrChildrens, rangePage])

    return(
        <React.Fragment>
            <Box noValidate width={'100%'} sx={{ mt: 1 }}>
                <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12}>
                    <Title>{title || "Ubicar título"}</Title>
                </Grid>
                </Grid>
                <Grid container spacing={3} >
                <Grid item xs={1} md={1} lg={1}>
                    <ButtonAction Icon={ArrowBackIosNewTwoToneIcon} action={goback}/>
                </Grid>
                
                <Grid item xs={10} md={10} lg={10}>
                    <div style={{ overflow:'scroll', height:'400px'}}>
                        {ChildrenPage}
                    </div>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <ButtonAction Icon={ArrowForwardIosTwoToneIcon} action={gonext}/>
                </Grid>
                </Grid>
                <Grid container spacing={3} >
                    <Grid item xs={12} md={12} lg={12} sx={{display:"flex", justifyContent:'center'}}>
                        <Pagination count={rangePage} page={page+1} shape="rounded" color="primary" onChange={paginatorCapture} />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}