import * as React from 'react';
import { Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PublicIcon from '@mui/icons-material/Public';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import DataObjectTwoToneIcon from '@mui/icons-material/DataObjectTwoTone';

const colorMenuClicked= '#d0d0d0'

export function MainListItems (props){
  const {menu_id}=props
  const [menus, setMenus]=React.useState([])


  React.useEffect(()=>{
    setMenus(localStorage.getItem("permisos")?.split('_'))
  },[])

  return(
    <React.Fragment>
      {(menus?.find(element=>element==="1")?true:false) && <Link to="/" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===1?colorMenuClicked:'')}}>
        <ListItemIcon>
          <HomeIcon titleAccess='Inicio' color={menu_id===1?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItemButton>
      </Link>}
  
      {(menus?.find(element=>element==="2")?true:false) && <Link to="/Registros" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===2?colorMenuClicked:'')}}>
        <ListItemIcon>
          <AssignmentIcon titleAccess='Registros y reportes' color={menu_id===2?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Registros y reportes" />
      </ListItemButton>
      </Link>}

      {(menus?.find(element=>element==="7")?true:false) && <Link to="/Personas" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===7?colorMenuClicked:'')}}>
        <ListItemIcon>
          <GroupIcon titleAccess='Datos Personas' color={menu_id===7?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Datos Personas"/>
      </ListItemButton>
      </Link>}
  
    </React.Fragment>
  );
} 



export function SecondaryListItems(props) {
  const {menu_id}=props
  const [menus, setMenus]=React.useState([])


  React.useEffect(()=>{
    setMenus(localStorage.getItem("permisos")?.split('_'))
  },[])
  return(
    <React.Fragment>
      <ListSubheader component="div" inset>
        Administración
      </ListSubheader>

      {(menus?.find(element=>element==="4")?true:false) && <Link to="/Administracion/Ciudades" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===4?colorMenuClicked:'')}}>
        <ListItemIcon>
          <PublicIcon titleAccess='Datos geográficos' color={menu_id===4?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Datos geográficos" />
      </ListItemButton>
      </Link>}

      {(menus?.find(element=>element==="5")?true:false) && <Link to="/Administracion/Catalogo" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===5?colorMenuClicked:'')}}>
        <ListItemIcon>
          <FolderCopyIcon titleAccess='Catálogos y detalles' color={menu_id===5?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Catálogos y detalles" />
      </ListItemButton>
      </Link>}

      {(menus?.find(element=>element==="6")?true:false) && <Link to="/Administracion/Usuarios" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===6?colorMenuClicked:'')}}>
        <ListItemIcon>
          <RecentActorsIcon titleAccess='Datos de usuario' color={menu_id===6?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Datos de Usuarios" />
      </ListItemButton>
      </Link>}

      {(menus?.find(element=>element==="8")?true:false) && <Link to="/Administracion/Formatos" style={{textDecoration: "none", color: 'gray'}}>
      <ListItemButton style={{background:(menu_id===8?colorMenuClicked:'')}}>
        <ListItemIcon>
          <DataObjectTwoToneIcon titleAccess='Datos de usuario' color={menu_id===8?'primary':''}/>
        </ListItemIcon>
        <ListItemText primary="Formatos impresion" />
      </ListItemButton>
      </Link>}

      <ListItemButton onClick={(e)=>{localStorage.clear(); window.location.assign('/')}} style={{textDecoration: "none", color: 'gray'}}>
        <ListItemIcon>
          <DoorBackIcon titleAccess='Cerrar sesión' color={'primary'}/>
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión"/>
      </ListItemButton>

    </React.Fragment>
  );
}