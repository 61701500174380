import React from "react";
import { useState } from "react";
import TablaGestion from "../../components/shared/layout/Table";
import { Estados } from "../../structure/Tables";
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';

export default function EstadosView(props){
    const {data, addRow, handleEditar,guardarRegistro, borrarRegistro, verCiudades, backOption,backFunction, title}=props;
    const [listaRegistro,setListaRegistros]=useState([])

    const [sortModel, setSortModel] = React.useState([
        {
          field: 'nombre',
          sort: 'asc',
        },
      ]);

    const botonesAccion = [
        {
        field: "action",
        headerName: <div  style={{marginBottom:"-10px"}}><AddTwoToneIcon titleAccess="Agregar" onClick={(e)=>addRow()}/></div>,
        width: 100,
        //width: 400,
        
        renderCell: (params) => {
            return (
            <div className="cellAction" style={{ display:"flex"}}>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} onClick={async(e)=>{
                if(!params.row.edit){params.row.idreal=await guardarRegistro(params.row, (backOption?"ciudad" : "estado"))}; params.row.edit= !params.row.edit
                }} >{params.row.edit?<EditTwoToneIcon titleAccess="Editar" />: <SaveTwoToneIcon titleAccess="Guardar"/> }
                </div>
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    borrarRegistro? borrarRegistro(params.row, backOption):e.preventDefault()
                }} ><DeleteTwoToneIcon titleAccess={backOption?"Eliminar ciudad de la lista":"Eliminar Estado/provincia de la lista"}/>
                </div>
                {!backOption?
                <div className="viewButton" style={{color:"#d7a200", border:"none"}} 
                onClick={(e)=>{
                    verCiudades? verCiudades(params.row.idreal):e.preventDefault()
                }} ><TravelExploreTwoToneIcon titleAccess="Consultar ciudades"/>
                </div>:null}
            </div>
            );
        },
        },
    ];

    return(
        <React.Fragment>
            <TablaGestion 
                botones ={botonesAccion}
                data={data} 
                sortModel={sortModel} 
                setSortModel={setSortModel} 
                formato = {Estados} 
                titulo={<>
            {backOption?<KeyboardBackspaceTwoToneIcon onClick={backFunction} sx={{cursor:"pointer"}}/>:null}
            {title}</>} 
            />
        </React.Fragment>
    )
}
