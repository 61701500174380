import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import CreateNewFolderTwoToneIcon from '@mui/icons-material/CreateNewFolderTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FolderDeleteTwoToneIcon from '@mui/icons-material/FolderDeleteTwoTone';

import logoMain from './../../assets/logo.png'
import instance from "../../utilities/Instance";
import CatalogosView from "./CatalogosView";
import { generarSelector } from "../../utilities/Utilities";
import DetalleView from "./DetalleView";
import { getFormatUp } from "../../structure/toUp";
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F, Modal_SimplePreForm } from "../../components/alerts";
import { getEntityRoute } from "../../structure/toUp";


export default function Skeleton(props){
    const [actionsButtoms, setActionsButtoms]= useState([]);
    const [listPaises, setListPaises]= useState([]);
    const [listEstados, setListEstados]= useState([]);
    const [listCiudades, setListCiudades]= useState([]);
    const [SelectOption, setSelectOption] = useState([]);
    const [AditionalFields, setAditionalFields] =useState([]);
    const [datatable, setDataTable]= useState([]);
    const [contadorSecuencias, setContador]=useState(0);
    const [catalogo_id, setCatalogoId]= useState(0);
    const [estado_id, setEstadoId]= useState(0);
    const [title, setTitle]= useState("");
    const [backOption, setBackOption]= useState(null);
    const {setLoader} = props;


    const backToCatalogo= async ()=>{
      setDataTable(listEstados)
      setBackOption(null)
    }

    const selectCatalogo =async(e)=>{
      setTitle("Detalles.")
        if(e>-1){
          setLoader(true)
          await instance.get("detallecatalogo/child/"+e)
          .then(async (response)=>{
              var data=response.data.data ||[]
              var newData= []
              
              var newItem= contadorSecuencias
              data.forEach(async element => {
                newItem= newItem+1
                newData.push(await createRow(element, newItem))
              });
              setContador(newItem)
              setListEstados(newData)
              setDataTable(newData)
          }).catch(error=>{
            if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
          })
          setLoader(false)
        }
        setCatalogoId(e)
        
    }

    const selectEstado =(e)=>{
      setTitle("Detalles subcatálogos.")
        if(e>0){
          setLoader(true)
          instance.get("Ciudad/ListAllByEstadoId/"+e)
          .then(async (response)=>{
              var data=response.data.data ||[]
              var newData= []
              setListCiudades(response.data.data)
              var newItem= contadorSecuencias
              data.forEach(async element => {
                newItem= newItem+1
                newData.push(await createRow(element, newItem))
              });
              setContador(newItem)
              
              setDataTable(newData)
              setBackOption(true)
          })
          .catch((error)=>{
            if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
            console.error(error)
          })
        }
        setEstadoId(e)
        setLoader(false)
        
    }

    const ActionsHandle = async(e) =>{
      e.preventDefault();
      var opt = e.nativeEvent.submitter.value;
      var base ="catalogo/";
      var requestType =true;
      var newdata={}
      switch (opt) {
        case "Create":
          opt="add"
          requestType=true
          newdata= {"descripcion": await Modal_SimplePreForm({title:"Ingrese nombre del catálogo."})}

          break;
        case "update":
          opt="update/"+catalogo_id
          requestType=true
          newdata= {"descripcion": await Modal_SimplePreForm({title:"Ingrese nuevo nombre del catálogo."})}
          break;
        case "RemoveById":
          opt="delete/"+catalogo_id
          requestType=false
          break;
        
        default:
          break;
      }
      setLoader(true);
      if(requestType){
        await instance.postForm(base+opt,newdata)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      }else{
        await instance.get(base+opt)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
      }
      getData()
      setLoader(false);
    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        descripcion: row?.descripcion ||"",
        valor: row?.valor || "",
        ordenvisualizacion: row?.ordenvisualizacion ||"",
        detallecatalogo_id:row?.detallecatalogo_id ||"",
        catalogo_id:row?.catalogo_id ||"",
        estado: row?.estado ||"",
        fechacreacion:row?.fechacreacion ||"",

        }
        return insertEl
    }

    const actualizarRegistro = async (row, base) =>{
      setLoader(true);
      var res = 0;
      await instance.postForm(base,row)
      .then(async(response)=>{
        if(! await AlertCodeInterpreter_F(response.data.data)){
          setLoader(false);
          res= await response.data.data.data
          
        }
      }).catch(error =>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);
      return res;
    }

    const guardarRegistro = async (row, formatUp) =>{
      var base=getEntityRoute(formatUp) ||""
      
      var option=formatUp||""
      
      var toup=getFormatUp(row, option)
      if(formatUp==="detallecatalogo"){
        toup.catalogoId=catalogo_id;
      }
      if(row.idreal){
        //actualizar
        base=base+"update/"+row.idreal
      }else{
        //crear
        base=base+"add"
      }
      var res=await actualizarRegistro(toup, base) 
      return res

    }

    const borrarRegistro = async (id)=>{
      if(id>0){
        instance.get("detallecatalogo/delete/"+id)
        .then(async(response)=>{
          if(! await AlertCodeInterpreter_F(response.data.data)){
            setLoader(false);
            var dataaux=datatable.filter(row=>row.idreal!==id)
            setDataTable(dataaux);
          }
        }).catch(error =>{
          if(error?.response?.data?.error){
            AlertCodeInterpreter_Catch(error.response.data.error)
          }
          console.error(error)
        })
        setLoader(false);
      }
    }

    const addRow = async(row) =>{
      var newItem= contadorSecuencias +1
      
      var aux= [...datatable]
      aux.push(await createRow(row, newItem))
      setContador(newItem)
      setDataTable(aux)
      //alert('accion')
    }
    const getData=async()=>{
      instance.get("catalogo")
      .then((response)=>{
        setListPaises(response.data.data);
        var list= response.data.data
        setSelectOption(generarSelector(list, "descripcion", "id"))
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })

      var botones = []
      botones.push({icon:<CreateNewFolderTwoToneIcon titleAccess="Agregar Nuevo Catálogo" ></CreateNewFolderTwoToneIcon>,
                    value: "Create"})
      botones.push({icon: <EditTwoToneIcon titleAccess="Editar Catálogo"></EditTwoToneIcon>,
                    value: "update"})
      botones.push({icon: <FolderDeleteTwoToneIcon titleAccess="Eliminar Catálogo"></FolderDeleteTwoToneIcon>,
                    value: "RemoveById"})
      setActionsButtoms(botones)
    }

    useEffect(()=> {
      getData();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
  
  
    }, [])

    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
              {/* Selector de paises */}
              <Grid item xs={12} md={4} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: "center",
                  }}
                >
                  {<CatalogosView 
                    actionsButtoms={actionsButtoms} 
                    titleSelect= {"Catálogos disponibles"} 
                    SelectOption={SelectOption} 
                    handleOpenReporte={selectCatalogo}
                    AditionalFields={AditionalFields}
                    ActionsHandle={ActionsHandle}
                    
                  />}
                </Paper>
              </Grid>
              {/* Lista provincias */}
              <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  { <DetalleView 
                      data={datatable} 
                      title={title}
                      addRow={addRow} 
                      guardarRegistro={guardarRegistro}
                      borrarRegistro={borrarRegistro}
                      verCiudades={selectEstado}
                      backOption={backOption}
                      backFunction={backToCatalogo}/> }
                  {/*<Chart />*/}
                </Paper>
              </Grid>
              {/* lista ciudades */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <img
                        src={logoMain}
                    >
                    </img>
                </Paper>
              </Grid>
            </Grid>
        </React.Fragment>
    )
}