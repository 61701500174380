import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonRemoveTwoToneIcon from '@mui/icons-material/PersonRemoveTwoTone';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import instance from "../../utilities/Instance";
import ProfileBase from "../../components/shared/layout/profileBaseSkeleton";
import TextField from '@mui/material/TextField';
import { AlertCodeInterpreter_Catch, AlertCodeInterpreter_F , AlertCodeInterpreter_preForm} from "../../components/alerts";
import { generarForm, generarFormCompleto, isNumeric, selectForm } from "../../utilities/Utilities";
import { getFormatUp } from "../../structure/toUp";
import logoMain from './../../assets/logo.png'
import PersonasView from "./PersonasView";
import FormsEdit from "../../components/shared/layout/Forms";


export default function SkeletonPersona(props){
    const [datatable, setDataTable]= useState([]);
    const [actionsButtoms, setActionsButtoms]= useState([]);
    const [AditionalFields, setAditionalFields] =useState([]);
    const [formDataSearch, setFormDataSearch]= useState([]);
    const [formDataSelect, setFormDataSelect]= useState([]);
    const [formDataDate, setFormDataDate]= useState([]);
    const [formDataToggleSwitch, setFormDataToggleSwitch]= useState([]);
    const [formDataModalSearch, setFormDataModalSearch]= useState([]);
    
    const [usuario_id, setUsuarioId]=useState(null)
    const [contadorSecuencias, setContador]=useState(0)
    const [rules,setRules]= useState([]);
    const [listCatalogos,setListCatalogos]= useState([]);
    const [selectListOptions, setSelectListOptions]= useState({})
    const [listPaises, setListPaises]= useState([]);
    const [listEstados, setListEstados]= useState([]);
    const [listCiudades, setListCiudades]= useState([]);
    const [datosPersona, setDatosPersona]= useState({});
    const [foto, setFile]=useState('');

    const {setLoader} = props;




    

    const actualizarRegistro = async (row, base) =>{
      setLoader(true);
      await instance.postForm(base,row)
      .then(async(response)=>{
        setLoader(false);
        if(! await AlertCodeInterpreter_F(response.data.data)){

        }
      }).catch(error =>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
        setLoader(false);
      })
    }

    const guardarRegistro = async (row) =>{
      var base="Usuario/update/"+usuario_id
      //var toup=getFormatUp(row, option)
      
      actualizarRegistro(row, base)

    }

    const createRow = async (row, newItem)=>{
      var insertEl={
        id: newItem || "",
        idreal: row?.id || null,
        numeroidentificacion: row?.numeroidentificacion ||"",
        apellidos: row?.apellidos ||"",
        nombres: row?.nombres ||"",
        nombrecompleto:row?.nombrecompleto ||"",
        telefono:row?.telefono ||"",
        telefonocelular:row?.telefonocelular || "",
        lugarnacimiento:row?.lugarnacimiento ||"",
        ciudadnatalidad_id:row?.ciudadnatalidad_id||"",
        ciudadnatalidad_detalle:row?.ciudadnatalidad_detalle||"",
        fechanacimiento:row?.fechanacimiento||"",
        ciudadresidencia_id:row?.ciudadresidencia_id||"",
        ciudadresidencia_detalle:row?.ciudadresidencia_detalle||"",
        correopersonal:row?.correopersonal||"",
        correointitucional:row?.correointitucional||"",
        ct_tipoidentificacion_id:row?.ct_tipoidentificacion_id||"",
        ct_tipoidentificacion_detalle:row?.ct_tipoidentificacion_detalle||"",
        ct_estadocivil:row?.ct_estadocivil||"",
        ct_estadocivil_detalle:row?.ct_estadocivil_detalle||"",
        ct_sexo:row?.ct_sexo||"",
        ct_sexo_detalle:row?.ct_sexo_detalle||"",
        discapacidad:row?.discapacidad||false,
        extranjero:row?.extranjero||false,
        observacion:row?.observacion||"",
        estado:row?.estado||false,
        fechacreacion:row?.fechacreacion||"",
        fechamodificacion:row?.fechamodificacion||"",
        direccion:row?.direccion||""
      }
        return insertEl
    }

    const addRow = async(row) =>{
      var newItem= contadorSecuencias +1
      
      var aux= [...datatable]
      aux.push(await createRow(row, newItem))
      setContador(newItem)
      setDataTable(aux)
    }

    const SearchCedula= async(e)=>{
      setLoader(true);
      await instance.get("Usuario/SearchUsuariosByIdentNumber/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        await setterSearch(response)
      }else{
        setUsuarioId(0)
        resetAll()
        //setDataTable([])
      }
      
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);

    }

    const setterSearch= async(response)=>{
      var datosPersona= response.data.data.data;
      if(Array.isArray(datosPersona)){
        var datosform=[]
        var nombres
        var newItem= contadorSecuencias
        datosPersona.forEach(async element => {
          newItem= newItem+1
          /*datosform.push({
            ...(generarForm({"numeroidentificacion":element.numeroidentificacion}))[0], 
          nombrecompleto: element.nombrecompleto})*/
          datosform.push(await createRow(element, newItem))
        });

        setDataTable(datosform)
        setContador(newItem)
        response.data.data.form =datosform
        /*var selected= await AlertCodeInterpreter_preForm(response.data.data)
        if(selected){
          SearchCedula(selected)
        }*/
      }else{
        if(!datosPersona){
          datosPersona={}
        }
        var idp =datosPersona?.id
        setUsuarioId(idp)
        var newItem= contadorSecuencias
        var datosform=[]
        newItem= newItem+1
        datosform.push(await createRow(datosPersona, newItem))
        setContador(newItem)
        //setDataTable([])
        delete datosPersona?.id;
        setDataTable(datosform)
        
        var datosform=generarForm(datosPersona);
        var datosprueba= generarFormCompleto(datosPersona);

        var dictionaryRuleCatalogo=rules.find((rule)=>rule.nombre==="Catalogo parent")?.configuracion
        datosprueba.selectfield.forEach(element => {
          element.padre=dictionaryRuleCatalogo[element.paridad.name]
          element.padrefield="catalogo_id"
        });
        
        var generalform={}
        await setFormDataSearch(datosprueba.textfield || []);
        
        await setFormDataSelect(datosprueba.selectfield || []);
        await setFormDataDate(datosprueba.DateTimeField || []);
        await setFormDataToggleSwitch(datosprueba.ToggleSwitchField || []);
        await setFormDataModalSearch(datosprueba.modalSearch || [])

        datosform.forEach(element => {
          generalform={...generalform, [element.name]:element.value}
        });
        await setDatosPersona(generalform)
        setFile(response.data.data?.data?.foto ||'')
        
      }
    }

    const resetAll=()=>{
      setFile('')
      setFormDataSearch([]);
        
      setFormDataSelect( []);
      setFormDataDate( []);
      setFormDataToggleSwitch( []);
      setFormDataModalSearch( [])
    }

    const SearchName= async(e)=>{
      setLoader(true);
      await instance.get("Usuario/SearchUsuariosByNombre/"+e)
      .then(async(response)=>{
        setLoader(false);
      if(! await AlertCodeInterpreter_F(response.data.data)){
        
        await setterSearch(response)

      }else{
        setUsuarioId(0)
        resetAll()
        //setDataTable([])
      }
      
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
      setLoader(false);

      
    }

    const openEdit = async(e)=>{
      SearchCedula(e.numeroidentificacion)

    }

    const handleChange = (e) =>{
      var datosPers= {...datosPersona}
      datosPers[e.target.name]=e.target.value
      setDatosPersona(datosPers)
    }

    const handleSelect = (e) =>{
      var datosPers= {...datosPersona}
      /* datosPers[e.target.name]=e.target.value
      setDatosPersona(datosPers) */
    }


    const ActionsHandle = async(e) =>{
      e.preventDefault();
      var opt = e.nativeEvent.submitter.value;
      
      const data = new FormData(e.currentTarget);
      var toSend={
        busqueda: data.get('busqueda')
      }
      if(!toSend.busqueda){
        getDataAll();
        return
      }
      if( isNumeric(toSend.busqueda)){
        opt = "cedula"
      }else{{
        opt = "nombre"
      }}
      if(opt==='cedula'){
        await SearchCedula(toSend.busqueda)
      }else
      if(opt==='nombre'){
        SearchName(toSend.busqueda)
      }
    }

    const SubmitHandle = async(e) =>{
      e.preventDefault();
      
      const data = new FormData(e.currentTarget);
      var toSend={}
      formDataSearch.forEach(element => {
        toSend[element.name]=data.get(element.name)
      });

      toSend={...datosPersona}
      
      guardarRegistro(toSend)
    }

    const getDataAll= async(e)=>{
      setLoader(true)
      await instance.get("Usuario/SearchUsuariosByNombre/_")
      .then(async(response)=>{
          setterSearch(response)
          //resetAll()
        })
        .catch((error)=>{
          if(error?.response?.data?.error){
              AlertCodeInterpreter_Catch(error.response.data.error)
            }
            console.error(error)
        })
        setLoader(false)
  }

    const getData=async()=>{
      getDataAll()
      var botones = []
      botones.push({icon:<BadgeTwoToneIcon titleAccess="Nùmero de identidad" />,
                    value: "cedula"})
      botones.push({icon: <PersonSearchTwoToneIcon titleAccess="Nombre" />,
                    value: "nombre"})
      setActionsButtoms(botones)
      
      
      /* var botonesForm = []
      botonesForm.push({icon:<BadgeTwoToneIcon titleAccess="Guardar" />,
                    value: "update"})
      botonesForm.push({icon: <PersonSearchTwoToneIcon titleAccess="Crear" />,
                    value: "Create"})
       */
      var AdicionalFields = (
        <React.Fragment>
          <TextField
                margin="normal"
                required
                fullWidth
                id="busqueda"
                label="Buscar"
                name="busqueda"
                autoComplete="busqueda"
                autoFocus
              />
        </React.Fragment>
          
      )
      
      setAditionalFields(AdicionalFields)

      var listaOpciones={}
      instance.get("DataMaster/getAllSelectors")
      .then(async response=>{
        listaOpciones.detallecatalogo=response?.data?.data?.DetalleCatalogos ||[]
        listaOpciones.listCiudades=response?.data?.data?.Ciudades ||[]
        setSelectListOptions(listaOpciones)
        setListPaises(response?.data?.data?.Paises ||[])
        setListEstados(response?.data?.data?.Estados ||[])
        setListCiudades(response?.data?.data?.Ciudades ||[])
        setListCatalogos(response?.data?.data?.Catalogos ||[])
        setRules(response?.data?.data?.Config ||[])
      }).catch((error)=>{
        if(error?.response?.data?.error){
          AlertCodeInterpreter_Catch(error.response.data.error)
        }
        console.error(error)
      })
    }

    useEffect(()=> {
      getData();
      //setWrapperNavHK('hk-wrapper hk-vertical-nav');
    }, [])

    
    
    return (
        <React.Fragment>
            <Grid container spacing={3}>
              {/* Perfil Search y options */}
              <Grid item xs={12} md={4} lg={3} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: "center",
                  }}
                >
                  {<ProfileBase 
                    actionsButtoms={actionsButtoms} 
                    titleSelect= {"Buscar por:"} 
                    imageBg={foto}
                    title={"Usuario"}
                    //SelectOption={SelectOption} 
                    //handleOpenReporte={handleOpenReporte}
                    AditionalFields={AditionalFields}
                    ActionsHandle={ActionsHandle}  
                  />}
                </Paper>
              </Grid>
              {/* Perfil datos */}
              <Grid item xs={12} md={8} lg={9}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <PersonasView
                    openEdit={openEdit}
                    data={datatable} 
                    addRow={addRow} 
                    //backOption={backOption}
                    //backFunction={backToEstados}
                    /> 
                    
                </Paper>
              </Grid>
              {/* Tablas */}
              <Grid item xs={12}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  {<FormsEdit textFields={formDataSearch} 
                    title={'usuario'}
                    selectFields={formDataSelect} 
                    toggleSwitchFields={formDataToggleSwitch} 
                    ModalSearchFields={formDataModalSearch}
                    handleChange={handleChange} 
                    formData={datosPersona}
                    SubmitHandle={SubmitHandle}
                    handleSelect={handleSelect}
                    selectListOptions={selectListOptions}
                    listPaises={listPaises}
                    listEstados={listEstados}
                    listCiudades={listCiudades}
                    />}
                  {/*<Chart />*/}
                </Paper>
                
              </Grid>
            </Grid>
        </React.Fragment>
    )
}