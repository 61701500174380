import React, { useEffect, useState } from "react";
import Title from "../layout/Title";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import { ButtonGroup, Button, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import { IOSSwitch } from "../Litles/switch";
import ModalCiudadSelector from "../layout/ModalCiudadSelector";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function AditionalModalSelectiorController (props){

    return(
        <React.Fragment>
            <ModalCiudadSelector {...props}/>
        </React.Fragment>
    )
}

export default function SelectCityModal(props){
    const {openPais, handleClosePais, listPaises, listEstados, listCiudades, ciudadId} = props;

    return(
        <React.Fragment>
            <Modal
                hideBackdrop
                open={openPais}
                onClose={handleClosePais}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">Seleccionar ciudad</h2>
                    <AditionalModalSelectiorController 
                        ciudadId={ciudadId}
                        listPaises={listPaises} 
                        listEstados={listEstados} 
                        listCiudades={listCiudades} 
                        handleClose={handleClosePais}
                    />
                </Box>
            </Modal>
        </React.Fragment>
    )
}