import React from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Title from "../../components/shared/layout/Title";
import DefaultProfile from "./../../assets/CatalogoDefault.png";
import Box from '@mui/material/Box';
import { Select, MenuItem } from "@mui/material";

export default function CatalosView(props){
    const {actionsButtoms, titleSelect, SelectOption, ActionsHandle, handleOpenReporte} = props;

    return (
        <React.Fragment>
            <Title>Catálogo</Title>
            <Avatar
                alt="country"
                src={DefaultProfile}
                sx={{ width: 'auto', height: 'auto',  maxHeight:150,maxWidth:150 }}
            />
            <Box component="form" noValidate onSubmit={ActionsHandle} width={'100%'} sx={{ mt: 1, alignItems: "center", display:"flex", flexDirection:"column" }}>


            <Title>{titleSelect || ''}</Title>
            {SelectOption?.length>0 && <Select
                defaultValue={''}
                fullWidth
                onChange={(e)=>handleOpenReporte? handleOpenReporte(e.target.value):null}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="0">
                        <em>--Seleccione una opción--</em>
                    </MenuItem>
                    {
                        SelectOption.map((option)=>(
                            <MenuItem value={option.value}>{option.label}</MenuItem>
                        ))
                    }
                
            </Select>}

            {}


            <ButtonGroup variant="text" aria-label="text button group">
                {actionsButtoms?.length && actionsButtoms.map((boton)=>(
                    <Button 
                        type="submit"
                        id={"boton1"}
                        name={boton.value}
                        value={boton.value}
                        //onClick={boton.action || null}
                        >
                    {boton.icon || ''}
                    </Button>))}

            </ButtonGroup>
            </Box>

        </React.Fragment>
    )
}