export const upRegistroMision = {
    "idreal":"id",
    "fecha": "fecha",
    "casa_id": "casa_id",
    "ciudad_id": "ciudad_id",
    "superiora_id": "superiora_id",
    "mision_id": "ct_mision_id",
    "ct_mision_id": "ct_mision_id",
    "ct_casa_id":"ct_casa_id",
    "onlyyear":"onlyyear",
};

export const upRegistroClinico = {
    "idreal":"id",
    "fecha": "fecha",
    "ct_establecimiento_id":"ct_establecimiento_id",
    "evolucion": "evolucion",
    "doctor_id":"doctor_id",
    "numero":"numero",
    "prescripcion": "prescripcion",
    "medicamento": "medicamento",
    "historial_id": "historial_id",
    "comprobante_id":"comprobante_id",
    "onlyyear":"onlyyear",
};

export const upRegistroProfesional = {
    "idreal":"id",
    "fecha": "fecha",
    "ct_comunidad_id":"ct_comunidad_id",
    "superiora_id": "superiora_id",
    "ct_responsabilidad_id": "ct_responsabilidad_id",
    "observacion": "observacion"
};

export const upPais = {
    "idreal":"id",
    "nombre": "nombre",
    "nombreclatura":"nombreclatura",
    "codigo": "codigo", 
    "estado": "estado", //?
};

export const upEstado = {
    "idreal":"id",
    "nombre": "nombre",
    "nombreclatura":"nombreclatura",
    "pais_id": "pais_id", //?
    "estado": "estado", //?
};

export const toCiudad = {
    "idreal":"id",
    "nombre": "nombre",
    "nombreclatura":"nombreclatura",
    "estado_id": "estado_id", //?
    "estado": "estado", //?
}

export const toDetalleCatalogo = {
    "idreal":"id",
    "catalogo_id":"catalogoId",
    "descripcion":"descripcion",
    "valor":"valor",
    "ordenvisualizacion":"ordenvisualizacion",
    "detallecatalogo_id":"detallecatalogoId"
}

export const toUpDictionary = {
    "registromision": upRegistroMision,
    "registroclinico": upRegistroClinico,
    "registroprofesional": upRegistroProfesional,
    "pais": upPais,
    "estado": upEstado,
    "ciudad": toCiudad,
    "detallecatalogo": toDetalleCatalogo
}

export function getFormatUp(row, option){
    var preres= toUpDictionary[option]
    var res ={}
    for (var key in preres){
        var aux = {...res}
        res={...aux, [preres[key]]:row[key]||null}
        
    }
    return res
}

/**Sección para diccionario de rutas bases */
export const entityDictionary = {
    "registromision": "RegistroMision/",
    "registroclinico": "RegistroClinico/",
    "registroprofesional": "RegistroProfesional/",
    "pais": "Pais/",
    "estado": "Estado/",
    "ciudad": "Ciudad/",
    "detallecatalogo":"detallecatalogo/",
    "persona":"Persona/",
    "usuario": "Usuario/"
}

export function getEntityRoute(formatUp){
    try{
        return entityDictionary[formatUp]
    }catch{
        return null;
    }
}