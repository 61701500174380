import React, { useState } from "react";
import { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import PersonSearchTwoToneIcon from '@mui/icons-material/PersonSearchTwoTone';
import logoMain from './../../assets/logo.png'
import instance from "../../utilities/Instance";
import Editor, { loader } from "@monaco-editor/react";
import { Avatar, Button, MenuItem, Select, Stack } from "@mui/material";
import TextField from '@mui/material/TextField';
import { AlertCodeInterpreter_F } from "../../components/alerts";
import Title from "../../components/shared/layout/Title";
import TablaGestion from "../../components/shared/layout/Table";
import EditIcon from '@mui/icons-material/Edit';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import ReplyTwoToneIcon from '@mui/icons-material/ReplyTwoTone';
import HtmlTwoToneIcon from '@mui/icons-material/HtmlTwoTone';
import CodeTwoToneIcon from '@mui/icons-material/CodeTwoTone';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';


export default function Skeleton() {
    const [listFormato, setListFormatos] = useState([]);
    const [listaFormatosComprobantesRespaldo, setListaFormatosComprobantesRespaldo] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
            field: 'id',
            sort: 'asc',
        },
    ]);
    const [menuId, setMenuId] = useState(0)
    const [menuNombre, setMenuNombre] = useState(0)
    const [listMenus, setListMenus] = useState([]);
    const [listInitialMenus, setListInitialMenus] = useState([])
    const [time, setTime] = useState(new Date());

    const openEditFormato = (row) => {
        setMenuId(row.id);
        setMenuNombre ( row.nombre)
    }

    const closeEditFormato = () => {
        setMenuId(0);
    }

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

    const FormatoForm = [
        {
            field: "nombre",
            headerName: "Menú",
            width: 250,
            renderCell: (params) => {
                var value = params.row.nombre
                return (
                    value
                )
            }
        },
        /*{
          field: "titulo",
          headerName: "Nombre formato",
          width: 250,
          renderCell:(params)=>{
            var value= params.row.titulo
            return(
                value
              )
          }
        }*/

    ]

    const botonesAccionFormatos = [
        {
            field: "action",
            headerName: "Acción",
            width: 100,
            sortable: false,

            renderCell: (params) => {
                return (
                    <div className="cellAction" style={{ display: "flex", border: '1px solid #d7a200', padding: 5, borderRadius: 5 }}>
                        <div className="viewButton" style={{ color: "#d7a200", border: "none", cursor: 'pointer' }} onClick={async (e) => {
                            openEditFormato(params.row);
                        }} >{<EditIcon titleAccess="Editar." />}
                            Editar</div>
                    </div>
                );
            },
        },
    ];

    const getData = async () => {
        var formatos = []
        //instance.get("Formatos/ListAllByMenuId/"+menuId)
        instance.get("Formatos/ListAll")
            .then(async response => {
                let menus = response?.data?.data?.menu || []
                setListMenus([...menus])
                setListInitialMenus([...menus])
                if (!AlertCodeInterpreter_F(response?.data?.data)) {
                    setListFormatos([])


                } else {

                    setListFormatos(response?.data?.data?.data || [])
                    setListaFormatosComprobantesRespaldo([...response?.data?.data?.data])
                }
            })
            .catch(error => {
                setListFormatos([])
                console.error(error)
            })
    }

    useEffect(() => {
        getData();
        //setWrapperNavHK('hk-wrapper hk-vertical-nav');

    }, [])

    const saveFormato = (formato) => {
        let ruta="Formatos/Create"
        if(formato.id>0){
            ruta="Formatos/update/"+formato.id
        }else{
            ruta="Formatos/Create"
        }
        instance.post(ruta, {...formato, menu_id: menuId})
        .then(async response => {
            if (!AlertCodeInterpreter_F(response?.data?.data)) {

            } else {
                getData();
            }
        })
    }


    return (
        <React.Fragment>
            <Grid container spacing={3}>
                {/* logo */}
                {!menuId && <FormatoData data={listMenus || []} setData={setListMenus} dataRespaldo={listInitialMenus
                    || []} botonesAccion={botonesAccionFormatos} sortModel={sortModel} setSortModel={setSortModel} formato={FormatoForm} />}
                {menuId > 0 && <EditorFormato saveFormato={saveFormato} time={time} Titulo={menuNombre} listFormato={listFormato.filter(x=>{ return (x?.menu_id === menuId)})} backAction= {closeEditFormato} />}
            </Grid>
        </React.Fragment>
    )
}

function FormatoData(props) {
    const { data, dataRespaldo, setData, botonesAccion, sortModel, setSortModel, formato } = props
    const [searchField, setSearchField] = useState('')
    const autosearch = (value) => {
        if (value) {
            var filtrado = dataRespaldo.filter(banner => banner.nombre?.toUpperCase()?.includes(value.toUpperCase()))
            setData(filtrado)
        } else {
            setData(dataRespaldo)
        }
    }

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: 2 }}>
                <Paper sx={{ padding: 2 }}>
                    <TextField
                        fullWidth
                        label={'Escriba nombre del menú.'}
                        value={searchField}
                        onChange={e => { setSearchField(e.target.value); autosearch(e.target.value) }}
                    />
                </Paper>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TablaGestion
                    botones={botonesAccion}
                    data={data}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    formato={formato}
                />
            </Grid>
        </React.Fragment>
    )
}

function EditorFormato(props) {
    const {
        listFormato=[],
        time,
        backAction=()=>{},
        saveFormato= () =>{},
        Titulo='SIN FORMATO'
    } = props
    const [idFormato, setIdFormato] = useState(0)
    const [selectedFormat, setSelectedFormat] = useState({})
    //const [openInf, setOpenInf] = useState(true)
    //const [openForm, setOpenForm] = useState(false)
    //const [openPrev, setOpenPrev] = useState(false)
    const [optionMenu,setOptionMenu] = useState(1)
    const [isNew, setIsNew] = useState(false)
    const [title, setTitle] = useState('Información')
    const [dataprueba, setDataprueba] = useState('')
    const [sortModel, setSortModel] = useState([
        {
            field: 'nombre',
            sort: 'asc',
        },
    ]);
    const [document, setDocument] = useState('')
    const [timeNow, setTimeNow] = useState(new Date())

    const botonesAccionFormatos = [
        {
            field: "action",
            headerName: "Acción",
            width: 100,
            sortable: false,

            renderCell: (params) => {
                return (
                    <div className="cellAction" style={{ display: "flex", border: '1px solid #d7a200', padding: 5, borderRadius: 5 }}>
                        <div className="viewButton" style={{ color: "#d7a200", border: "none", cursor: 'pointer' }} onClick={async (e) => {
                            setSelectedFormat({...params.row});
                        }} >{<EditIcon titleAccess="Editar." />}
                            Editar</div>
                    </div>
                );
            },
        },
    ];

    const timeDifference  = time - timeNow;
    const secondsRemaining = Math.floor(timeDifference / 1000);

    const FormatoForm = [
        {
            field: "titulo",
            headerName: "Título",
            width: 250,
            renderCell: (params) => {
                var value = params.row.titulo
                return (
                    value
                )
            }
        }
    ]///PrintByMenuAndIdFormatosTRAction

    const printFormato = () => {
        let formato={
            ...selectedFormat,
            data: dataprueba, 

        }
        instance.post("Formatos/printByTR", formato)
        .then(async response => {
            setDocument(response.data)
        }).catch((error)=>{

        })
        /*instance.post("printById/", {...formato, menu_id: menuId})
        .then(async response => {
            if (!AlertCodeInterpreter_F(response?.data?.data)) {

            } else {
                getData();
            }
        })*/
    }

    useEffect(()=>{
        if(secondsRemaining===3){
            printFormato()
            //setTimeNow(new Date())
        }
    },[secondsRemaining])

    return (
        <React.Fragment>
            <Grid item sm={12} xs={12} lg={12} md={12}>
                <Paper sx={{ p: 2, flexDirection: 'column', height:'100%' }}>
                <Stack direction="row" spacing={2}>
                    
                    <Title>{'Panel de administración'}</Title>
                    <hr/>
                    <Avatar sx={{cursor:'pointer'}} onClick={backAction }>
                        <ReplyTwoToneIcon titleAccess="Regresar"/>
                    </Avatar>
                    <hr/>
                    <Avatar sx={{cursor:'pointer', bgcolor:isNew?'#2b383e':null}} onClick={(e)=>{setIsNew(prev=>!prev); setSelectedFormat({}) ; setTitle('Información')} }>
                        <AddBoxTwoToneIcon titleAccess="Nuevo"/>
                    </Avatar>
                    {(isNew || selectedFormat.id>0) && 
                        <>
                            <Avatar sx={{cursor:'pointer', bgcolor:optionMenu===1?'#2b383e':null}} onClick={(e)=>{setOptionMenu(1); setTitle('Información')} }>
                                <DescriptionTwoToneIcon titleAccess="Información"/>
                            </Avatar>
                            <Avatar sx={{cursor:'pointer', bgcolor:optionMenu===2?'#2b383e':null}} onClick={(e)=>{setOptionMenu(2); setTitle('Formato')} }>
                                <CodeTwoToneIcon titleAccess="Formato"/>
                            </Avatar>
                            <Avatar sx={{cursor:'pointer', bgcolor:optionMenu===3?'#2b383e':null}} onClick={(e)=>{setOptionMenu(3); setTitle('Vista previa')} }>
                                <ImageTwoToneIcon titleAccess="Vista previa"/>
                            </Avatar>

                            <Avatar sx={{cursor:'pointer', bgcolor:'rgb(215, 162, 0)'}} onClick={()=>saveFormato(selectedFormat) }>
                                <SaveTwoToneIcon titleAccess="Actualizar datos"/>
                            </Avatar>
                        </>
                    }
                </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12}><Title>{Titulo}</Title></Grid>
                    { (!isNew) && <Grid item xs={6}>
                        <Select
                            key={"SelectProfileBase"}
                            defaultValue={''}
                            fullWidth
                            onChange={(e) => setSelectedFormat({...e.target.value})}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="" key={"MenuItemProfileBaseA"}>
                                <em>--Seleccione una opción--</em>
                            </MenuItem>
                            {
                                listFormato.map((option, index) => (
                                    <MenuItem key={"MenuItemProfileBase" + index} value={option}>{option?.titulo}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>}
                    <Grid item xs={6}>
                        <TextField
                            //onChange={handleChage}
                            disabled={!(selectedFormat.id||isNew)}
                            margin="none"
                            required
                            fullWidth
                            id="busqueda"
                            label="Título"
                            name="busqueda"
                            autoComplete="busqueda"
                            autoFocus
                            value={selectedFormat.titulo||''}
                            onChange={(value) => setSelectedFormat(prev => ({ ...prev, titulo: value.target.value}))}
                            />
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}  style={{display: !(selectedFormat.id||isNew)? 'block':'none'}} >
                <TablaGestion
                    botones={botonesAccionFormatos}
                    data={listFormato}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    formato={FormatoForm}
                />
            </Grid>
            <Grid item sm={12} xs={12} lg={12} md={12}  style={{display: (selectedFormat.id||isNew)? 'block':'none'}} >
                <Grid container spacing={3} style={{display:optionMenu===1?null:'none'}} >
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Title>Datos de prueba</Title>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Editor
                                name="dataprueba"
                                theme='vs-dark'
                                height="60vh"
                                defaultLanguage="json"
                                defaultValue={dataprueba}
                                onChange={(value) => {setTimeNow(new Date()); setDataprueba(value)}}
                                options={
                                    {
                                        minimap: {
                                            enabled: false,
                                        },
                                    }
                                }
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{display:optionMenu===2?null:'none'}}>
                    <Grid item xs={6}>
                        <Title>Estilo</Title>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Editor
                                name="estilo"
                                theme='vs-dark'
                                height="60vh"
                                defaultLanguage="css"
                                defaultValue={''}
                                value={selectedFormat.estilo||''}
                                onChange={(value) => {setTimeNow(new Date()); setSelectedFormat(prev => ({ ...prev, estilo: value }))}}
                                options={
                                    {
                                        minimap: {
                                            enabled: false,
                                        },
                                    }
                                }
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Title>Contenido</Title>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Editor
                                name="contenido"
                                theme='vs-dark'
                                height="60vh"
                                defaultLanguage="html"
                                defaultValue={''}
                                value={selectedFormat.contenido||''}
                                onChange={(value) => {setTimeNow(new Date()); setSelectedFormat(prev => ({ ...prev, contenido: value }))}}
                                options={
                                    {
                                        minimap: {
                                            enabled: false,
                                        },
                                    }
                                }
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{display:optionMenu===3?null:'none'}} >
                    <Grid item xs={12} sm={12} md={12} lg={12}  >
                        <Title>Vista previa</Title>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <iframe style={{width:'100%', height:'70vh'}} srcdoc={document}></iframe>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}