import { Button, Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AlertSimpleStandar } from "../../alerts";
import Title from "../../shared/layout/Title";
import { CedulaUp } from "./documentOption/Cedula";


export function AddFile(props){
    const {handleClose, validFunc } = props
    const [newName, setNewName]=useState('')
    const [file, setFile]=useState(null)
    const [warningColor, setWarningColor]=useState(false)
    
    const handleChange=(e)=>{
        setNewName(e.target.value)
    }

    const handleCaseAceptar=()=>{
      if(!newName?.length || !file){
        var info={
          content:"Formulario incompleto. Por favor, asegúrese de colocar un nombre adecuado para su archivo y contar con un archivo cargado al sistema para continuar."
        }
        setWarningColor(true)
        AlertSimpleStandar(info, 11);
        return
      }
      handleClose( file ,newName, false)
    }

    const fileUp= (e) => {
        //setTheTitle(getBase64(e.target.files[0]))
        var reader = new FileReader();
        
        var fname=e.target.files[0];
        
        //setFile(e.target.files[0])
        if(e.target.files[0].type==="application/pdf" ){
          /* if(e.target.files[0].size<=1000000){
            reader.onloadend = function() {
              setFile( reader.result)
            }
            reader.readAsDataURL(fname);
          }else{
            reader.onloadend = function() {
              setFile( reader.result)
            }
            reader.readAsDataURL(fname);
          } */

          reader.onloadend = function() {
            setFile( reader.result)
          }
          if (fname) {
            reader.readAsDataURL(fname);
          }
        }else{

          //warningm('Estimado usuario. El tipo de archivo que intenta subir no es el adecuado. Por favor, ingrese archivos de extensión ".png", ".jpg" y ".jpeg".')
        }
        
      }

    return (
        <React.Fragment>
            <Grid container spacing={3} >
                <Grid item xs={12} md={12} lg={12} >
                    <TextField
                        fullWidth
                        value={newName||""}
                        label={"Agregue un nombre adecuado para su archivo."}
                        onChange={handleChange}
                        color={newName?'':(warningColor?'error':'')}
                        focused={newName?null:warningColor}
                        required
                        //onKeyDown={"searchBy"}
                    >
                        
                    </TextField>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <CedulaUp setDocument={setFile} warningColor={warningColor} document={file} handleChange={handleChange} fileUp={fileUp}/>

                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button onClick={()=>handleClose(null)}>Cancelar</Button>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button onClick={()=>handleCaseAceptar()}>Guardar</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}