import instance from "../utilities/Instance";
import { getEntityRoute } from "./toUp";

export const entityIdActivar=(entity, id)=>{
    entity=getEntityRoute(entity)
    instance.get(entity+"active/"+id)
    .then(response=>{

    }).catch(error=>{

    })
}

